<template functional>
  <svg viewBox="0 0 24 24" fill="none" :class="props.classes">
    >
    <path
      d="M19.7508 6.7506L16.131 8.8314V8.16C16.131 7.58713 15.9034 7.03773 15.4984 6.63265C15.0933 6.22757 14.5439 6 13.971 6H5.16C4.58713 6 4.03773 6.22757 3.63265 6.63265C3.22757 7.03773 3 7.58713 3 8.16L3 14.8848C3 15.4577 3.22757 16.0071 3.63265 16.4122C4.03773 16.8172 4.58713 17.0448 5.16 17.0448H13.971C14.5439 17.0448 15.0933 16.8172 15.4984 16.4122C15.9034 16.0071 16.131 15.4577 16.131 14.8848V14.2134L19.7508 16.2942C20.4366 16.6884 21 16.3644 21 15.5742V7.4742C21 6.6804 20.4384 6.3564 19.7508 6.7506Z"
      fill="currentColor"
    />
  </svg>
</template>

<script>
export default {
  name: "VideoIcon",
  props: {
    classes: {
      type: String,
      required: false,
      default: "",
    },
  },
};
</script>
