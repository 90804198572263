import { isNil } from "ramda";
import { Product } from "~/types/product";
import { ProductAttribute as ProductAttributeEnum } from "~/constants/attributes/productAttributes";

// Finds and returns url from a string
const regexMatchUrl =
  /\bhttps?:\/\/[^,\s()<>]+(?:\([\w\d]+\)|([^,!"#$%&'()*+,./:;<=>?@\\^_`{|}[\]~\s]|\/))/g;

export const getProductDetails = (
  description: string,
  products: Product[]
): string => {
  const getDescriptionAnchor = (p: Product): string =>
    `<a class="font-bold underline" href="/${p.slug}/p${p.code}">${p.code}</a>`;

  if (!isNil(products) && products.length > 0) {
    products.forEach((p) => {
      description = description.replace(
        new RegExp(`\\(code (${p.code})\\)`, "gi"),
        getDescriptionAnchor(p)
      );
    });
  }

  return description?.replace(/\n|\\n/g, "<br />").replace(/\r|\\r/g, "");
};

export const attrIsYoutube = (id: number): boolean => {
  return ProductAttributeEnum.Youtube === id;
};

export const attrIsRiskCode = (id: number): boolean => {
  return ProductAttributeEnum.RiskCode === id;
};

export const getUrlFromString = (value: string): string => {
  if (value === "") return "";
  const matches = value.match(regexMatchUrl);
  if (matches === null || matches.length === 0) return "";

  return matches[0];
};
