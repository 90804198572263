
import Vue from "vue";
import { mapActions, mapGetters, mapMutations } from "vuex";
import VAutocomplete from "v-autocomplete";
import AutocompleteItemTemplateSwitch from "./AutocompleteItemTemplateSwitch.vue";
import { SearchBarItem } from "~/types/searchBar";
import { defaultSearchData } from "~/store/searchBar";
import { TrackingEvents } from "~/store/tracking";
import { SearchIcon } from "~/components/UI/icons";

export default Vue.extend({
  name: "SearchBar",

  components: {
    VAutocomplete,
    SearchIcon,
  },

  data() {
    return {
      item: null,
      searchText: "",
      template: AutocompleteItemTemplateSwitch,
      queryItemLimit: 5,
      departmentItemLimit: 3,
      productItemLimit: 3,
      keepOpen: false,
      searchInputOnFocus: false,
      manualSearchLock: false,
    };
  },
  computed: {
    ...mapGetters("searchBar", ["searchData"]),
    dropdownData(): SearchBarItem[] {
      let items: SearchBarItem[] = [];
      if (this.searchData && this.searchData.suggestionGroups?.length) {
        items = [
          {
            data: this.searchData.suggestionGroups[0]?.querySuggestions?.slice(
              0,
              this.queryItemLimit
            ),
            component: "QueryItems",
          },
          {
            data: this.searchData.suggestionGroups[0]?.attributeSuggestions?.slice(
              0,
              this.departmentItemLimit
            ),
            component: "DepartmentItems",
          },
          {
            data: this.searchData.suggestionGroups[0]?.searchSuggestions?.slice(
              0,
              this.productItemLimit
            ),
            component: "ProductItems",
          },
        ];
      }
      return items;
    },
    searchQuery(): string | (string | null)[] {
      return this.$route.query.q;
    },
  },
  watch: {
    searchQuery(): void {
      if (this.searchQuery) {
        this.searchText = this.searchQuery as string;
        (this.$refs.searchbar as any & { searchText: string }).searchText = this
          .searchQuery as string;
      }
    },
    $route(): void {
      if (this.$route.name !== "search" && this.searchText !== "") {
        this.changeSearchText("");
        (this.$refs.searchbar as any & { searchText: string }).searchText = "";
      }
    },
  },
  mounted() {
    const queryString = this.$route.query.q as string;
    if (this.$route.name === "search" && queryString) {
      this.searchText = queryString;
      (this.$refs.searchbar as any & { searchText: string }).searchText =
        queryString;
    }
  },
  methods: {
    ...mapActions("searchBar", ["getSearchData"]),
    ...mapMutations("searchBar", ["setSearchData", "setSearchQuery"]),
    handleEnterPressed() {
      if (this.searchInputOnFocus) {
        this.searchManually();
        this.handleLeaveSearchbar();
      }
    },
    searchManually() {
      if (this.searchText) {
        this.manualSearchLock = true;

        this.$router.push({
          name: "search",
          query: {
            q: this.searchText,
          },
        });

        this.$store.commit("tracking/addTrackingEvent", {
          type: TrackingEvents.SearchInput,
          data: this.searchText,
        });

        this.resetSearch();
      }
      this.$emit("showOverlay", false);
    },
    getLabel() {
      return this.searchText;
    },

    changeSearchText(text: string) {
      this.searchInputOnFocus = true;
      this.manualSearchLock = false;
      this.searchText = text;
      this.setSearchQuery(text || "");
      if (!text) {
        this.resetSearch();
      }
    },
    resetSearch() {
      this.keepOpen = false;
      this.$emit("showOverlay", false);
      this.setSearchData(defaultSearchData);
    },
    handleInput() {
      if (this.dropdownData.length) {
        this.$emit("showOverlay", true);
      }
    },
    async getData(query: string) {
      const data = await this.getSearchData(query);

      if (!this.manualSearchLock) {
        if (
          data?.suggestionGroups?.length &&
          data?.suggestionGroups[0]?.querySuggestions?.length
        ) {
          this.$emit("showOverlay", true);
        }
      } else {
        this.resetSearch();
      }
    },
    keepOpenDropdown(keep: boolean) {
      if (keep && !this.keepOpen) {
        this.keepOpen = true;
        this.$emit("showOverlay", true);
      } else if (!keep) {
        this.keepOpen = false;
        this.$emit("showOverlay", false);
      }
    },
    handleLeaveSearchbar() {
      this.keepOpen = false;
      this.$emit("showOverlay", false);
    },
    handleSearchBarFocus() {
      this.searchInputOnFocus = true;
      this.$store.commit("mainMenu/CLOSE_MOBILE_MENU");
      if (this.dropdownData.length) {
        this.$emit("showOverlay", true);
      }
    },
    handleSearchBarBlur() {
      this.searchInputOnFocus = false;
      if (!this.dropdownData.length) {
        this.$emit("showOverlay", false);
      }
    },
  },
});
