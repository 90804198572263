import type { Context } from "@nuxt/types";

enum AccountType {
  TRADE_CREDIT = "Trade Credit",
}

export default (context: Context): void => {
  const { store, redirect } = context;
  if (store.state?.auth?.user?.accountType !== AccountType.TRADE_CREDIT) {
    redirect("/");
  }
};
