<template>
  <div class="h-full relative rounded-xl">
    <div
      class="flex flex-col h-full items-center justify-center relative rounded-xl shadow-md overflow-hidden"
    >
      <div
        v-if="backgroundColour && !imageUrl"
        :style="backgroundColourClass"
        class="w-full object-cover"
        :class="heightClasses"
      />
      <img
        v-if="imageUrl && !backgroundColour"
        class="w-full object-cover"
        :class="heightClasses"
        :src="imageUrl"
        :alt="title"
        loading="lazy"
      />
      <div
        v-if="backgroundColour && imageUrl"
        :style="backgroundColourClass"
        class="w-full flex justify-center items-center"
        :class="heightClasses"
      >
        <img
          :class="imageHeightClasses"
          :src="imageUrl"
          :alt="title"
          loading="lazy"
        />
      </div>

      <p
        v-if="title"
        class="font-semibold text-size-4 p-2 w-full min-h-[70px] grow flex justify-center items-center text-center"
        :style="titleTextColour !== '' ? titleTextColour : 'color: #065CAB'"
      >
        {{ title }}
      </p>
    </div>
  </div>
</template>

<script setup>
import { computed } from "vue";

const props = defineProps({
  heightClasses: {
    type: String,
    required: true,
  },
  imageUrl: {
    type: String,
    required: true,
  },
  backgroundColour: {
    type: String,
    required: false,
    default: null,
  },
  title: {
    type: String,
    required: true,
  },
  imageClasses: {
    type: String,
    required: false,
    default: "",
  },
  titleTextColour: {
    type: String,
    required: false,
    default: "color: #065CAB",
  },
});

const backgroundColourClass = computed(
  () => `background-color:${props.backgroundColour}`
);

const imageHeightClasses = computed(() =>
  props.imageClasses ? props.imageClasses : props.heightClasses
);
</script>
