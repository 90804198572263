import type { Context } from "@nuxt/types";
import { getSupportedLocales } from "~/utils/i18n";

export default async (context: Context): Promise<void> => {
  if (process.client) {
    return;
  }

  const { defaultLanguage, localeInstance } = context.$config;

  const localeCookie = context.$cookies.get("toolstation-locale");

  // If the user has a cookie we'll use that as the preference
  const switchedLocale =
    localeCookie ?? `${String(defaultLanguage)}-${String(localeInstance)}`;
  const supportedLocales = getSupportedLocales(localeInstance);

  // If the selected locale isnt one of the supported locales then we'll use the first one
  if (!supportedLocales.includes(switchedLocale)) {
    await context.app.i18n.setLocale(supportedLocales[0].code);
    return;
  }

  await context.app.i18n.setLocale(switchedLocale);
};
