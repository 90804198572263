
import Vue from "vue";
import { ChevronIcon } from "~/components/UI/icons";

export default Vue.extend({
  name: "SearchBarSuggestionRow",
  components: {
    ChevronIcon,
  },
  props: {
    bottomBorder: {
      type: Boolean,
      default: false,
    },
  },
});
