import { AppCookieNames } from "~/constants/ecomApi";

export default function (context) {
  const { route, $cookies } = context;

  if (route && route.query && route.query.preview) {
    $cookies.set(AppCookieNames.MonetatePreviewId, route.query.preview, {
      expires: new Date(Date.now() + 60 * 60 * 1000), // 1 hour
      path: "/",
      sameSite: "Lax",
      secure: true,
    });
  }
}
