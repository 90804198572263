<template>
  <LoadingSpinner
    v-if="loading"
    size="xxl"
    extra-classes="fixed z-50 w-full h-full bg-white opacity-80 top-0 left-0"
  >
  </LoadingSpinner>
</template>

<script>
import { mapGetters } from "vuex";
import LoadingSpinner from "~/components/UI/loading/LoadingSpinner.vue";

export default {
  components: {
    LoadingSpinner,
  },
  data: () => ({
    loading: false,
  }),
  computed: {
    ...mapGetters(["pageLoadingIndicatorEnabled"]),
  },
  methods: {
    start() {
      this.loading = this.pageLoadingIndicatorEnabled;
    },
    finish() {
      this.loading = false;
    },
  },
};
</script>
