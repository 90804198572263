
import Vue from "vue";
import { mapGetters } from "vuex";
import DefaultButton from "~/components/UI/button/DefaultButton.vue";
import { productSlug } from "~/services/products.service";
import { LineItem } from "~/types/trolley";

const trolleyPreview = Vue.extend({
  name: "TrolleyPreview",

  components: {
    DefaultButton,
  },

  computed: {
    ...mapGetters("trolley", [
      "trolleyItemsCount",
      "trolleyTotal",
      "trolleyLines",
    ]),
  },

  methods: {
    displayProductSlug(item: LineItem) {
      if (item?.product === undefined) {
        return "";
      }

      return productSlug(item.product);
    },
  },
});
export default trolleyPreview;
