<template>
  <div
    class="flex flex-col items-center justify-center relative overflow-hidden bg-white p-2 shadow my-2"
  >
    <div :class="heightClasses" class="w-full">
      <div
        v-if="backgroundColour"
        :style="backgroundColourClass"
        class="w-full h-full object-cover"
      />
      <img
        v-else
        class="w-full h-full object-cover"
        :src="imageUrl"
        :alt="title"
        loading="lazy"
      />
    </div>
    <p
      class="pb-2 md:pb-4 pt-4 md:pt-6 font-bold text-size-6 md:text-size-7.5 text-center"
      :style="titleTextColour !== '' ? titleTextColour : 'color: #065CAB'"
    >
      {{ title }}
    </p>
  </div>
</template>

<script setup>
import { computed } from "vue";

const props = defineProps({
  heightClasses: {
    type: String,
    required: true,
  },
  imageUrl: {
    type: String,
    required: true,
  },
  backgroundColour: {
    type: String,
    required: false,
    default: null,
  },
  title: {
    type: String,
    required: true,
  },
  cta: {
    type: Object,
    required: false,
    default: () => {},
  },
  titleTextColour: {
    type: String,
    required: false,
    default: "color: #065CAB",
  },
});

const backgroundColourClass = computed(
  () => `background-color:${props.backgroundColour}`
);
</script>
