<template>
  <div
    class="flex flex-col h-full items-center justify-center relative border-1 border-grey-3 overflow-hidden"
  >
    <div
      v-if="backgroundColour"
      :style="backgroundColourClass"
      class="w-full object-cover"
      :class="heightClasses"
    />
    <img
      v-else
      class="w-full object-cover"
      :class="heightClasses"
      :src="imageUrl"
      :alt="title"
      loading="lazy"
    />
    <p
      v-if="title"
      class="text-size-5 min-h-[50px] grow w-full flex justify-start items-start p-3 text-left"
      :style="titleTextColour !== '' ? titleTextColour : 'color: #065CAB'"
    >
      {{ title }}
    </p>
  </div>
</template>

<script setup>
import { computed } from "vue";

const props = defineProps({
  heightClasses: {
    type: String,
    required: true,
  },
  imageUrl: {
    type: String,
    required: true,
  },
  backgroundColour: {
    type: String,
    required: false,
    default: null,
  },
  title: {
    type: String,
    required: true,
  },
  titleTextColour: {
    type: String,
    required: false,
    default: "color: #065CAB",
  },
});

const backgroundColourClass = computed(
  () => `background-color:${props.backgroundColour}`
);
</script>
