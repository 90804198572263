<template functional>
  <hr class="border-legacy-grey-default my-8" :class="props.classes" />
</template>

<script>
export default {
  name: "Divider",
  props: {
    classes: {
      type: String,
      required: false,
      default: "",
    },
  },
};
</script>
