import { Context as NuxtContext } from "@nuxt/types";
import { AppCookieNames } from "~/constants/ecomApi";

let dataLoaded = false;
let queueTrackPageLoadEvents = false;

const trackPageLoadEvents = (context: NuxtContext): void => {
  context.$gtm.push({
    event: "userLoggedIn",
    userLoggedIn: context.store.getters["auth/isAuthenticated"],
    removeOnNewPage: true,
  });
  context.$gtm.push({
    event: "userId",
    userId: context.store.getters["auth/getUser"]?.id,
    removeOnNewPage: true,
  });
  context.$gtm.push({
    event: "trolleyPopulated",
    trolleyPopulated: context.store.getters["trolley/trolleyItemsCount"] > 0,
    removeOnNewPage: true,
  });
  context.$gtm.push({
    event: "selectedBranch",
    value: context.store.getters["branch/selectedBranchName"],
    removeOnNewPage: true,
  });
  context.$dd.setGlobalContextProperty(
    AppCookieNames.TsSessionId,
    context.$cookies.get(AppCookieNames.TsSessionId) || null
  );
  context.$dd.setGlobalContextProperty("trolley", {
    trolleyId: context.store.getters["trolley/trolleyId"] || null,
    productCodes: (context.store.getters["trolley/trolleyLines"] || []).map(
      (item: any) => item.product_code
    ),
    trolleySubTotal: context.store.getters["trolley/trolleySubTotal"] || null,
    trolleyTotal: context.store.getters["trolley/trolleyTotalRaw"] || null,
  });
  context.$dd.setGlobalContextProperty("branchSelected", {
    branchId: context.store.getters["branch/selectedBranchId"],
    branchName: context.store.getters["branch/selectedBranchName"],
  });
  context.$dd.setGlobalContextProperty(
    "featureFlags",
    context.store.getters["featureFlags/getFeatureFlags"] || {}
  );
};

let handleUserDataLoaded = (): void => {
  dataLoaded = true;
};

window.addEventListener("userDataLoaded", handleUserDataLoaded);

export default (context: NuxtContext): void => {
  window.removeEventListener("userDataLoaded", handleUserDataLoaded);

  handleUserDataLoaded = () => {
    dataLoaded = true;

    if (queueTrackPageLoadEvents) {
      queueTrackPageLoadEvents = false;
      dataLoaded = false;
      trackPageLoadEvents(context);
    }
  };

  window.addEventListener("userDataLoaded", handleUserDataLoaded);

  context.app.router?.afterEach(() => {
    if (!dataLoaded) {
      queueTrackPageLoadEvents = true;
      return;
    }

    trackPageLoadEvents(context);
  });
};
