import type { NuxtI18nInstance } from "@nuxtjs/i18n";

/**
 * @description Campaign pages don't exist as real taxonomies, this returns the taxonomy data as expected by the application
 */
export const getCampaignTaxonomy = (
  i18n: NuxtI18nInstance,
  campaignId: string,
  campaignName: string
) => {
  return {
    taxonomy: {
      id: campaignId,
      name: campaignName,
      slug: `campaign/${campaignId}`,
      description: i18n.t("campaign.description", {
        campaignName,
      }),
      notes: [
        {
          id: 1,
          name: "meta-title",
          value: i18n.t("campaign.meta.title", {
            campaignName,
          }),
        },
        {
          id: 2,
          name: "meta-description",
          value: i18n.t("campaign.meta.description", {
            campaignName,
          }),
        },
      ],
    },
  };
};
