<template functional>
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    :class="props.classes"
  >
    <path
      d="M8.64726 12.5865C8.58305 12.3143 8.54828 12.0357 8.54356 11.7559C8.54356 10.825 8.90772 9.93227 9.55593 9.27405C10.2041 8.61583 11.0833 8.24605 12 8.24605H12.2765L13.2097 7.14631C12.8087 7.09345 12.4044 7.06999 12 7.07612C7.84076 7.07612 4.32672 10.1998 3.17457 11.3464C3.11946 11.3989 3.07554 11.4623 3.04552 11.5328C3.01549 11.6032 3 11.6791 3 11.7559C3 11.8326 3.01549 11.9085 3.04552 11.979C3.07554 12.0494 3.11946 12.1128 3.17457 12.1653C4.24038 13.218 5.43265 14.1299 6.72318 14.8796L8.64726 12.5865Z"
      fill="black"
    />
    <path
      d="M20.8253 11.3464C19.7595 10.2937 18.5673 9.3818 17.2767 8.63211L15.3527 10.972C15.4136 11.229 15.4484 11.4916 15.4564 11.7558C15.4564 12.6867 15.0922 13.5794 14.444 14.2376C13.7958 14.8959 12.9166 15.2656 11.9999 15.2656H11.7234L10.8478 16.342C11.2293 16.3999 11.6142 16.4312 11.9999 16.4356C16.1592 16.4356 19.6732 13.3119 20.8253 12.1653C20.8805 12.1128 20.9244 12.0494 20.9544 11.9789C20.9844 11.9085 20.9999 11.8326 20.9999 11.7558C20.9999 11.6791 20.9844 11.6032 20.9544 11.5327C20.9244 11.4623 20.8805 11.3989 20.8253 11.3464Z"
      fill="black"
    />
    <path
      d="M17.7607 5.90617L16.7929 5.06382C16.7467 5.02268 16.6873 5 16.6259 5C16.5644 5 16.505 5.02268 16.4588 5.06382L6.23929 17.2311C6.21258 17.2542 6.19111 17.2829 6.17639 17.3152C6.16168 17.3475 6.15405 17.3827 6.15405 17.4183C6.15405 17.4539 6.16168 17.4891 6.17639 17.5215C6.19111 17.5538 6.21258 17.5825 6.23929 17.6055L7.19557 18.4362C7.2418 18.4773 7.30117 18.5 7.36264 18.5C7.4241 18.5 7.48347 18.4773 7.5297 18.4362L17.7607 6.26885C17.7893 6.24831 17.8125 6.22112 17.8286 6.18957C17.8447 6.15802 17.853 6.12303 17.853 6.08751C17.853 6.05199 17.8447 6.01699 17.8286 5.98544C17.8125 5.95389 17.7893 5.92671 17.7607 5.90617Z"
      fill="currentColor"
    />
  </svg>
</template>

<script>
export default {
  name: "EyeSlashIcon",
  props: {
    classes: {
      type: String,
      required: false,
      default: "",
    },
  },
};
</script>
