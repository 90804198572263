<template functional>
  <svg
    :class="props.classes"
    width="32"
    height="36"
    viewBox="0 0 32 36"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M3.55556 35.6395H28.4444C30.4053 35.6395 32 34.0449 32 32.084V7.1951C32 5.23421 30.4053 3.63954 28.4444 3.63954H24.8889V0.0839844H21.3333V3.63954H10.6667V0.0839844H7.11111V3.63954H3.55556C1.59467 3.63954 0 5.23421 0 7.1951V32.084C0 34.0449 1.59467 35.6395 3.55556 35.6395ZM28.4444 10.7507L28.4462 32.084H3.55556V10.7507H28.4444Z"
      fill="#065CAB"
    />
    <path
      d="M7.11111 16.084H10.6667V19.6395H7.11111V16.084ZM7.11111 23.1951H10.6667V26.7507H7.11111V23.1951ZM14.2222 16.084H17.7778V19.6395H14.2222V16.084ZM14.2222 23.1951H17.7778V26.7507H14.2222V23.1951ZM21.3333 16.084H24.8889V19.6395H21.3333V16.084ZM21.3333 23.1951H24.8889V26.7507H21.3333V23.1951Z"
      fill="#065CAB"
    />
  </svg>
</template>

<script>
export default {
  name: "CalendarIcon",
  props: {
    classes: {
      type: String,
      required: false,
      default: "",
    },
  },
};
</script>
