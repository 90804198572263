import { Plugin } from "@nuxt/types";

const polyfill: Plugin = ({ $config: { polyfills } }) => {
  const required = polyfills.filter((polyfill: string) => polyfill in Window);

  if (required.length) {
    const script = document.createElement("script");
    script.src = `https://polyfill.io/v3/polyfill.min.js?features=${required.join(
      ","
    )}`;
    document.head.appendChild(script);
  }
};

export default polyfill;
