<template>
  <div class="h-full relative rounded-xl">
    <div class="flex items-center justify-center h-full relative">
      <div
        class="bg-black-shade absolute left-0 top-0 w-full h-full z-10 rounded-xl"
      />
      <div
        v-if="backgroundColour"
        :style="backgroundColourClass"
        :class="heightClasses"
        class="min-h-[200px] w-full rounded-xl object-cover"
      />
      <img
        v-else
        class="min-h-[200px] w-full rounded-xl object-cover"
        :class="heightClasses"
        :src="imageUrl"
        :alt="title"
        loading="lazy"
      />
      <p
        v-if="title"
        class="font-bold text-size-8 m-6 text-center z-10 absolute"
        :style="titleTextColour !== '' ? titleTextColour : 'color: white'"
      >
        {{ title }}
      </p>
    </div>
  </div>
</template>

<script setup>
import { computed } from "vue";

const props = defineProps({
  heightClasses: {
    type: String,
    required: true,
  },
  imageUrl: {
    type: String,
    required: true,
  },
  backgroundColour: {
    type: String,
    required: false,
    default: null,
  },
  title: {
    type: String,
    required: true,
  },
  titleTextColour: {
    type: String,
    required: false,
    default: "color: #fff",
  },
});

const backgroundColourClass = computed(
  () => `background-color:${props.backgroundColour}`
);
</script>
