import { render, staticRenderFns } from "./ForwardIcon.vue?vue&type=template&id=abef54ba&functional=true"
import script from "./ForwardIcon.vue?vue&type=script&lang=js"
export * from "./ForwardIcon.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  true,
  null,
  null,
  null
  
)

export default component.exports