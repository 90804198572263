import type { Context } from "@nuxt/types";
import VuexSharedMutations from "vuex-shared-mutations";
import hydrate from "~/store/plugins/hydrate";

export default (context: Context) => {
  const { store } = context;
  window.onNuxtReady(() => {
    VuexSharedMutations({
      predicate: [
        "branch/setSelectedBranch",
        "trolley/setTrolleyIdData",
        "trolley/setTrolleyData",
        "trolley/setDeliveryAddressId",
        "trolley/setCollectionAddressId",
        "trolley/setBillingAddressId",
        "trolley/setDeliveryDetails",
        "trolley/setDeliveryMethods",
        "trolley/setStaffDiscountToken",
        "guest/setGuestCheckoutSession",
        "guest/setGuest",
        "guest/setGuestEmail",
        "auth/setUser",
        "auth/setUserAccounts",
        "auth/setCardholders",
        "auth/setJwt",
        "auth/deleteUser",
      ],
    })(store);

    hydrate(context);
  });
};
