<template functional>
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    :class="props.classes"
  >
    <path
      d="M4.75 3.75H16.2778C17.4284 3.75 18.3611 4.68274 18.3611 5.83333V19.25H6.83333C5.68274 19.25 4.75 18.3173 4.75 17.1667V3.75Z"
      stroke="currentColor"
      stroke-width="1.5"
    />
    <rect
      x="7.77783"
      y="9.61111"
      width="7.55556"
      height="0.944444"
      rx="0.2"
      fill="currentColor"
    />
    <rect
      x="7.77783"
      y="6.77777"
      width="7.55556"
      height="0.944444"
      rx="0.2"
      fill="currentColor"
    />
    <rect
      x="7.77783"
      y="12.4444"
      width="7.55556"
      height="0.944444"
      rx="0.2"
      fill="currentColor"
    />
    <rect
      x="7.77783"
      y="15.2778"
      width="7.55556"
      height="0.944444"
      rx="0.2"
      fill="currentColor"
    />
  </svg>
</template>

<script>
export default {
  name: "FormIcon",
  props: {
    classes: {
      type: String,
      required: false,
      default: "",
    },
  },
};
</script>
