
import Vue from "vue";
import { IconPositions } from "~/components/layout/navbar/types";

export default Vue.extend({
  name: "NavItem",
  props: {
    iconPosition: {
      type: String,
      default: IconPositions.None,
      validator: (value: IconPositions): boolean =>
        Object.values(IconPositions).includes(value),
    },
  },
  data: () => ({
    IconPositions,
  }),
  computed: {
    hasIconSlot(): boolean {
      return !!this.$slots.icon;
    },
    iconClassName(): string {
      return "nav-item-icon";
    },
  },
  methods: {
    hasIconSlotPosition(position: string): boolean {
      return this.hasIconSlot && position === this.iconPosition;
    },
  },
});
