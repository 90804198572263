<template functional>
  <svg
    viewBox="0 0 24 24"
    fill="none"
    :class="props.classes"
    aria-hidden="true"
    role="img"
    focusable="false"
  >
    <template v-if="props.direction === 'left'">
      <rect
        x="8.99243"
        y="9.87122"
        width="11.5"
        height="3"
        rx="0.9"
        transform="rotate(45 8.99243 9.87122)"
        fill="currentColor"
      />
      <rect
        x="17.1243"
        y="5.98213"
        width="11.5"
        height="3"
        rx="0.9"
        transform="rotate(135 17.1243 5.98213)"
        fill="currentColor"
      />
    </template>
    <template v-if="props.direction === 'up'">
      <rect
        x="14.119"
        y="8.98727"
        width="11.5"
        height="3"
        rx="0.9"
        transform="rotate(135 14.119 8.98727)"
        fill="currentColor"
      />
      <rect
        x="18.0081"
        y="17.1191"
        width="11.5"
        height="3"
        rx="0.9"
        transform="rotate(-135 18.0081 17.1191)"
        fill="currentColor"
      />
    </template>
    <template v-if="props.direction === 'right'">
      <rect
        x="15.0029"
        y="14.1139"
        width="11.5"
        height="3"
        rx="0.9"
        transform="rotate(-135 15.0029 14.1139)"
        fill="currentColor"
      />
      <rect
        x="6.87109"
        y="18.0029"
        width="11.5"
        height="3"
        rx="0.9"
        transform="rotate(-45 6.87109 18.0029)"
        fill="currentColor"
      />
    </template>

    <template v-if="props.direction === 'down'">
      <rect
        x="9.87646"
        y="14.9978"
        width="11.5"
        height="3"
        rx="0.9"
        transform="rotate(-45 9.87646 14.9978)"
        fill="currentColor"
      />
      <rect
        x="5.9873"
        y="6.86595"
        width="11.5"
        height="3"
        rx="0.9"
        transform="rotate(45 5.9873 6.86595)"
        fill="currentColor"
      />
    </template>
  </svg>
</template>

<script>
export default {
  name: "ChevronIcon",
  props: {
    classes: {
      type: String,
      required: false,
      default: "",
    },
    direction: {
      type: String,
      required: false,
      default: "down",
    },
  },
};
</script>
