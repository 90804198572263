
import Vue, { PropType } from "vue";

const allowedTypes = [
  "file",
  "text",
  "number",
  "email",
  "password",
  "tel",
  "date",
];

export default Vue.extend({
  name: "FormInput",
  props: {
    id: { type: String, required: false, default: undefined },
    type: {
      type: String,
      default: "text",
      validator: (value) => allowedTypes.includes(String(value)),
    },
    required: { type: Boolean, default: false },
    accept: { type: String, default: "" },
    autocomplete: { type: String, default: "off" },
    ariaInvalid: {
      type: [Boolean, String] as PropType<
        boolean | "false" | "true" | "grammar" | "spelling"
      >,
      default: false,
    },
    maxLength: {
      default: null,
      type: String,
    },
    forceMaxLength: {
      default: false,
      type: Boolean,
    },
    min: {
      default: null,
      type: [Number, String],
    },
    max: {
      default: null,
      type: [Number, String],
    },
    readonly: { type: Boolean, default: false },
    size: { type: String, required: false, default: undefined },
    isStateValid: {
      type: Boolean as PropType<boolean | null | undefined>,
      default: null,
    },
    pill: {
      type: Boolean,
      default: false,
    },
    legacy: {
      type: Boolean,
      default: false,
    },
    value: {
      type: [String, Number],
      default: "",
    },
    step: {
      type: [String, Number],
      default: 0,
    },
    // applicable only for numbers
    noSpinners: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      dirty: false,
    };
  },
  computed: {
    classes() {
      return [
        {
          dirty: this.dirty,
          "rounded-full": this.pill && this.legacy,
          rounded: !this.pill && this.legacy,
          legacy: this.legacy,
          [`${this.size}`]: this.size && !this.legacy,
          [`legacy-${this.size}`]: this.size && this.legacy,
          "is-valid": this.isStateValid === true,
          "is-invalid": this.isStateValid === false,
          "no-spinners": this.noSpinners,
          "read-only": this.readonly,
        },
        "bg-grey-2",
        "md:max-w-[345px]",
      ];
    },
    computedAriaInvalid() {
      if (this.ariaInvalid) {
        return this.ariaInvalid.toString();
      }

      return this.isStateValid === false ? "true" : undefined;
    },
  },
  methods: {
    onInput(event: Event) {
      this.dirty = true;

      // literally enforce max length
      if (this.forceMaxLength && this.maxLength) {
        const target = event.target as HTMLInputElement;
        if (target.value.length >= Number(this.maxLength)) {
          target.value = target.value.substring(0, Number(this.maxLength));
        }
      }

      const { value } = event.target as HTMLInputElement;

      if (event.defaultPrevented) {
        event.preventDefault();
        return;
      }

      this.$emit("input", value);
    },
    onChange(event: Event) {
      this.dirty = true;

      const { value } = event.target as HTMLInputElement;

      if (event.defaultPrevented) {
        event.preventDefault();
        return;
      }

      this.$emit("change", value);
    },
    onBlur(event: Event) {
      this.$emit("blur", event);
    },
  },
});
