import { isEmpty } from "ramda";

/**
 * This function will look at the meta data for the given fields and will return an array of matched field data.
 * @param meta Meta data
 * @param fields The fields that need to be included in the returned array
 * @returns An array with data as nested objects
 */
export function groupValuesByNumericSuffix(
  meta: object,
  fields: string[]
): Array<Record<string, any>> {
  const groups: Array<Record<string, any>> = [];
  const suffixPattern = new RegExp(`^(${fields.join("|")})_(\\d+)$`);

  if (isEmpty(meta) || fields.length === 0) {
    return groups;
  }

  Object.entries(meta).forEach(([key, value]) => {
    const [, fieldName, keyIndex] = key.match(suffixPattern) ?? [];
    if (!isEmpty(keyIndex)) {
      const groupIndex = parseInt(keyIndex, 10);
      groups[groupIndex] = {
        ...groups[groupIndex],
        [fieldName]: value,
      };
    }
  });

  return groups.filter(Boolean);
}

export default groupValuesByNumericSuffix;
