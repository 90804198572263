import type { Context } from "@nuxt/types";

/**
 * Plugin set-up purely to aid vuex-shared-mutations to ensure
 * other browser tabs update themselves correctly
 *
 * NOTE: used within vuex-shared-mutations.ts, so this entire file recieves a Context arg
 */
export default ({ store, $axios }: Context) => {
  store.subscribe((mutation) => {
    if (
      mutation.type === "auth/setUser" ||
      mutation.type === "guest/setGuest"
    ) {
      $axios.setHeader("X-Toolstation-Customer-Id", mutation.payload.token);
    }
    if (mutation.type === "guest/setGuestCheckoutSession") {
      $axios.setHeader("X-Toolstation-Session-ID", mutation.payload);
    }

    if (mutation.type === "auth/deleteUser") {
      delete $axios.defaults.headers["X-Toolstation-Session-ID"];
      delete $axios.defaults.headers["X-Toolstation-Customer-Id"];
      window.location.href = "/logout";
    }
  });
};
