import {
  BrDocumentResponse,
  DocumentPathKey,
  getBrDocument,
  getBrExperiencePage,
} from "~/services/api/bloomreach.api";
import { handleError } from "~/services/helpers";
import { get } from "~/utils/lru-cache";
import { camelizeKeys } from "~/utils/utils";

/**
 * Fetch Bloomreach document data
 * @param documentPath
 * @param document
 * @param brContentURL
 */
export const fetchBrDocument = async (
  documentPath: DocumentPathKey,
  document: string,
  brContentURL: string
): Promise<any> => {
  try {
    const fetchDocument = async (): Promise<any> => {
      return await getBrDocument(documentPath, document, brContentURL)
        .then((response: BrDocumentResponse) => {
          return response?.document.fields;
        })
        .then(camelizeKeys);
    };

    return await fetchDocument();
  } catch (error) {
    handleError(error);
  }
};

/**
 * Fetch Bloomreach experience page
 * @param config
 * @param getCachedPage
 */
export const fetchBrExperiencePage = async (
  config: any,
  getCachedPage: boolean
): Promise<any> => {
  try {
    const { url } = config;
    return getCachedPage
      ? await get(url, async () => await getBrExperiencePage(config))
      : await getBrExperiencePage(config);
    // return await getBrExperiencePage(config);
    // @todo turn on the above return when content API has implemented experience page caching
  } catch (error) {
    handleError(error);
  }
};
