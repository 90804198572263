import { NuxtAxiosInstance } from "@nuxtjs/axios";
import {
  ApiSavedListResponse,
  ApiCreateSavedListItemRequest,
  ApiUpdateSavedListRequest,
  ApiUpdateSavedListItemRequest,
} from "~/services/api/savedLists.api.d";

const ENDPOINTS = {
  SAVED_LISTS: (savedListRef: string) => {
    return `/saved-lists/${savedListRef}`;
  },
  SAVED_LISTS_ITEMS: (savedListRef: string) => {
    return `/saved-lists/${savedListRef}/items`;
  },
  SAVED_LISTS_ITEMS_PRODUCT: (savedListRef: string, productCode: string) => {
    return `/saved-lists/${savedListRef}/items/${productCode}`;
  },
};

export const getSavedList = async (
  $axios: NuxtAxiosInstance,
  savedListRef: string
): Promise<ApiSavedListResponse> =>
  await $axios.$get(ENDPOINTS.SAVED_LISTS(savedListRef));

export const updateSavedList = async (
  $axios: NuxtAxiosInstance,
  savedListRef: string,
  data: ApiUpdateSavedListRequest
): Promise<ApiSavedListResponse> =>
  await $axios.$patch(ENDPOINTS.SAVED_LISTS(savedListRef), data);

export const deleteSavedList = async (
  $axios: NuxtAxiosInstance,
  savedListRef: string
): Promise<AxiosResponse<any>> =>
  await $axios.$delete(ENDPOINTS.SAVED_LISTS(savedListRef));

export const createSavedListsItems = async (
  $axios: NuxtAxiosInstance,
  savedListRef: string,
  data: ApiCreateSavedListItemRequest
): Promise<ApiSavedListResponse> =>
  await $axios.$post(ENDPOINTS.SAVED_LISTS_ITEMS(savedListRef), data);

export const updateSavedListsItemsProduct = async (
  $axios: NuxtAxiosInstance,
  savedListRef: string,
  productCode: string,
  data: ApiUpdateSavedListItemRequest
): Promise<ApiSavedListResponse> =>
  await $axios.$patch(
    ENDPOINTS.SAVED_LISTS_ITEMS_PRODUCT(savedListRef, productCode),
    data
  );

export const deleteSavedListsItemsProduct = async (
  $axios: NuxtAxiosInstance,
  savedListRef: string,
  productCode: string
): Promise<ApiSavedListResponse> =>
  await $axios.$delete(
    ENDPOINTS.SAVED_LISTS_ITEMS_PRODUCT(savedListRef, productCode)
  );
