
import Vue, { PropType } from "vue";
import { NuxtError } from "@nuxt/types";
import Error from "~/components/shared/error.vue";

export default Vue.extend({
  name: "ErrorLayout",
  components: { Error },
  layout: "error",
  props: {
    error: {
      type: Object as PropType<NuxtError>,
      default: () => ({}) as NuxtError,
    },
  },
});
