import { NuxtAxiosInstance } from "@nuxtjs/axios";
import { prop } from "ramda";
import type {
  CustomerApiDefaultBranchResponse,
  CustomerData,
} from "./customers";
import { Customer, CustomerExists } from "./customers.service.d";
import {
  convertGuestToCustomer,
  createCustomerTrolleys,
  createCustomerUsernameCheck,
  customerLogin,
  customerRegister,
  getCustomerAccounts,
  getCustomerActiveTrolleys,
  getCustomerById,
  getCustomerOrders,
  getCustomerTrolleys,
  guestCustomerRegister,
  resetCustomerPassword,
  sendCustomerResetPasswordEmail,
  updateCustomerById,
  updateCustomerContactPreferences,
  updateCustomerPassword,
  updateDefaultBranch,
  updateLoyaltyAccountStatus,
  getLoyaltyAccountLink,
} from "~/services/api/customers.api";
import {
  ApiCustomerAuthRegisterRequest,
  ApiCustomerResetPasswordRequest,
  ApiGuestCustomerAuthRegisterRequest,
  ApiLoginRequest,
  ApiPasswordResetRequest,
  ApiUpdateCustomerPreferencesRequest,
  ApiUpdateCustomerRequest,
  ApiUpdateGuestCustomerRequest,
} from "~/services/api/customers.api.d";
import { Order } from "~/services/orders.service.d";
import { RawTrolleyData } from "~/types/trolley";
import { camelizeKeys } from "~/utils/utils";

export const postCustomerLogin = async (
  axios: NuxtAxiosInstance,
  data: ApiLoginRequest
): Promise<Customer> =>
  await customerLogin(axios, data)
    .then((response) => response.data)
    .then(camelizeKeys);

export const getCustomerActiveTrolleysByCustomerId = async (
  axios: NuxtAxiosInstance,
  customerId: string
): Promise<AxiosResponse<RawTrolleyData[]>> =>
  await getCustomerActiveTrolleys(axios, customerId);

export const getCustomerTrolleysByCustomerId = async (
  axios: NuxtAxiosInstance,
  customerId: string
): Promise<AxiosResponse<RawTrolleyData[]>> =>
  await getCustomerTrolleys(axios, customerId);

export const createCustomerTrolley = async (
  axios: NuxtAxiosInstance,
  customerId: string
): Promise<AxiosResponse<RawTrolleyData>> =>
  await createCustomerTrolleys(axios, customerId);

export const createCustomerUsernameCheckByUsername = async (
  axios: NuxtAxiosInstance,
  email: string
): Promise<AxiosResponse<CustomerExists>> =>
  await createCustomerUsernameCheck(axios, {
    username: email,
  });

export const fetchCustomerById = async (
  $axios: NuxtAxiosInstance,
  customerId: string
): Promise<CustomerData> =>
  await getCustomerById($axios, customerId).then(prop("data"));

export const updateSelectedBranch = async (
  $axios: NuxtAxiosInstance,
  customerId: string,
  siteId: string
): Promise<{ data: CustomerApiDefaultBranchResponse } | null> =>
  await updateDefaultBranch($axios, customerId, {
    site_id: siteId,
  });

export const updateCustomer = async (
  $axios: NuxtAxiosInstance,
  customerId: string,
  data: ApiUpdateCustomerRequest
): Promise<Customer> =>
  await updateCustomerById($axios, customerId, data)
    .then((response) => response.data)
    .then(camelizeKeys);

export const updateCustomerPreferences = async (
  $axios: NuxtAxiosInstance,
  customerId: string,
  data: ApiUpdateCustomerPreferencesRequest
): Promise<Customer> =>
  await updateCustomerContactPreferences($axios, customerId, data)
    .then((response) => response.data)
    .then(camelizeKeys);

/**
 * Requires customer token to be sent before can change the password
 *
 * @param $axios
 * @param data
 */
export const passwordReset = async (
  $axios: NuxtAxiosInstance,
  data: ApiCustomerResetPasswordRequest
): Promise<boolean> =>
  await resetCustomerPassword($axios, data).then(
    (response) => response.status === 204
  );

/**
 * Reset the customer password
 */
export const updateCustomerPasswordReset = async (
  $axios: NuxtAxiosInstance,
  customerId: string,
  data: ApiPasswordResetRequest
): Promise<boolean> =>
  await updateCustomerPassword($axios, customerId, data)
    .then((response) => response.data)
    .then(camelizeKeys);

export const sendCustomerResetPassword = async (
  $axios: NuxtAxiosInstance,
  email: string
): Promise<boolean> =>
  await sendCustomerResetPasswordEmail($axios, {
    username: email,
  }).then((response) => response.status === 204);

export const fetchCustomerOrders = async (
  $axios: NuxtAxiosInstance,
  customerId: string,
  page: number = 1,
  pageLimit: number = 50,
  paymentType: string | null = null
): Promise<Order[]> =>
  await getCustomerOrders($axios, customerId, page, pageLimit, paymentType)
    .then((response) => response.data)
    .then(camelizeKeys);

export const fetchCustomerAccounts = async (
  $axios: NuxtAxiosInstance,
  customerId: string
): Promise<any> =>
  await getCustomerAccounts($axios, customerId)
    .then((response) => response.data)
    .then(camelizeKeys);

export const createCustomerAccount = async (
  $axios: NuxtAxiosInstance,
  data: ApiCustomerAuthRegisterRequest
): Promise<CustomerData> =>
  await customerRegister($axios, data).then((response) => response.data);

export const createGuestCustomer = async (
  $axios: NuxtAxiosInstance,
  data: ApiGuestCustomerAuthRegisterRequest
) =>
  await guestCustomerRegister($axios, data).then((response) => response.data);

export const convertGuestAccountToCustomerAccount = async (
  $axios: NuxtAxiosInstance,
  customerId: string,
  data: ApiUpdateGuestCustomerRequest
) =>
  await convertGuestToCustomer($axios, customerId, data).then(
    (response) => response.data
  );

export const setLoyaltyAccount = async (
  $axios: NuxtAxiosInstance,
  customerId: string,
  enabled: boolean
) => await updateLoyaltyAccountStatus($axios, customerId, enabled);

export const getLoyaltyAccountSsoLink = async (
  $axios: NuxtAxiosInstance,
  customerId: string
) => await getLoyaltyAccountLink($axios, customerId);
