<template>
  <div class="aspect-square">
    <div
      v-if="backgroundColour"
      :style="backgroundColourClass"
      class="h-full w-full object-cover"
    />
    <div
      class="border-grey-3 border-1 bg-white h-full flex items-center justify-center"
      :class="{
        'p-4': !noImagePadding,
      }"
    >
      <p
        v-if="title"
        class="font-semibold text-size-4 text-center my-2"
        :style="titleTextColour !== '' ? titleTextColour : 'color: #065CAB'"
      >
        {{ title }}
      </p>
    </div>
  </div>
</template>

<script setup>
import { computed } from "vue";

const props = defineProps({
  title: {
    type: String,
    required: true,
  },
  titleTextColour: {
    type: String,
    required: false,
    default: "color: #065CAB",
  },
  backgroundColour: {
    type: String,
    required: false,
    default: null,
  },
  noImagePadding: {
    type: Boolean,
    required: false,
    default: false,
  },
});

const backgroundColourClass = computed(
  () => `background-color:${props.backgroundColour}`
);
</script>
