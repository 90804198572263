import { render, staticRenderFns } from "./AlertBox.vue?vue&type=template&id=0805f41a&scoped=true"
import script from "./AlertBox.vue?vue&type=script&lang=ts"
export * from "./AlertBox.vue?vue&type=script&lang=ts"
import style0 from "./AlertBox.vue?vue&type=style&index=0&id=0805f41a&prod&lang=postcss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0805f41a",
  null
  
)

export default component.exports