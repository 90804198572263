<template functional>
  <svg viewBox="0 0 24 24" fill="none" :class="props.classes">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M5 4.425C5 3.63911 5.598 3 6.33334 3H15.6667C16.4021 3 17.0001 3.63911 17.0001 4.425V10.8375H11.6667C11.2987 10.8375 11 11.1567 11 11.55C11 11.9433 11.2987 12.2625 11.6667 12.2625H17.0001V18.675C17.0001 19.4609 16.4021 20.1 15.6667 20.1H6.33334C5.598 20.1 5 19.4609 5 18.675V4.425ZM17.0001 12.2625L20.7241 12.2625L19.1954 13.8962C19.0213 14.0749 18.9511 14.3403 19.012 14.59C19.0729 14.8397 19.2554 15.0347 19.489 15.0998C19.7226 15.1649 19.9709 15.0899 20.1381 14.9038L22.8048 12.0538C23.0651 11.7755 23.0651 11.3245 22.8048 11.0462L20.1381 8.19624C20.0126 8.05835 19.8403 7.98055 19.6603 7.98054C19.389 7.98062 19.1448 8.15636 19.0427 8.42498C18.9406 8.6936 19.001 9.00154 19.1954 9.20376L20.7241 10.8375H17.0001V12.2625Z"
      fill="currentColor"
    />
  </svg>
</template>

<script>
export default {
  name: "LogoutIcon",
  props: {
    classes: {
      type: String,
      required: false,
      default: "",
    },
  },
};
</script>
