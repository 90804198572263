
import Vue from "vue";
import { mapGetters } from "vuex";
import { fetchBrDocument } from "~/services/bloomreach.service";
import { BloomreachFolderPaths } from "~/services/api/bloomreach.api";
import MultiTextAndImage from "~/components/multi-text-and-image/MultiTextAndImage.vue";
import PathwaysAndRecsMixin from "~/components/product-recommendations/mixins/PathwaysAndRecsMixin";
import ProductRecommendations from "~/components/product-recommendations/ProductRecommendations.vue";
import LoadingSpinner from "~/components/UI/loading/LoadingSpinner.vue";

export default Vue.extend({
  name: "ErrorLayout",
  components: { MultiTextAndImage, LoadingSpinner, ProductRecommendations },
  mixins: [PathwaysAndRecsMixin],
  layout: "error",
  props: {
    bloomreachDocPath: {
      type: String,
      required: false,
      default: "404",
    },
  },
  data(): {
    pageData: any;
    recommendations: any;
  } {
    return {
      pageData: {},
      recommendations: {},
    };
  },
  async fetch() {
    const { brxmContentEndpoint: brContentURL } = this.$config;

    // Fetch Bloomreach 404 document
    this.pageData = await fetchBrDocument(
      BloomreachFolderPaths.ErrorPage,
      this.bloomreachDocPath,
      brContentURL
    );

    if (Object.keys(this.pageData).length !== 0) {
      this.recommendations = await (this as any).getPathwaysAndRecs(
        this.pageData?.pathways
      );
    }
  },
  fetchOnServer: true,
  computed: {
    ...mapGetters({
      initialUpdate: "viewport/initialUpdate",
    }),
    pathwaysDataLength(): number {
      return this.recommendations?.data?.length;
    },
  },
});
