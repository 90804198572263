<template functional>
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    :class="props.classes"
  >
    <path
      d="M12.1626 3.74261C12.6644 3.26558 13.3259 3.00013 14.013 3H18.6295C19.2582 3 19.8611 3.25379 20.3057 3.70554C20.7502 4.15728 21 4.76998 21 5.40885V10.0593C21 10.4209 20.9299 10.779 20.7937 11.1131C20.6575 11.4471 20.4579 11.7507 20.2062 12.0063L12.1985 20.1434C11.6587 20.6919 10.9266 21 10.1633 21C9.3999 21 8.66779 20.6919 8.12798 20.1434L3.8434 15.7896C3.57035 15.5122 3.35501 15.1819 3.21018 14.8182C3.06536 14.4545 2.99401 14.0649 3.00039 13.6726C3.00678 13.2803 3.09075 12.8934 3.24733 12.5348C3.40391 12.1761 3.62989 11.8532 3.91181 11.5851L12.1626 3.74261ZM16.5976 9.19418C17.0466 9.19418 17.4773 9.0129 17.7949 8.69022C18.1124 8.36755 18.2908 7.92991 18.2908 7.47357C18.2908 7.01724 18.1124 6.5796 17.7949 6.25692C17.4773 5.93425 17.0466 5.75297 16.5976 5.75297C16.1485 5.75297 15.7178 5.93425 15.4003 6.25692C15.0827 6.5796 14.9043 7.01724 14.9043 7.47357C14.9043 7.92991 15.0827 8.36755 15.4003 8.69022C15.7178 9.0129 16.1485 9.19418 16.5976 9.19418Z"
      fill="currentColor"
    />
  </svg>
</template>

<script>
export default {
  name: "TagIcon",
  props: {
    classes: {
      type: String,
      required: false,
      default: "",
    },
  },
};
</script>
