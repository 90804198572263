<template functional>
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    :class="props.classes"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M3.69231 6C3.27584 6 3 6.27231 3 6.68344V16.2515C3 16.6627 3.27584 16.935 3.69231 16.935H5.07692C5.21484 15.7737 6.25331 14.8206 7.5 14.8206C8.74669 14.8206 9.78516 15.7737 9.92308 16.935H12.6923C13.1088 16.935 13.3846 16.6627 13.3846 16.2515V6.68344C13.3846 6.27231 13.1088 6 12.6923 6H3.69231ZM14.7692 8.73374C14.3528 8.73374 14.0769 9.00605 14.0769 9.41718V16.2515C14.0769 16.5933 14.3528 16.935 14.7692 16.935C14.9072 15.7737 15.9456 14.8206 17.1923 14.8206C18.439 14.8206 19.4775 15.7043 19.6154 16.935H20.3077C20.7242 16.935 21 16.6627 21 16.2515V12.8344C21 12.2203 20.4375 11.4675 20.4375 11.4675L18.9231 9.41718L18.8816 9.36785C18.5596 8.98441 18.3491 8.73374 17.8846 8.73374H14.7692ZM17.8197 10.1006H16.1538V12.8344H19.8317C19.8317 12.6339 19.6955 12.4309 19.5608 12.2304L19.5505 12.215L18.101 10.2288C18.101 10.2288 17.9576 10.1006 17.8197 10.1006ZM7.5 15.6962C6.62109 15.6962 5.89904 16.4091 5.89904 17.2767C5.89904 18.1443 6.62109 18.8571 7.5 18.8571C8.3789 18.8571 9.10096 18.1443 9.10096 17.2767C9.10096 16.4091 8.3789 15.6962 7.5 15.6962ZM15.5913 17.2767C15.5913 16.4091 16.3134 15.6962 17.1923 15.6962C18.0712 15.6962 18.7933 16.4091 18.7933 17.2767C18.7933 18.1443 18.0712 18.8571 17.1923 18.8571C16.3134 18.8571 15.5913 18.1443 15.5913 17.2767Z"
      fill="currentColor"
    />
  </svg>
</template>

<script>
export default {
  name: "DeliveryIcon",
  props: {
    classes: {
      type: String,
      required: false,
      default: "",
    },
  },
};
</script>
