import {
  allPass,
  always,
  complement,
  compose,
  either,
  equals,
  filter,
  is,
  isEmpty,
  isNil,
  not,
  reject,
} from "ramda";
import type { PromoBlocks } from "~/components/UI/banners/PromoBlocks.d";

export const isNotLastItemOfList = (index: number, list: any[]) =>
  list.length - 1 !== index;
export const isLastItemOfList = compose(not, isNotLastItemOfList);

export const promoSlideGroupToHref = (
  desktopSlides: any,
  mobileSlides: any
): PromoBlocks => {
  function getData(obj: any) {
    if (obj) {
      return obj.promoSlide.map((slides: any) => {
        return slides.imageAndLink.map((slide: any) => {
          return {
            src: slide.imageUrl,
            alt: slide.imageAltText,
            href: slide.linkUrl,
          };
        });
      });
    }
  }

  return {
    desktopSlides: { slides: getData(desktopSlides) },
    mobileSlides: { slides: getData(mobileSlides) },
  };
};

export const asyncCallWithTimeout = async <T>(
  asyncPromise: Promise<T>,
  timeLimit: number
  // eslint-disable-next-line require-await
): Promise<T> => {
  let timeoutHandle: NodeJS.Timeout;

  const timeoutPromise = new Promise<T>((_resolve, reject) => {
    timeoutHandle = setTimeout(
      () => reject(new Error("Async call timeout limit reached")),
      timeLimit
    );
  });

  return Promise.race([asyncPromise, timeoutPromise]).then((result: T) => {
    clearTimeout(timeoutHandle);
    return result;
  });
};

export const safeJSONParse = (str: any, fall = {}) => {
  try {
    return JSON.parse(str);
  } catch (e) {
    return fall;
  }
};

export const just = always;
export const constantly = always;

export const isNumber = is(Number);

export const isTrue = equals(true);
export const isFalse = complement(isTrue);
export const isNotFalse = complement(equals(false));
export const isUndefined = equals(undefined);
export const isEmptyOrNil = either(isEmpty, isNil);

export const isNotEmpty = compose(not, either(isNil, isEmpty));
export const isNotNil = complement(isNil);
export const isNotEmptyOrNil = complement(isEmptyOrNil);

export const filterNotEmpty = filter(isNotEmpty);
export const filterFalse = filter(isNotFalse);
export const filterFalsy = filter(allPass([isNotNil, isNotEmpty, isNotFalse]));

export const rejectEmptyOrNil = reject(isEmptyOrNil);
export const rejectUndefined = reject<any>(isUndefined);

type Matches<T extends string | number | symbol, R> = { default: R } & Record<
  T,
  R
>;

/**
 * Sugar over switch statements
 * @param T value
 * @param matches Record<T, R>
 * @return R
 * @example
 * const word = match(10, { 10: "ten", 5: "five", default: "unknown" })
 */
export const match = <T extends string | number | symbol = any, R = any>(
  val: T,
  matches: Matches<T, R>
): R => {
  return matches?.[val] ?? matches.default;
};

/**
 *
 * Ramda replacements (native JS)
 *
 */

/**
 * Finds the intersection between two sets
 * @param setA T[]
 * @param setB T[]
 * @returns T[]
 */
export const intersection = <T = any>(setA: T[], setB: T[]) =>
  setA.filter((x) => setB.includes(x));

/**
 * Drops tail of list whilst predicate is true
 * @param pred (x: T) => boolean
 * @param l T[]
 */
export const dropLastWhile = <T = any>(pred: (x: T) => boolean, l: T[]) =>
  l.slice(0, l.findIndex((x) => !pred(x)) + 1);

/**
 * Inverts an object where it's K-V become V-K (lookups)
 * @param object Object
 */
export const invertObj = (object: Object) =>
  Object.fromEntries(Object.entries(object).map(([k, v]) => [v, k]));

/*
 * Returns a subset of K-V that satisfy the given keys
 * @param keys string[] | number[] | symbol[]
 * @param object Record<K, any>
 * @returns T
 */
export const pick = <
  K extends string | number | symbol = string,
  T extends Record<K, any> = Record<K, any>,
>(
  keys: K[],
  object: T
) => Object.fromEntries(keys.map((k) => [k, object[k]]));

/*
 * Returns a list of plucked property from a list of items
 * @param keys string[] | number[] | symbol[]
 * @param object Record<K, any>
 * @returns T[K]
 */
export const pluck = <
  K extends string | number | symbol = string,
  T extends Record<K, any> = Record<K, any>,
>(
  property: K,
  l: T[]
) => l.map((t) => t[property]);

/**
 * Returns an indexed object based on property
 * @params property string | number | symbol
 * @param object Record<K, any>
 * @returns Record<k, T>
 */
export const indexBy = <
  K extends string | number | symbol = string,
  T extends Record<K, any> = Record<K, any>,
>(
  property: K,
  l: T[]
) => Object.fromEntries(l.map((t) => [t[property], t]));
