<template functional>
  <svg viewBox="0 0 28 27" :class="props.classes">
    <path
      d="M8.18 1.956l6.047 15.129a5.69 5.69 0 0 0-1.2.328 5.5 5.5 0 0 0-1.092.58L6.345 4.007l-4.658 1.84A1.23 1.23 0 1 1 .781 3.56l5.8-2.293a1.236 1.236 0 0 1 1.6.689H8.18zm19.682 15.628a1.226 1.226 0 0 1-.694 1.6l-6.488 2.565a6.076 6.076 0 0 0-.33-1.192 5.9 5.9 0 0 0-.584-1.092l6.488-2.565a1.242 1.242 0 0 1 1.609.687l-.001-.003zM24.94 8.475l2.634 6.58-8.664 3.423a5.712 5.712 0 0 0-3.379-1.447l-1.668-4.179L24.94 8.475zM13.405 11.71L10.78 5.131 21.857.756l2.625 6.579-11.077 4.375zm.076 6.843a4.369 4.369 0 1 0 3.296 8.093 4.369 4.369 0 0 0-3.296-8.093zm2.338 5.856a1.918 1.918 0 1 1-1.396-3.573 1.918 1.918 0 0 1 1.396 3.573z"
      fill="currentColor"
      fill-rule="evenodd"
    />
  </svg>
</template>

<script>
export default {
  name: "TrolleyIcon",
  props: {
    classes: {
      type: String,
      required: false,
      default: "",
    },
  },
};
</script>
