import { sort } from "ramda";
import { TaxonomyData, Taxonomy } from "~/services/taxonomies.d";
import type { Ancestor } from "~/lib/taxonomies/breadcrumbItems";
import { getBrandTaxonomy } from "~/lib/taxonomies/brands";
import { getCampaignTaxonomy } from "~/lib/taxonomies/campaigns";
import { prefixCategory } from "~/lib/shared";
import { Response } from "~/services/search.d";

export enum CustomTaxonomyPageNames {
  brands = "brands",
  campaign = "campaign",
}

export const sortTaxonomiesNaturally = sort(
  (
    { taxonomy: { name: name1 } }: TaxonomyData,
    { taxonomy: { name: name2 } }: TaxonomyData
  ) => {
    return name1.localeCompare(name2);
  }
);

export const sortAs = (
  sortBy: string,
  children: TaxonomyData[]
): TaxonomyData[] =>
  // Results already come back sorted as "popularity", so this just re-sorts them into A to Z
  sortBy === "atoz" ? sortTaxonomiesNaturally(children) : children;

export const generateTopParentSlug = (ancestors: Ancestor[]): string => {
  const parent = ancestors[0];
  const topParentSlug = parent?.taxonomy?.slug
    ? `/${parent.taxonomy.slug}`
    : "";
  return topParentSlug;
};

export const generateTaxonomySlug = (taxonomy: Taxonomy): string => {
  return taxonomy?.slug ? `/${taxonomy.slug}` : "";
};

export const generateCategorySlug = (id: string | null): string => {
  return id ? `/${prefixCategory(id)}` : "";
};

export const getTaxonomyMap = {
  brands: getBrandTaxonomy,
  campaign: getCampaignTaxonomy,
};

const getCampaignName = (
  campaign: string[] | string | undefined,
  slugName: string
) => {
  const transformSlug = (slug: string): string => {
    return slug.split("-").map((str) => {
      return str
        .split(" ")
        .map((word) => {
          return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
        })
        .join(" ");
    })[0];
  };

  if (Array.isArray(campaign)) {
    return (
      campaign.find((el) => el?.toLocaleLowerCase() === slugName) ||
      transformSlug(slugName)
    );
  }
  return transformSlug(slugName);
};

export const getCustomTaxonomyName = (
  val: string,
  response: Response,
  slugName: string
) => {
  switch (val) {
    case CustomTaxonomyPageNames.campaign:
      return getCampaignName(response.docs[0]?.campaign, slugName);
    case CustomTaxonomyPageNames.brands:
      return response.docs[0]?.brand;
    default:
      return "";
  }
};
