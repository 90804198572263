import { NuxtCookies } from "cookie-universal-nuxt";
import { curry } from "ramda";
import type { Store } from "vuex/types/index";
import { AppCookieNames } from "~/constants/ecomApi";
import { CustomerApiResponse } from "~/services/customers";

export const modal = ($store: Store<any>, productCode: string) =>
  $store.commit("saveForLater/setProductInQuestion", productCode);
export const handleLogin = curry(
  ($store: Store<any>, response: CustomerApiResponse | null) => {
    if (response) {
      $store.commit("auth/setUser", response.data);
    }
  }
);
export const handleCookie = (
  $cookies: NuxtCookies,
  response: CustomerApiResponse
) => {
  $cookies.set(AppCookieNames.CustomerJWT, response.data.token, {
    secure: true,
  });
};

export const checkConsent = (group: string): boolean => {
  const oneTrustLoadedEvent =
    window.dataLayer.find((item) => item.event === "cookieConsent") ||
    window.dataLayer.find((item) => item.event === "OneTrustLoaded");

  if (!oneTrustLoadedEvent) {
    return false;
  }

  const activeGroups =
    oneTrustLoadedEvent?.OnetrustActiveGroups?.split(",") ?? [];
  const cookieValues = oneTrustLoadedEvent?.cookievalues ?? {};

  return cookieValues[group] === "1" || activeGroups.includes(group);
};
