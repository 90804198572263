import { GetterTree, MutationTree } from "vuex/types";
import { RootState } from "./index";

interface TrackingState {
  eventsToConsume: string[] | object[];
}

export enum TrackingEvents {
  PageLoaded = "trackPageLoaded",
  SearchPage = "trackSearchPage",
  SearchSuggestion = "trackSearchSuggestion",
  SearchSuggestionClicked = "trackSearchSuggestionClick",
  SearchInput = "trackSearchInput",
  SearchRedirected = "trackSearchRedirected",
  LastEvent = "trackLastEvent",
  SaveForLater = "trackSaveForLater",
  Variations = "trackVariations",
  ModalClose = "trackModalClose",
  Catalogue = "trackCatalogueClick",
  ProductClick = "trackProductClick",
  PromoClick = "trackPromoClick",
  AddToCartClick = "trackAddToCartClick",
  PathwaysAndRecsViewPixel = "pathwaysAndRecsViewPixel",
  PathwaysAndRecsClickPixel = "pathwaysAndRecsClickPixel",
  ProductPageView = "productPageView",
  BannerClick = "trackBannerClick",
  BannersOnPage = "trackBannersOnPage",
  BannerView = "trackBannerView",
  SmartBannerVisible = "trackSmartBannerVisible",
  SmartBannerClick = "trackSmartBannerClick",
  SmartBannerClose = "trackSmartBannerClose",
  ProductPageLoaded = "productPageLoaded",
  SearchOrCategory = "trackSearchOrCategory",
  PageView = "trackPageView",
  MetaData = "trackMetaData",
  TrackTrolleyUpdate = "trackTrolleyUpdate",
  CheckoutConfirmation = "checkoutConfirmation",
  MonetateExperienceDecision = "trackMonetateExperimentDecision",
  StockLoaded = "trackStockLoaded",
  PromoAndRecsClick = "promoAndRecsClick",
  OutOfStockMessage = "trackOutOfStockMessageVisible",
}

export const state = (): TrackingState => ({
  eventsToConsume: [],
});

export const mutations: MutationTree<TrackingState> = {
  addTrackingEvent: (
    state: TrackingState,
    trackingEvent: string | object
  ): void => {
    if (typeof trackingEvent === "object") {
      // @ts-ignore
      state.eventsToConsume.push({ ...trackingEvent });
    } else {
      // @ts-ignore
      state.eventsToConsume.push(trackingEvent);
    }
  },
  trackingEventConsumed: (state: TrackingState): void => {
    state.eventsToConsume.shift();
  },
};

export const getters: GetterTree<TrackingState, RootState> = {
  getEventToConsume: (state: TrackingState): string[] | object[] =>
    state.eventsToConsume,
};

export default {
  state,
  mutations,
  getters,
};
