<template>
  <div class="h-full flex flex-col relative rounded-xl">
    <div
      class="rounded-xl border-blue border-1 flex items-center p-2 md:p-8 justify-center overflow-hidden"
      :class="heightClasses"
    >
      <div
        v-if="backgroundColour"
        :style="backgroundColourClass"
        class="object-contain w-3/4 h-full"
      />
      <img
        v-else
        class="object-contain w-3/4"
        :src="imageUrl"
        :alt="title"
        loading="lazy"
      />
    </div>
    <p
      class="text-center mt-4 text-size-5 md:text-size-7 font-semibold"
      :style="titleTextColour !== '' ? titleTextColour : 'color: #065CAB'"
    >
      {{ title }}
    </p>
  </div>
</template>

<script setup>
import { computed } from "vue";

const props = defineProps({
  heightClasses: {
    type: String,
    required: true,
  },
  imageUrl: {
    type: String,
    required: true,
  },
  backgroundColour: {
    type: String,
    required: false,
    default: null,
  },
  title: {
    type: String,
    required: true,
  },
  titleTextColour: {
    type: String,
    required: false,
    default: "color: #065CAB",
  },
});

const backgroundColourClass = computed(
  () => `background-color:${props.backgroundColour}`
);
</script>
