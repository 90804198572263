<template functional>
  <svg viewBox="0 0 24 24" fill="none" :class="props.classes">
    >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M9.55357 3.85L10.4643 3H14.1071L15.0179 3.85H19.5714V5.55H5V3.85H9.55357ZM5.91062 18.3V7.25H18.6606V18.3C18.6606 19.235 17.841 20 16.8392 20H7.73205C6.73026 20 5.91062 19.235 5.91062 18.3ZM10.4644 8.94997H8.64298V18.3H10.4644V8.94997ZM14.107 8.94997H15.9284V18.3H14.107V8.94997Z"
      fill="currentColor"
    />
  </svg>
</template>

<script>
export default {
  name: "TrashIcon",
  props: {
    classes: {
      type: String,
      required: false,
      default: "",
    },
  },
};
</script>
