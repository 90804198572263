<template functional>
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    :class="props.classes"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M12 6C6.64902 6 3.19259 11.5267 3.13008 11.6273C3.04593 11.7333 3.00009 11.8647 3 12C3.00011 12.1161 3.03389 12.2297 3.09727 12.327L3.09961 12.3305C3.10993 12.3518 6.0113 18 12 18C17.9633 18 20.8604 12.4072 20.8957 12.3387L20.9027 12.327C20.9661 12.2297 20.9999 12.1161 21 12C21 11.8652 20.9546 11.7343 20.8711 11.6285L20.8699 11.6273C20.8074 11.5267 17.351 6 12 6ZM12 7.8C14.3196 7.8 16.2 9.6804 16.2 12C16.2 14.3196 14.3196 16.2 12 16.2C9.68044 16.2 7.80004 14.3196 7.80004 12C7.80004 9.6804 9.68044 7.8 12 7.8ZM10.2 12C10.2 11.0059 11.0059 10.2 12 10.2C12.9941 10.2 13.8 11.0059 13.8 12C13.8 12.9941 12.9941 13.8 12 13.8C11.0059 13.8 10.2 12.9941 10.2 12Z"
      fill="currentColor"
    />
  </svg>
</template>

<script>
export default {
  name: "EyeIcon",
  props: {
    classes: {
      type: String,
      required: false,
      default: "",
    },
  },
};
</script>
