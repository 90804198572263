
import type { PropType } from "vue";
import Vue from "vue";
import { mapGetters } from "vuex";
import { BottomNavbarLink } from "~/components/layout/navbar/NavbarBottomLink";
import BottomNavbarConstants from "~/constants/bottomNavbar";
import TradeBrandsRow from "~/components/layout/header/components/TradeBrandsRow.vue";
import { XMarkIcon } from "~/components/UI/icons";

const MENU_DELAY = 150;

export default Vue.extend({
  name: "TradeBrands",
  components: {
    TradeBrandsRow,
    XMarkIcon,
  },
  props: {
    showClose: {
      type: Boolean,
      default: true,
    },
    viewAllLink: {
      type: Object as PropType<BottomNavbarLink>,
      required: true,
    },
    show: {
      type: Boolean,
      required: true,
    },
  },
  data: () => ({
    showBrands: false,
    timeout: null as ReturnType<typeof setTimeout> | null,
  }),
  computed: {
    ...mapGetters("locale", ["getNewTradeBrands", "getTopTradeBrands"]),
    links(): { [key: string]: BottomNavbarLink } {
      return BottomNavbarConstants;
    },
  },
  watch: {
    show(val) {
      if (val) {
        this.timeout = setTimeout(() => {
          this.showBrands = true;
          this.$emit("showOverlay", true);
        }, MENU_DELAY);
      } else {
        clearTimeout((this as any).timeout);
        this.showBrands = false;
        this.$emit("showOverlay", false);
      }
    },
  },
  methods: {
    brandSelected() {
      this.$emit("close");
    },
    closeTradeBrands() {
      this.$emit("close");
    },
  },
});
