<template functional>
  <svg viewBox="0 0 24 24" fill="none" :class="props.classes">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M3 7.65601C3 6.74965 3.78911 6 4.74316 6H19.2568C20.2107 6 21 6.74965 21 7.65601V16.4565C21 17.3627 20.2109 18.1125 19.2568 18.1125H4.74316C3.78911 18.1125 3 17.3628 3 16.4565V7.65601ZM19.2568 7.42501H4.74313C4.59918 7.42501 4.49996 7.51927 4.49996 7.65602V8.85001H19.5V7.65602C19.5 7.51927 19.3994 7.42501 19.2568 7.42501ZM4.49996 10.9875H19.5V16.4565C19.5 16.592 19.4007 16.6875 19.2568 16.6875H4.74313C4.59918 16.6875 4.49996 16.5932 4.49996 16.4565V10.9875ZM6.00004 13.125V12.4125H11.25V13.125H6.00004Z"
      fill="currentColor"
    />
  </svg>
</template>

<script>
export default {
  name: "CardOutlineIcon",
  props: {
    classes: {
      type: String,
      required: false,
      default: "",
    },
  },
};
</script>
