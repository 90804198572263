
import Vue, { PropType } from "vue";
import type { StepStatus } from ".";
import { CheckIcon } from "~/components/UI/icons";

export default Vue.extend({
  components: { CheckIcon },
  props: {
    stepText: {
      type: String as PropType<string>,
      default: () => "",
    },
    stepNumber: {
      type: Number as PropType<number>,
      default: () => null,
    },
    state: {
      type: String as PropType<StepStatus>,
      default: () => "uncompleted",
    },
  },
});
