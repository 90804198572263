<template functional>
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    :class="props.classes"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M5.10496 12.414C5.03467 12.865 5.04381 13.3242 5.13199 13.7722C5.31294 14.7699 5.76945 15.5376 6.18952 15.5985C7.23648 18.3759 9.47962 21 12.077 21C14.6745 21 16.9217 18.369 17.9687 15.59C18.3723 15.4727 18.7971 14.7267 18.9675 13.7745C19.0559 13.3267 19.0651 12.8676 18.9945 12.4169L5.10496 12.414ZM19.1272 10.1538H18.8564C18.8588 7.66034 17.4165 5.37681 15.1245 4.24487L14.5605 7.35706C14.5333 7.53968 14.4057 7.69347 14.2275 7.75833C14.0493 7.82319 13.8488 7.78884 13.7043 7.66869C13.5599 7.54854 13.4944 7.36167 13.5335 7.18109L14.1768 3.62642C13.9287 3.23682 13.49 2.99973 13.0176 3H11.0865C10.6141 3 10.1758 3.2369 9.92729 3.62642L10.5736 7.18109C10.606 7.35901 10.538 7.54019 10.3953 7.65637C10.2525 7.77255 10.0567 7.80609 9.88149 7.74436C9.70631 7.68262 9.57842 7.53498 9.54599 7.35706L8.98256 4.24601C6.69009 5.37696 5.24761 7.66045 5.25064 10.1538H4.97862C4.43435 10.1665 4 10.5979 4 11.1256C4 11.6533 4.43435 12.0846 4.97862 12.0974H19.1266C19.6709 12.0846 20.1053 11.6533 20.1053 11.1256C20.1053 10.5979 19.6709 10.1665 19.1266 10.1538H19.1272Z"
      fill="currentColor"
    />
  </svg>
</template>

<script>
export default {
  name: "ConstructionIcon",
  props: {
    classes: {
      type: String,
      required: false,
      default: "",
    },
  },
};
</script>
