
import Vue from "vue";
import {
  AddressBookIcon,
  CatalogueIcon,
  FormIcon,
  UserIcon,
} from "~/components/UI/icons";

export default Vue.extend({
  name: "AccountTopMenu",
  components: {
    AddressBookIcon,
    CatalogueIcon,
    FormIcon,
    UserIcon,
  },
  data() {
    const menuItems = [
      {
        title: "account.details.pageTitleManage",
        path: "/account/details/manage",
        icon: "user-icon",
      },
      {
        title: "account.addressBook.pageTitle",
        path: "/account/details/address-book",
        icon: "address-book-icon",
      },
      {
        title: "account.accountPreferences.pageTitle",
        path: "/account/details/preferences",
        icon: "form-icon",
      },
      {
        title: "account.requestACatalogue.pageTitle",
        path: "/account/details/catrequest",
        icon: "catalogue-icon",
      },
    ];
    return { menuItems };
  },
});
