<template>
  <div class="h-full flex flex-col relative rounded-xl">
    <div
      class="flex items-center justify-center relative"
      :class="heightClasses"
    >
      <div
        v-if="backgroundColour"
        :style="backgroundColourClass"
        class="h-full w-full rounded-xl object-cover"
      />
      <img
        v-else
        class="h-full w-full rounded-xl object-cover"
        :src="imageUrl"
        :alt="title"
        loading="lazy"
      />
    </div>
    <p
      v-if="title"
      class="font-bold text-size-6 mt-3 text-left"
      :style="titleTextColour !== '' ? titleTextColour : 'color: #065CAB'"
    >
      {{ title }}
    </p>
  </div>
</template>

<script setup>
import { computed } from "vue";

const props = defineProps({
  heightClasses: {
    type: String,
    required: true,
  },
  imageUrl: {
    type: String,
    required: true,
  },
  backgroundColour: {
    type: String,
    required: false,
    default: null,
  },
  title: {
    type: String,
    required: true,
  },
  titleTextColour: {
    type: String,
    required: false,
    default: "color: #065CAB",
  },
});

const backgroundColourClass = computed(
  () => `background-color:${props.backgroundColour}`
);
</script>
