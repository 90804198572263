
import Vue from "vue";
import { Portal } from "portal-vue";
import { FocusTrap } from "focus-trap";
import * as focusTrap from "focus-trap";
import { TrackingEvents } from "~/store/tracking";
import { XMarkIcon } from "~/components/UI/icons";

export default Vue.extend({
  name: "BaseModal",
  components: {
    Portal,
    XMarkIcon,
  },
  props: {
    wrapperClass: {
      type: String,
      default: "",
    },
    modalStyle: {
      type: Object,
      default: null,
    },
    modalClass: {
      type: String,
      default: "modal-default",
    },
    modalTitleClass: {
      type: String,
      default: "w-10/12 w-full",
    },
    withDefaultHeaderBackground: {
      type: Boolean,
      default: true,
    },
    modalSize: {
      type: String,
      default: "",
      validator(value) {
        return ["", "sm"].includes(String(value));
      },
    },
    closeButtonClass: {
      type: String,
      default: "btn-close-default",
    },
    modalContentClass: {
      type: String,
      default: "modal-content",
    },
    closeButtonSize: {
      type: String,
      default: "xl",
      validator(value) {
        return ["xxs", "xs", "sm", "md", "lg", "xl", "xxl"].includes(
          String(value)
        );
      },
    },
    modalTitle: {
      type: String,
      default: "",
    },
    showing: {
      type: Boolean,
      default: false,
      required: true,
    },
    hideCloseButton: {
      type: Boolean,
      default: false,
    },
    portalName: {
      type: String,
      default: "modal-layer-1",
    },
    disablePortal: {
      type: Boolean,
      default: false,
    },
    overflowYaxisAuto: {
      type: Boolean,
      required: false,
      default: true,
    },
  },
  data() {
    return {
      focusTrap: null as FocusTrap | null,
    };
  },
  computed: {
    modalSizeClass() {
      return this.modalSize ? `modal-size-${this.modalSize}` : "";
    },
    wrapperClasses() {
      return [
        "fixed inset-0 z-30 w-full h-screen flex items-start justify-center py-4 px-2 md:p-8",
        this.wrapperClass,
      ];
    },
    wrapperTag() {
      return !this.disablePortal ? Portal : "div";
    },
  },
  watch: {
    async showing(val) {
      if (process.client) {
        this.toggleBodyScroll(val, document.body);
      }

      if (val) {
        await this.$nextTick();
        this.focusTrap = focusTrap.createFocusTrap("#modal", {
          clickOutsideDeactivates: true,
        });

        this.focusTrap?.activate();
      } else {
        this.focusTrap?.deactivate();
      }
    },
    $route() {
      if (!this.showing) return;
      this.close();
    },
  },
  mounted() {
    if (this.showing) this.toggleBodyScroll(this.showing, document.body);
  },
  beforeDestroy() {
    this.toggleBodyScroll(false, document.body);
  },
  methods: {
    close() {
      this.$emit("close");
      this.$store.commit(
        "tracking/addTrackingEvent",
        TrackingEvents.ModalClose
      );
    },
    toggleBodyScroll(val: boolean, body: HTMLElement) {
      if (val) body.classList.add("overflow-hidden");
      else body.classList.remove("overflow-hidden");
    },
  },
});
