var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return (_vm.data[0] && (_vm.data[0].imageUrl || _vm.data[0].backgroundColour))?_c('div',{staticClass:"flex flex-col items-center"},[(_vm.mainTitle)?_c('h3',{staticClass:"text-blue font-bold text-size-7 self-start mb-2"},[_vm._v("\n    "+_vm._s(_vm.mainTitle)+"\n  ")]):_vm._e(),_vm._v(" "),_c('div',{staticClass:"w-full gap-4",class:[_setup.widthClasses, { grid: !_vm.isCarousel }]},[(_vm.isCarousel)?_c(_setup.BaseCarousel,{attrs:{"settings":_setup.carouselSetting},scopedSlots:_vm._u([{key:"slides",fn:function(){return _vm._l((_setup.computedData),function(item,index){return _c(_setup.getElementType(item.link.externalLink),_vm._b({key:index,tag:"Component"},'Component',_setup.getHref(item.link.externalLink),false),[_c(_setup.getVariant(item),{tag:"Component",attrs:{"height-classes":_setup.heightClasses,"image-url":item.imageUrl,"background-colour":item.backgroundColour,"title":item.title,"cta":{
              path: item.link.externalLink,
              linkText: item.link.linkText,
              colour: _vm.ctaBtnColour,
            },"title-text-colour":_vm.titleTextColour,"no-image-padding":_vm.noImagePadding}})],1)})},proxy:true}],null,false,2401735653)}):_vm._l((_setup.computedData),function(item,index){return _c(_setup.getElementType(item.link.externalLink),_vm._b({directives:[{name:"show",rawName:"v-show",value:(item.show),expression:"item.show"}],key:index,tag:"Component",attrs:{"data-testid":"item-wrapper"}},'Component',_setup.getHref(item.link.externalLink),false),[_c(_setup.getVariant(item),{tag:"Component",attrs:{"height-classes":_setup.heightClasses,"image-url":item.imageUrl,"background-colour":item.backgroundColour,"title":item.title,"image-classes":_vm.imageClasses,"cta":{
            path: item.link.externalLink,
            linkText: item.link.linkText,
            colour: _vm.ctaBtnColour,
          },"title-text-colour":_vm.titleTextColour,"no-image-padding":_vm.noImagePadding}})],1)})],2),_vm._v(" "),(
      _vm.showViewMoreButton &&
      !_vm.isCarousel &&
      _setup.splitResults() &&
      !(
        !_setup.isExpanded &&
        _vm.data.length === _setup.computedData.filter((item) => item.show).length
      )
    )?_c(_setup.DefaultButton,{ref:"button",staticClass:"md:hidden mt-6 !px-24 !py-2 !text-size-4",on:{"clickAction":function($event){return _setup.toggleExpand()}}},[_vm._v("\n    "+_vm._s(_setup.computedButtonText)+"\n  ")]):_vm._e(),_vm._v(" "),(_vm.mainButton && _vm.mainButton.href)?_c(_setup.DefaultButton,{staticClass:"w-[345px] mt-4 !p-3",attrs:{"appearance":"pill","size":"lg","variant":"primary","path":_vm.mainButton && _vm.mainButton.href}},[_vm._v("\n    "+_vm._s(_vm.mainButton.text)+"\n  ")]):_vm._e()],1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }