<template functional>
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    :class="props.classes"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M3 12C3 7.0401 7.0401 3 12 3C16.9599 3 21 7.0401 21 12C21 16.9599 16.9599 21 12 21C7.0401 21 3 16.9599 3 12ZM19.2 12C19.2 8.01289 15.9871 4.8 12 4.8C8.01289 4.8 4.8 8.01289 4.8 12C4.8 15.9871 8.01289 19.2 12 19.2C15.9871 19.2 19.2 15.9871 19.2 12ZM11.1 7.5V9.3H12.9V7.5H11.1ZM11.1 16.5V11.1H12.9V16.5H11.1Z"
      fill="currentColor"
    />
  </svg>
</template>

<script>
export default {
  name: "InformationIcon",
  props: {
    classes: {
      type: String,
      required: false,
      default: "",
    },
  },
};
</script>
