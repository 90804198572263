import { NuxtAxiosInstance } from "@nuxtjs/axios";
import { Department } from "~/components/layout/header/components/Departments";

const ENDPOINTS = {
  TAXONOMIES: `/taxonomies`,
  TAXONOMIES_HIERARCHY: `/taxonomies?summary=true`,
  TAXONOMIES_TOP_LEVEL: `/taxonomies/top-level`,
  TAXONOMY_BY_ID: (id: number) => `/taxonomies/${id}`,
};

export const getTaxonomies = async (
  $axios: NuxtAxiosInstance
): Promise<AxiosResponse<any>> => await $axios.$get(ENDPOINTS.TAXONOMIES);

export const getTaxonomiesTopLevel = async (
  $axios: NuxtAxiosInstance
): Promise<Department[]> =>
  await $axios
    .get(ENDPOINTS.TAXONOMIES_TOP_LEVEL)
    .then((response) => response.data ?? []);

export const getTaxonomyById = async (
  $axios: NuxtAxiosInstance,
  id: number
): Promise<AxiosResponse<any>> =>
  await $axios.$get(ENDPOINTS.TAXONOMY_BY_ID(id));

export const getTaxonomyHierarchy = async (
  $axios: NuxtAxiosInstance
): Promise<Department[]> =>
  await $axios
    .$get(ENDPOINTS.TAXONOMIES_HIERARCHY)
    .then((response) => response.data ?? []);
