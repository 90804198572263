import { camelCase } from "camel-case";
import type { Address } from "paypal-checkout-components/modules/callback-data";
import { Product } from "~/types/product";

export const convertToPascalCase = (value: string): string => {
  if (value === "") return "";
  const camelCased = camelCase(value);
  return camelCased.charAt(0).toUpperCase() + camelCased.slice(1);
};

export const generateUrl = (url: string): string => {
  if (url.includes("https")) {
    const index = url.indexOf("https");
    return url.substring(index);
  }
  return url;
};

export const removeStyleTagFromHtml = (innerHtml: string): string =>
  innerHtml.replace(
    /<style>(\w|\d|\n|[().,\-:;@#$%^&*[\]"'+-//®°⁰!?{}|~]| )*<.style>/g,
    ""
  );

export const prefixCategory = (id: number | string): string => "c" + id;
export const prefixProductCode = (id: number | string): string => `p${id}`;
export const brandUrl = (brand: string): string =>
  `/brands/${brand.replace(/ /g, "-").toLowerCase()}`;
export const fullProductPath = (product: Product): string =>
  `/${product.slug}/${prefixProductCode(product.code)}`;
export const isProductSlugMismatch = (path: string, product: Product) =>
  path !== fullProductPath(product);

export const isVersionOne = (version: string): boolean =>
  version === "version 1";

export const isVersionTwo = (version: string): boolean =>
  version === "version 2";

export const notZero = (value: string | number) => Number(value) !== 0;

export const never = () => {};

export const isValidEmail = (email: string) => {
  const emailRegex =
    /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/i;

  return emailRegex.test(email);
};

export const convertOpeningHoursToColonFormat = (data: string[]) => {
  return data.map((el) => {
    const [days, hours] = el.split(":");
    const modifiedHours = hours.replace(/(.{3})(.{5})/, "$1:$2:");
    return { days, hours: modifiedHours };
  });
};

export const isOdd = (num: number): boolean => {
  return num % 2 !== 0;
};

export const objectIsEmpty = (object: object): boolean => {
  return Object.keys(object).length === 0;
};

export const decodeHtmlEntity = (inputString: string) => {
  const entityPattern = /&#(\d+);/g;
  const resultString = inputString.replace(entityPattern, (_, entityCode) =>
    String.fromCharCode(parseInt(entityCode, 10))
  );
  return resultString;
};

export const apiPoll = async (
  fn: Function,
  condition: Function = () => Boolean,
  maxTries: number,
  gap: number,
  errorMessage: string
) => {
  const timeout = (n: number) =>
    new Promise((resolve) => setTimeout(resolve, n));

  while (maxTries--) {
    await timeout(gap);
    const result = await fn();
    if (condition(result)) return result;
  }

  throw new Error(errorMessage);
};

export const generateBranchAddress = (
  branchAddress: string[],
  countryCode: string
) => {
  const address = branchAddress.splice(0, branchAddress.length - 1);
  let recipientName,
    line1,
    line2,
    line3,
    city,
    state,
    postalCode,
    streetAddress;

  switch (address.length) {
    case 7:
      [recipientName, line1, line2, line3, city, state, postalCode] = address;
      streetAddress = `${line1} ${line2} ${line3}`;
      break;
    case 6:
      [recipientName, line1, line2, city, state, postalCode] = address;
      streetAddress = `${line1} ${line2}`;
      break;
    case 5:
      [recipientName, line1, city, state, postalCode] = address;
      streetAddress = line1;
      break;
    case 4:
      [recipientName, line1, city, postalCode] = address;
      streetAddress = line1;
      state = city;
      break;
  }

  return {
    recipientName,
    line1: streetAddress,
    city,
    postalCode,
    countryCode,
    state,
  } as Address;
};

export * from "./image";
export * from "./social";
export * from "./url";
