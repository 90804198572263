<template functional>
  <svg
    height="100%"
    width="100%"
    aria-hidden="true"
    focusable="false"
    role="img"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 448 512"
    :class="`${props.classes} ${props.size}`"
  >
    <path
      fill="currentColor"
      d="M256 80c0-17.7-14.3-32-32-32s-32 14.3-32 32V224H48c-17.7 0-32 14.3-32 32s14.3 32 32 32H192V432c0 17.7 14.3 32 32 32s32-14.3 32-32V288H400c17.7 0 32-14.3 32-32s-14.3-32-32-32H256V80z"
    ></path>
  </svg>
</template>

<script>
export default {
  name: "PlusIcon",
  props: {
    classes: {
      type: String,
      required: false,
      default: "",
    },
    size: {
      type: String,
      default: "xl",
      validator(value) {
        return ["xxs", "xs", "sm", "md", "lg", "xl", "xxl"].includes(
          String(value)
        );
      },
    },
  },
};
</script>

<style scoped lang="postcss">
.xxs {
  @apply text-size-0;
}
</style>
