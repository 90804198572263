import type { Context } from "@nuxt/types";
import { isEmpty, isNil } from "ramda";
import { ProductFilter } from "~/services/search.d";
import { serverSideFilters } from "~/utils/filters/index";

/**
 * @description Checks for suspicious XSS injections
 */
export const checkQueryParams = (query: Context["query"]): boolean => {
  for (const param of Object.values(query).flat()) {
    if (
      param?.match(
        /(\b)(on\S+)(\s*)=|javascript|<(|\/|[^/>][^>]+|\/[^>][^>]+)>/gi
      )
    ) {
      return false;
    }
  }
  return true;
};

/**
 * @description Returns query filters in object entries style from the route, after filtering out any empty values and the q param and save for later
 */
export const queryFilterEntriesFromRoute = (
  query: Context["query"]
): Array<[string, string]> => {
  return Object.entries(query)
    .filter(
      ([key, value]) =>
        !isEmpty(value) &&
        key !== "q" &&
        key !== "save-for-later" &&
        key !== "page"
    )
    .map(([key, value]) => [key, value as string]);
};

/**
 * @description Returns query filters from the URL and commits to Vuex
 */
export const getQueryActiveFilters = (context: Context): ProductFilter[] => {
  if (!context || !context.store) {
    return [];
  }

  const queryFiltersEntries = queryFilterEntriesFromRoute(context.route.query);
  let activeFilters: ProductFilter[] = [];

  if (!isEmpty(queryFiltersEntries) || !isNil(queryFiltersEntries)) {
    activeFilters = queryFiltersEntries.reduce(
      (filters: ProductFilter[], filter: [string, string]) => {
        const [name, value] = filter;
        return !isNil(name) && !isNil(value)
          ? [
              ...filters,
              {
                name,
                value,
              },
            ]
          : filters;
      },
      []
    );
  }

  context.store.commit("search/updateActiveFilters", activeFilters);
  return activeFilters;
};

/**
 * @description Updates the URL with the active serverside filter query params
 */
export const updateQueryParams = (context: Context): void => {
  // Keep exisitng params such as the search query
  const paramsToRetain = ["q"];

  const retainedParams = Object.keys(context.route.query)
    .filter((paramKey) => paramsToRetain.includes(paramKey))
    .reduce((result, paramKey) => {
      return Object.assign(result, {
        [paramKey]: context.route.query[paramKey],
      });
    }, {});

  const activeFilters: ProductFilter[] =
    context.store.getters["search/getActiveFilters"];

  const updatedFilterParams = activeFilters
    .filter((filter) => serverSideFilters().includes(filter.name)) // return only the filters in the 'serverSideFilters' array
    .sort((a, b) => {
      return (
        serverSideFilters().indexOf(a.name) -
        serverSideFilters().indexOf(b.name)
      );
    }) // Sort by 'serverSideFilters' array to preserve param order
    .map((filter) => ({ [filter.name]: filter.value }))
    .reduce((result, currentObject) => {
      Object.keys(currentObject).forEach((key) => {
        if (!(key in result)) {
          result[key] = currentObject[key];
        }
      });
      return result;
    }, {}); // Reduce to a single object, prioritising the first selected attribute if multiple are selected from a single filter

  context.app?.router?.push({
    path: context.route.path,
    query: { ...retainedParams, ...updatedFilterParams },
  });
};
