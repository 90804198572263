import { NuxtAxiosInstance } from "@nuxtjs/axios";
import {
  ApiProduct,
  ApiProductResponse,
  FetchProductsPayload,
} from "~/services/api/products.api.d";
const ENDPOINTS = {
  PRODUCTS_BY_CODE: (productCode: string) => `/products/${productCode}`,
  PRODUCTS_BY_CODE_DELIVERY: (productCode: string) =>
    `/products/${productCode}/delivery`,
  PRODUCTS: `/products/getProducts`,
};

export const getProductByCode = async (
  $axios: NuxtAxiosInstance,
  productCode: string
): Promise<ApiProductResponse> =>
  await $axios.$get(ENDPOINTS.PRODUCTS_BY_CODE(productCode));

export const getProducts = async (
  $axios: NuxtAxiosInstance,
  data: FetchProductsPayload
): Promise<ApiProduct[]> =>
  await $axios
    .$post(ENDPOINTS.PRODUCTS, data)
    .then((response) => response.data);

export const getProductDelivery = async (
  $axios: NuxtAxiosInstance,
  productCode: string
): Promise<AxiosResponse<any>> =>
  await $axios.$get(ENDPOINTS.PRODUCTS_BY_CODE_DELIVERY(productCode));
