<template functional>
  <svg
    aria-hidden="true"
    focusable="false"
    role="img"
    xmlns="http://www.w3.org/2000/svg"
    width="32"
    height="32"
    viewBox="0 0 256 256"
    :class="props.classes"
  >
    <path
      fill="currentColor"
      d="M248 128a15.76 15.76 0 0 1-7.33 13.34l-88.19 56.16A15.91 15.91 0 0 1 128 184.16v-37.3L48.48 197.5A15.91 15.91 0 0 1 24 184.16V71.84A15.91 15.91 0 0 1 48.48 58.5L128 109.14v-37.3a15.91 15.91 0 0 1 24.48-13.34l88.19 56.16A15.76 15.76 0 0 1 248 128"
    />
  </svg>
</template>

<script>
export default {
  name: "ForwardIcon",
  props: {
    classes: {
      type: String,
      required: false,
      default: "",
    },
  },
};
</script>
