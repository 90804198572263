
import Vue, { PropType } from "vue";

export default Vue.extend({
  props: {
    id: { type: String as PropType<string>, default: undefined },
    value: { type: Boolean as PropType<boolean>, default: false },
    labelClass: {
      type: String as PropType<string>,
      default: "label-default",
    },
    checkboxClass: {
      type: String as PropType<string>,
      default: "checkbox-default",
    },
    required: {
      type: Boolean,
      default: false,
      required: false,
    },
    isError: {
      type: Boolean,
      default: false,
      required: false,
    },
    checked: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    checkBoxValue: {
      get(): boolean {
        return this.value;
      },
      set(newValue: boolean) {
        this.$emit("input", newValue);
        this.$emit("click", newValue);
        this.$emit("dirty", true);
      },
    },
    classes() {
      return [this.checkboxClass, { "is-invalid": this.isError }];
    },
  },
  methods: {
    handleClick(event: Event) {
      this.checkBoxValue = (event.target as HTMLInputElement).checked;
    },
  },
});
