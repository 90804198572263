<template>
  <label
    :class="[
      classlist,
      { 'flex-col': stacked, 'flex-row': !stacked, 'items-center': !stacked },
    ]"
    class="flex w-full cursor-pointer form-label"
  >
    <slot />

    <p
      v-show="validationError"
      class="mt-2 font-semibold text-red-error text-size-3"
      v-text="validationMessage || $t('validation.generic')"
    />
  </label>
</template>

<script>
import Vue from "vue";

export default Vue.extend({
  props: {
    classlist: { type: String, default: "" },
    stacked: { type: Boolean, default: true },
    validationError: {
      type: Boolean,
      default: false,
    },
    validationMessage: {
      type: String,
      default: "",
    },
  },
});
</script>
