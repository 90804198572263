import { equals, pipe, prop } from "ramda";
import type { GetterTree, MutationTree } from "vuex/types";
import { RootState } from "./index";

export enum Device {
  Desktop,
  Tablet,
  Mobile,
  Unknown,
}

type State = {
  initialUpdate: Boolean;
  device: Device;
  deviceWidth: Number;
};

export const state = (): State => ({
  initialUpdate: false,
  device: Device.Mobile, // Mobile first
  deviceWidth: 0,
});

export const mutations: MutationTree<State> = {
  setDevice: (state: State, device: Device) => {
    if (!state.initialUpdate) state.initialUpdate = true;
    state.device = device;
  },
  setDeviceWidth: (state: State, deviceWidth: Number) => {
    if (!state.initialUpdate) state.initialUpdate = true;
    state.deviceWidth = deviceWidth;
  },
};

export const getters: GetterTree<State, RootState> = {
  getDevice: prop("device"),
  getDeviceWidth: (state: State) => state.deviceWidth,
  getDeviceType(state: State) {
    switch (state.device) {
      case Device.Mobile:
        return "mobile";
      case Device.Tablet:
        return "tablet";
      case Device.Desktop:
        return "desktop";
      default:
        return "mobile";
    }
  },
  isDesktop: pipe(prop("device"), equals(Device.Desktop)),
  isTablet: pipe(prop("device"), equals(Device.Tablet)),
  isMobile: pipe(prop("device"), equals(Device.Mobile)),
  isTabletOrMobile: (_state: State, getters) =>
    getters.isTablet || getters.isMobile,
  initialUpdate: pipe(prop("initialUpdate")),
};

export default {
  state,
  mutations,
  getters,
};
