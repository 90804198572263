
import Vue from "vue";
import { XMarkIcon } from "~/components/UI/icons";

export default Vue.extend({
  name: "SiteMessage",
  components: {
    XMarkIcon,
  },
  props: {
    messageId: {
      type: String,
      required: true,
    },
    styleType: {
      type: String,
      required: false,
      default: "info",
    },
    title: {
      type: String,
      required: true,
    },
    body: {
      type: String,
      required: true,
    },
    persistent: {
      type: Boolean,
      required: true,
    },
  },
  computed: {
    wrapperClasses() {
      switch (this.styleType) {
        case "info":
          return "bg-blue-50 border-blue-100 border-l-blue";
        case "warning":
          return "bg-red-100 border-red-200 border-l-red";
        default:
          return "bg-blue-50 border-blue-100 border-l-blue";
      }
    },
    titleClasses() {
      switch (this.styleType) {
        case "info":
          return "text-blue";
        case "warning":
          return "text-red";
        default:
          return "text-blue";
      }
    },
    bodyClasses() {
      switch (this.styleType) {
        case "info":
          return "text-blue-500";
        case "warning":
          return "text-[#ba5252]";
        default:
          return "text-blue-500";
      }
    },
  },
  methods: {
    dismissMessage(messageId: string, styleType: string) {
      this.$emit("removeMessage", messageId, styleType);
    },
  },
});
