<template functional>
  <Fragment>
    <svg
      v-if="props.direction === 'neutral'"
      width="24"
      height="24"
      fill="none"
      :class="props.classes"
    >
      <path d="M9 8L14.7391 0L21 8H9Z" fill="#065CAB" />
      <path d="M0 0L5.73913 8L12 0H0Z" fill="#065CAB" />
    </svg>

    <svg
      v-if="props.direction === 'desc'"
      width="21"
      height="8"
      :class="props.classes"
    >
      <path d="M9 8L14.7391 0L21 8H9Z" fill="#C4C4C4" />
      <path d="M0 0L5.73913 8L12 0H0Z" fill="#065CAB" />
    </svg>

    <svg
      v-if="props.direction === 'asc'"
      width="21"
      height="8"
      :class="props.classes"
    >
      <path d="M9 8L14.7391 0L21 8H9Z" fill="#065CAB" />
      <path d="M0 0L5.73913 8L12 0H0Z" fill="#C4C4C4" />
    </svg>
  </Fragment>
</template>

<script>
export default {
  name: "VideoIcon",
  props: {
    direction: {
      type: String,
      required: false,
      default: "neutral",
      validator(val) {
        return ["neutral", "asc", "desc"].includes(val);
      },
    },

    classes: {
      type: String,
      required: false,
      default: "",
    },
  },
};
</script>
