import Root from "postcss/lib/root";
import { ActionTree, GetterTree, MutationTree } from "vuex";
import { RootState } from ".";
import type { BreadCrumbsItems } from "~/components/UI/breadcrumbs/BreadCrumbsItems";

export enum BreadCrumbsMutation {
  SetItems = "setItems",
}

interface BreadCrumbsState {
  items: BreadCrumbsItems[] | null;
}

export const state = (): BreadCrumbsState => ({
  items: null,
});

export const mutations: MutationTree<BreadCrumbsState> = {
  [BreadCrumbsMutation.SetItems]: (
    state: BreadCrumbsState,
    items: BreadCrumbsItems[]
  ) => {
    state.items = items;
  },
};

export const getters: GetterTree<BreadCrumbsState, RootState> = {
  getBreadcrumbsItems: (state: BreadCrumbsState): BreadCrumbsItems[] | null =>
    state.items,
  getBreadcrumbsParent: (state: BreadCrumbsState): BreadCrumbsItems | string =>
    state.items && state.items?.length > 1
      ? state.items[state.items.length - 2]?.title
      : "",
};

export const actions: ActionTree<BreadCrumbsState, Root> = {
  setBreadcrumbs({ commit }, breadcrumbs: BreadCrumbsItems[]) {
    commit(BreadCrumbsMutation.SetItems, breadcrumbs);
  },
};

export default {
  state,
  getters,
  mutations,
  actions,
};
