import { curry, filter, find, isNil, pluck, propEq } from "ramda";
import { Product, ProductAttribute, ProductImageSizes } from "~/types/product";
import { ProductAttribute as ProductAttributeEnum } from "~/constants/attributes/productAttributes";
import { BreadCrumbsItems } from "~/components/UI/breadcrumbs/BreadCrumbsItems";
import { cdnConfig } from "~/services/config.service";

export const getAttributes = (product: Product): ProductAttribute[] => [
  ...(product.technicalSpecAttributes ?? []),
  ...(product.documentsAndManualsAttributes ?? []),
  ...(product.otherAttributes ?? []),
];

// @todo sort out types
export const pluckValuesFromAttributeId = (
  attribute: ProductAttributeEnum,
  attributes: { id: number; value: any }[]
): string[] =>
  pluck("value" as any, filter(propEq("id", attribute), attributes));
export const getValueFromAttributeId = (
  attribute: ProductAttributeEnum,
  attributes: { id: number; value: any }[]
): string | undefined => find(propEq("id", attribute), attributes)?.value;

const productCodeRegex = /\(code ([a-zA-Z0-9]{5})\)/g;

export const getProductCode = (code: string): string =>
  code.replace(productCodeRegex, "$1");

export const mentionedProductCodes = (description: string): string[] => {
  const matches = description.match(productCodeRegex);
  return !isNil(matches) && matches.length > 0
    ? [...new Set(matches.map(getProductCode))]
    : [];
};

export { getProductDetails } from "./description";

export const isProductEligibleForNextBusinessDayCollection = ({
  nextDayDelivery,
}: Product): boolean => nextDayDelivery;

/**
 * Is product online only
 * @param attributes
 */
export const isOnlineOnly = ({ channel }: Product): boolean => channel === 1;

/**
 * Is product trade counter only
 * @param attributes
 */
export const isTradeCounterOnly = ({ channel }: Product): boolean =>
  channel === 2;

/**
 * Is product dropship
 * @param product
 */
export const isDropship = ({ channel }: Product): boolean => channel === 3;

/**
 * Is product discontinued
 * @param attributes
 */
export const isDiscontinued = ({ channel }: Product): boolean => channel === 5;

export const getProductRating = (
  attributes: ProductAttribute[]
): ProductAttribute | null => {
  const attribute = attributes.find(
    (attr) => attr.id === ProductAttributeEnum.ReviewRating
  );

  return attribute ?? null;
};

export const getNumberNumberOfReviews = (
  attributes: ProductAttribute[]
): ProductAttribute | null => {
  const attribute = attributes.find(
    (attr) => attr.id === ProductAttributeEnum.NumberOfReviews
  );

  return attribute ?? null;
};

export const getQtyMinimum = (
  attributes: ProductAttribute[]
): ProductAttribute | null => {
  const attribute = attributes.find(
    (attr) => attr.id === ProductAttributeEnum.QuantityMinimum
  );

  return attribute ?? null;
};

export const getDropshipDeliveryTime = (
  attributes: ProductAttribute[]
): ProductAttribute | null => {
  const attribute = attributes.find(
    (attr) => attr.id === ProductAttributeEnum.DropshipDeliveryTime
  );

  return attribute ?? null;
};

export const getVariationTitle = (product: Product): string => {
  const formattedGrossPrice =
    product.prices !== null ? product.prices.formatted.gross : "";
  const packSizeOrName =
    product.packSize !== "" ? product.packSize : product.name;

  return `${packSizeOrName} - (${product.code}) - ${product.packQuantity} - ${formattedGrossPrice}`;
};

export const getTaxonomySlug = (slug: string): string =>
  slug
    .toLocaleLowerCase()
    .trim()
    .replace(/[$-/:-?{-~!"^_`[\]]/g, "")
    .replace(/\s+/g, "-");

export const getProductBreadcrumbs = (product: Product): BreadCrumbsItems[] => {
  if (
    product.taxonomies === null ||
    product.taxonomies.length === 0 ||
    product.taxonomies[0].length === 0
  )
    return [];
  const mainTaxonomies = product.taxonomies[0];
  const department = mainTaxonomies[0];
  const departmentSlug = getTaxonomySlug(department.name);

  return [
    ...mainTaxonomies.map((taxonomy) => ({
      title: taxonomy.name,
      to: `/${departmentSlug}/${
        department.id !== taxonomy.id
          ? getTaxonomySlug(taxonomy.name) + "/"
          : ""
      }c${taxonomy.id as unknown as string}`,
    })),
    {
      title: product.name,
    },
  ];
};

const toAwsCdnUri = curry(
  (cdn: string, cdnDir: string, imgSizes: ProductImageSizes[], img: string) =>
    imgSizes.map((size) => `${cdn}/images/${cdnDir}/${size}/${img}.jpg`)
);

export const generateAwsCdnUriForLocale = async (
  locale: string = "GB",
  imgSizes: ProductImageSizes[] = [ProductImageSizes.biggest]
): Promise<(img: string) => string> => {
  const { cdn, cdnDir } = await cdnConfig(locale);
  return toAwsCdnUri(cdn, cdnDir, imgSizes);
};

export const toYoutubeUri = (videoId: string) =>
  `https://www.youtube-nocookie.com/embed/${videoId}`;
export const toYouTubeThumbnailUri = (videoId: string) =>
  `https://img.youtube.com/vi/${videoId}/default.jpg`;

export * from "./description";
export * from "./rating";
export * from "./prices";
