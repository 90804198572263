<template functional>
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    :class="props.classes"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M12.2241 18.6784C12.2383 18.6723 12.2522 18.6655 12.2659 18.6582L13.7095 17.8883C15.2778 17.0518 17.1597 17.0518 18.728 17.8883C19.7571 18.4371 21 17.6914 21 16.5251V7.5875C21 6.96429 20.6565 6.39181 20.1066 6.09853L19.6599 5.8603C17.5092 4.71323 14.9283 4.71323 12.7776 5.8603L12 6.275L11.2224 5.8603C9.07168 4.71323 6.49081 4.71323 4.34007 5.8603L3.89338 6.09853C3.34349 6.39181 3 6.96429 3 7.5875V16.5251C3 17.6914 4.24288 18.4371 5.27198 17.8883C6.84028 17.0518 8.7222 17.0518 10.2905 17.8883L11.734 18.6582L12.2241 18.6784ZM12.5625 7.25V17.225L13.1801 16.8956C15.0792 15.8827 17.3583 15.8827 19.2574 16.8956C19.5372 17.0448 19.875 16.8421 19.875 16.5251V7.5875C19.875 7.37976 19.7605 7.18894 19.5772 7.09117L19.1305 6.85294C17.3107 5.88235 15.1268 5.88235 13.307 6.85294L12.5625 7.25ZM11.4375 7.25L10.693 6.85294C8.87318 5.88235 6.68934 5.88235 4.86949 6.85294L4.42279 7.09117C4.2395 7.18894 4.125 7.37976 4.125 7.5875V16.5251C4.125 16.8421 4.46284 17.0448 4.74257 16.8956C6.64174 15.8827 8.92076 15.8827 10.8199 16.8956L11.4375 17.225V7.25Z"
      fill="currentColor"
    />
  </svg>
</template>

<script>
export default {
  name: "CatalogueIcon",
  props: {
    classes: {
      type: String,
      required: false,
      default: "",
    },
  },
};
</script>
