import { MutationTree, GetterTree } from "vuex";
import { RootState } from "./index";

interface MainMenuState {
  mobileMenuOpen: boolean;
}

export const state = (): MainMenuState => ({
  mobileMenuOpen: false,
});

export const mutations: MutationTree<MainMenuState> = {
  TOGGLE_MOBILE_MENU_OPEN: (state) =>
    (state.mobileMenuOpen = !state.mobileMenuOpen),
  CLOSE_MOBILE_MENU: (state) => (state.mobileMenuOpen = false),
};

export const getters: GetterTree<MainMenuState, RootState> = {
  mobileMenuOpen: (state) => state.mobileMenuOpen,
};

export default {
  state,
  mutations,
  getters,
};
