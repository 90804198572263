// @todo this entire store needs refactoring
// There's a lot of gluing involved

import {
  clamp,
  defaultTo,
  equals,
  isEmpty,
  isNil,
  map,
  pipe,
  prop,
  sum,
  uniq,
} from "ramda";
import { ActionTree, GetterTree, MutationTree } from "vuex";
import { AppCookieNames } from "~/constants/ecomApi";
import thresholds from "~/constants/thresholds";
import { getDefaultDeliveryCode } from "~/lib/delivery";
import { indexProductsByProductCode } from "~/lib/products/transformers";
import {
  createCustomerTrolley,
  getCustomerActiveTrolleysByCustomerId,
} from "~/services/customers.service";
import { fetchProducts } from "~/services/products.service";

import type { Taxonomy } from "~/services/taxonomies.d";
import {
  addItemToTrolley,
  addPromoCodeToTrolley,
  checkTrolleyDiscount,
  convertGuestTrolleyToCustomerTrolley,
  createGuestTrolley,
  deletePromoCodeFromTrolley,
  deleteTrolleyLineById,
  fetchTrolleyOrdersTotal,
  getTrolleyById,
  setDeliveryToCollection,
  TrolleyChannel,
  trolleyDeliveryMethods,
  updateLineItem,
  updateLineItemChannel,
  updateLineItemDeliveryMethod,
  updateLineItemQuantityById,
} from "~/services/trolleys.service";
import { RootState } from "~/store/index";
import { OrderData } from "~/types/order";
import { Product } from "~/types/product";
import {
  DiscountData,
  LineItem,
  QualifyingPromotionsData,
  RawTrolleyData,
  TrolleyData,
  TrolleyPromotionIDs,
  TrolleyState,
  TrolleyTotals,
} from "~/types/trolley";

export const state = (): TrolleyState => ({
  trolleyId: null,
  trolley: null,
  addedToModalVisible: false,
  lastTrolleyUpdateProductCodes: [],
  lastTrolleyUpdateChannel: null,
  loading: false,
  trolleyUpdateError: false,
  deliveryAddressId: "",
  collectionAddressId: "",
  billingAddressId: "",
  deliveryMethods: [],
  deliveryMethod: {},
  deliveryDetails: "",
  poNumber: "",
  staffDiscountToken: null,
});

export const getters: GetterTree<TrolleyState, RootState> = {
  trolleyItemsCount: (state: TrolleyState): number | null => {
    const quantityTotal = sum(
      map(prop("quantity"), state.trolley?.lines ?? [])
    );
    return isNaN(quantityTotal) ? 0 : quantityTotal;
  },

  lastTrolleyUpdateProductCodes: (state: TrolleyState): string[] | [] =>
    state.lastTrolleyUpdateProductCodes ?? [],

  trolleyLines: (state: TrolleyState): LineItem[] => state.trolley?.lines ?? [],
  lastAddedProduct: (_state: TrolleyState, getters) =>
    getters.trolleyLines.slice(-1)[0],
  lastProductCategoryTree: (_state: TrolleyState, getters) =>
    getters.lastAddedProduct?.product?.taxonomies?.[0]
      ?.map((category: Taxonomy) => category.name)
      ?.join("/"),

  trolleyData: (state: TrolleyState): Partial<TrolleyData> | null =>
    state.trolley,

  hasProductInTrolley: (
    state: TrolleyState,
    code: string
  ): boolean | undefined => {
    if (state.trolley?.lines?.length === 0) {
      return false;
    }

    return state.trolley?.lines?.some(
      (line: LineItem) => String(line.product_code) === String(code)
    );
  },

  isAddedToTrolleyModalVisible: pipe(
    prop("addedToModalVisible"),
    defaultTo(false),
    equals(true)
  ),
  lastTrolleyUpdate(_state, getters): LineItem[] {
    return getters.trolleyLines.filter((line: LineItem) =>
      getters.lastTrolleyUpdateProductCodes.includes(line.product_code)
    );
  },
  lastTrolleyUpdateChannel: prop("lastTrolleyUpdateChannel"),
  trolleyId: prop("trolleyId"),
  loading: prop("loading"),
  trolleyUpdateError: prop("trolleyUpdateError"),

  getDeliveryAddressId: (state: TrolleyState) => state.deliveryAddressId,
  getCollectionAddressId: (state: TrolleyState) => state.collectionAddressId,
  getBillingAddressId: (state: TrolleyState) => state.billingAddressId,
  getDeliveryItems: (_state: TrolleyState, getters) =>
    getters.trolleyLines.filter(
      (line: LineItem) =>
        line.channel === TrolleyChannel.DELIVERY ||
        line.channel === TrolleyChannel.DIRECTSHIP
    ),
  getDeliveryOnlyItems: (_state: TrolleyState, getters) =>
    getters.trolleyLines.filter(
      (line: LineItem) => line.channel === TrolleyChannel.DELIVERY
    ),
  getDirectshipItems: (_state: TrolleyState, getters) =>
    getters.trolleyLines.filter(
      (line: LineItem) => line.channel === TrolleyChannel.DIRECTSHIP
    ),
  getDirectshipOnlyItems: (_state: TrolleyState, getters) =>
    getters.trolleyLines.filter(
      (line: LineItem) => line.channel === TrolleyChannel.DIRECTSHIP
    ),
  hasLineItems: (_state: TrolleyState, getters) =>
    (getters.trolleyLines ?? []).length > 0,
  hasDeliveryItems: (_state: TrolleyState, getters) =>
    getters.getDeliveryItems.length > 0,
  hasCollectionItems: (_state: TrolleyState, getters) =>
    getters.getCollectionItems.length > 0,
  hasDirectshipItems: (_state: TrolleyState, getters) =>
    getters.getDirectshipItems.length > 0,
  hasDirectshipOnlyItems: (_state: TrolleyState, getters) =>
    getters.getDirectshipOnlyItems.length > 0,
  hasCollectionOnlyItems: (_state: TrolleyState, getters) =>
    getters.getCollectionOnlyItems.length > 0,
  hasDeliveryOnlyItems: (_state: TrolleyState, getters) =>
    getters.getDeliveryOnlyItems.length > 0,
  hasNextBusinessDayItems: (_state: TrolleyState, getters) =>
    getters.getNextBusinessDayItems.length > 0,
  getCollectionItems: (_state: TrolleyState, getters) =>
    getters.trolleyLines.filter(
      (line: LineItem) =>
        line.channel === TrolleyChannel.COLLECTION ||
        line.channel === TrolleyChannel.NEXT_DAY_COLLECTION
    ),
  getCollectionOnlyItems: (_state: TrolleyState, getters) =>
    getters.trolleyLines.filter(
      (line: LineItem) => line.channel === TrolleyChannel.COLLECTION
    ),
  getDeliveryDetails: (state: TrolleyState) => state.deliveryDetails,
  getDeliveryMethods: (state: TrolleyState) => state.deliveryMethods,
  getDeliveryMethod: (state: TrolleyState) => state.deliveryMethod,
  getNextBusinessDayItems: (_state: TrolleyState, getters) =>
    getters.trolleyLines.filter(
      (line: LineItem) => line.channel === TrolleyChannel.NEXT_DAY_COLLECTION
    ),

  /**
   * Trolley totals
   */
  trolleyItemsSubtotal: (state: TrolleyState): string | null | undefined =>
    state.trolley?.items?.inc_vat?.formatted ?? null,

  trolleySubTotal: (state: TrolleyState): string | null | undefined =>
    state.trolley?.subtotals?.inc_vat?.formatted,

  trolleySubTotalRaw: (state: TrolleyState): number | null | undefined =>
    state.trolley?.subtotals?.inc_vat?.raw,

  trolleyTotalVat: (state: TrolleyState): string | null | undefined =>
    state.trolley?.totals?.vat?.formatted,

  trolleySubTotalVat: (state: TrolleyState): string | null | undefined =>
    state.trolley?.subtotals?.vat?.formatted,

  trolleyTotal: (state: TrolleyState): string | null | undefined =>
    state.trolley?.totals?.inc_vat?.formatted,

  trolleyTotalRaw: (state: TrolleyState): number | null | undefined =>
    state.trolley?.totals?.inc_vat?.raw,

  trolleyTotalExVat: (state: TrolleyState): string | null | undefined =>
    state.trolley?.totals?.ex_vat?.formatted,

  trolleyDiscount: (state: TrolleyState): string | null | undefined =>
    state.trolley?.trolley_discount?.discount_total,

  trolleyDiscountInPounds: (
    _s: TrolleyState,
    getters
  ): string | null | undefined => (getters.trolleyDiscount / 100).toFixed(2),

  trolleyDeliveryCost: (state: TrolleyState): string | null | undefined =>
    state.trolley?.delivery?.inc_vat?.formatted,

  isClubDiscount: (_state, getters) => {
    const promotions = getters.discountData?.qualifying_promotions ?? [];
    return promotions.find(
      (promotion: QualifyingPromotionsData) =>
        promotion.id === TrolleyPromotionIDs.TS_CLUB_PROMO
    );
  },

  discountData: (
    state: TrolleyState,
    getters
  ): (DiscountData & { token?: string }) | [] => {
    return state.trolley?.trolley_discount
      ? {
          ...state.trolley?.trolley_discount,

          // staff discount
          ...(getters.staffDiscountToken
            ? { token: getters.staffDiscountToken }
            : {}),
        }
      : [];
  },
  clubDiscountSavings: (_state, getters, _rootState, rootGetters) => {
    const DEFAULT_DISCOUNT_PERCENTAGE = 5;
    const userSpendStats = rootGetters["auth/getUserSpendStats"];
    const discount =
      userSpendStats?.spendRequirements?.standard?.discount ??
      DEFAULT_DISCOUNT_PERCENTAGE;
    const subTotal = getters.trolleySubTotalRaw;
    const clubSavings = (discount / 100) * subTotal;
    return (Math.round(clubSavings * 100) / 100).toFixed(2);
  },
  isSubTotalAboveLimit: (_state, getters) =>
    getters.trolleySubTotalRaw >= thresholds.trolley.TS_CLUB_SUBTOTAL_LIMIT,

  /**
   * Trolley to order data
   */
  getOrderData: (state: TrolleyState, getters): OrderData | null => {
    return {
      trolley_id: getters.trolleyId,
      order_total: String(getters.trolleyTotalRaw) ?? "0",
      billing_address_id: getters.getBillingAddressId,
      delivery_address_id:
        getters.getDeliveryAddressId || getters.getBillingAddressId,
      collection_address_id: getters.getCollectionAddressId,
      next_day_collection_address_id: getters.getCollectionAddressId,

      special_requests: getters.getDeliveryDetails,
      po_number: state.poNumber,

      promotions: getters.discountData,
    };
  },
  staffDiscountToken: prop("staffDiscountToken"),
};

export const mutations: MutationTree<TrolleyState> = {
  setTrolleyIdData: (state: TrolleyState, trolleyId: string): void => {
    state.trolleyId = trolleyId;
  },

  setTrolleyData(state: TrolleyState, payload: TrolleyData | null): void {
    if (state.trolley == null || payload == null) {
      state.trolley = payload;
    } else {
      state.trolley = { ...state.trolley, ...payload };
    }
  },

  setTrolleyDiscounts(
    state: TrolleyState,
    discount: DiscountData | null
  ): void {
    state.trolley = { ...state.trolley, trolley_discount: discount };
  },

  setAddedToTrolleyModalVisibility(state, visible) {
    state.addedToModalVisible = visible;
    state.loading = true;
    state.trolleyUpdateError = false;
  },
  setLastTrolleyUpdateProductCodes(state, productCode: string) {
    state.lastTrolleyUpdateProductCodes = uniq([
      ...(state.lastTrolleyUpdateProductCodes ?? []),
      productCode,
    ]);
  },
  clearLastTrolleyUpdateProductCodes(state) {
    state.lastTrolleyUpdateProductCodes = [];
  },
  setLastTrolleyUpdateChannel(state, channel) {
    state.lastTrolleyUpdateChannel = channel;
  },
  setLoading(state, loading: boolean) {
    state.loading = loading;
  },
  setTrolleyUpdateError(state, trolleyUpdateError: boolean) {
    state.trolleyUpdateError = trolleyUpdateError;
  },
  clearAddedToTrolleyModal(state) {
    if (state.loading) return false;

    state.addedToModalVisible = false;
    state.lastTrolleyUpdateProductCodes = [];
  },
  setDeliveryAddressId: (state: TrolleyState, addressId) => {
    state.deliveryAddressId = addressId;
  },
  setCollectionAddressId: (state: TrolleyState, addressId) => {
    state.collectionAddressId = addressId;
  },
  setBillingAddressId: (state: TrolleyState, addressId) => {
    state.billingAddressId = addressId;
  },
  clearAddressIds(state) {
    state.billingAddressId = "";
    state.deliveryAddressId = "";
    state.collectionAddressId = "";
  },
  setSelectedDeliveryMethods: (
    state: TrolleyState,
    { channel, method }: { channel: TrolleyChannel; method: string }
  ) => {
    state.deliveryMethod[channel] = method;
  },
  setDeliveryDetails: (state: TrolleyState, details) => {
    state.deliveryDetails = details;
  },
  setDeliveryMethods: (state: TrolleyState, methods) => {
    state.deliveryMethods = methods;
  },
  setStaffDiscountToken: (state: TrolleyState, token: string | null) => {
    state.staffDiscountToken = token;
  },
};

export const actions: ActionTree<TrolleyState, RootState> = {
  setAxiosHeaders(_, payload: string): void {
    this.$axios.setHeader("X-Toolstation-Session-ID", payload);
  },

  deleteAxiosSessionHeader(_): void {
    delete this.$axios.defaults.headers["X-Toolstation-Session-ID"];
    this.$cookies.remove(AppCookieNames.GuestCheckoutSession);
  },

  setTrolleyId({ commit }, trolleyId: string) {
    this.$cookies.set(AppCookieNames.Trolley, { trolleyId }, { secure: true });
    commit("setTrolleyIdData", trolleyId);
  },

  async createTrolley({
    commit,
    dispatch,
    rootGetters,
  }): Promise<RawTrolleyData | null> {
    let result = null;
    const isAuthenticated = rootGetters["auth/isAuthenticated"] as boolean;

    try {
      if (isAuthenticated) {
        const { id } = rootGetters["auth/getUser"];
        const { data } = await createCustomerTrolley(this.$axios, id);
        result = data;
      } else {
        result = await createGuestTrolley(this.$axios)
          .then((response) => response.data)
          .catch(() => null);

        await dispatch("setAxiosHeaders", result.session_token);
        commit("guest/setGuestCheckoutSession", result.session_token, {
          root: true,
        });
      }

      dispatch("setTrolleyId", result.id);
      commit("setTrolleyData", result);
    } catch (error) {
      console.error(error);
    }

    return result;
  },
  async addingTransaction({ commit }, callback: any) {
    commit("setLoading", true);
    commit("clearLastTrolleyUpdateProductCodes", null);
    return await callback().then(() => {
      commit("setLoading", false);
    });
  },
  async addItemToTrolleyForCollection({ dispatch }, [productCode, quantity]) {
    await dispatch("addItemToTrolley", {
      productCode,
      quantity,
      channel: TrolleyChannel.COLLECTION,
    });
  },
  async addItemToTrolleyForNextDayCollection(
    { dispatch },
    [productCode, quantity]
  ) {
    await dispatch("addItemToTrolley", {
      productCode,
      quantity,
      channel: TrolleyChannel.NEXT_DAY_COLLECTION,
    });
  },
  async addItemToTrolleyForDelivery({ dispatch }, [productCode, quantity]) {
    await dispatch("addItemToTrolley", {
      productCode,
      quantity,
      channel: TrolleyChannel.DELIVERY,
    });
  },
  async addItemToTrolleyForDropship({ dispatch }, [productCode, quantity]) {
    await dispatch("addItemToTrolley", {
      productCode,
      quantity,
      channel: TrolleyChannel.DIRECTSHIP,
    });
  },

  /**
   * @todo this needs serious refactoring
   */
  async addItemToTrolley(
    { state, dispatch, commit, getters },
    {
      productCode,
      quantity = 1,
      channel = 1,
    }: {
      productCode: string | null;
      quantity: number;
      channel: TrolleyChannel;
    }
  ): Promise<Partial<TrolleyData> | null> {
    if (
      isEmpty(productCode) ||
      isNil(productCode) ||
      !/^[a-zA-Z0-9]{5}/.test(productCode)
    ) {
      return null;
    }

    // So the customer can see something is happening whilst we process the rest in the background
    commit("setAddedToTrolleyModalVisibility", true);
    commit("setTrolleyUpdateError", false);

    let result: RawTrolleyData | Partial<TrolleyData> = {};

    if (state.trolley == null) {
      result = await dispatch("createTrolley");
    } else {
      result = state.trolley;
    }

    if (!isNil(result?.id)) {
      // Check to see if we already have this product in our trolley, and call update line item quantity instead
      const productAlreadyInTrolley: LineItem = getters.trolleyLines.find(
        (line: LineItem) => line.product_code === productCode
      );

      if (
        productAlreadyInTrolley != null &&
        !isNil(productAlreadyInTrolley.id)
      ) {
        result = (await updateLineItem(
          this.$axios,
          productAlreadyInTrolley.id,
          { quantity: productAlreadyInTrolley.quantity + quantity, channel }
        )
          .then((response) => response.data)
          .catch(() => null)) as RawTrolleyData;
      } else if (!isNil(result?.id)) {
        try {
          const { data } = await addItemToTrolley(this.$axios, result.id, {
            product_code: productCode,
            quantity: clamp(
              thresholds.trolley.MIN_ALLOWED_QUANTITY,
              thresholds.trolley.MAX_ALLOWED_QUANTITY,
              quantity
            ),
            channel,
            delivery_method_code: getDefaultDeliveryCode(channel),
          });

          // TODO - fetch the product and add into the trolley line

          if (!data.customer_id) {
            this.$cookies.set(
              AppCookieNames.GuestCheckoutSession,
              data.session_token,
              { secure: true }
            );
          }

          result = data;
        } catch (e) {
          console.error(e);
          commit("setLoading", false);
          commit("setTrolleyUpdateError", true);
          return null;
        }
      }
    }

    if (!isNil(state.trolleyId)) {
      await dispatch("refreshTrolleyData", result);
    }

    commit("setLastTrolleyUpdateProductCodes", productCode);
    commit("setLastTrolleyUpdateChannel", channel);
    commit("setLoading", false);
    commit("setTrolleyUpdateError", false);

    return state.trolley;
  },

  async getFullTrolleyPreviewData(
    { commit },
    trolleyData: RawTrolleyData
  ): Promise<TrolleyData | null> {
    if (isEmpty(trolleyData) || isNil(trolleyData)) {
      return null;
    }

    let indexedProducts: { [p: string]: Product };

    if (trolleyData?.lines?.length !== 0) {
      const products = await fetchProducts(this.$axios, {
        products: trolleyData.lines.map((line) => line.product_code),
      }).catch(() => null);
      if (products !== null) {
        indexedProducts = indexProductsByProductCode(products);
      }
    } else {
      indexedProducts = {};
    }

    try {
      commit("setTrolleyData", {
        ...trolleyData,
        lines: trolleyData.lines.map((lineItem) => ({
          ...lineItem,
          product: { ...indexedProducts[lineItem.product_code] },
        })),
      });
    } catch (e) {
      console.error(e);
    }

    return trolleyData;
  },

  async findLatestTrolley({
    rootGetters,
    dispatch,
  }): Promise<Partial<TrolleyData | null>> {
    const { id: customerId } = rootGetters["auth/getUser"];

    const latestTrolley = await getCustomerActiveTrolleysByCustomerId(
      this.$axios,
      customerId
    )
      .then((response) => response.data)
      .then((trolleys: RawTrolleyData[]) => {
        return trolleys.length > 0 ? trolleys[0] : null;
      });

    await dispatch("refreshTrolleyData", latestTrolley);

    return latestTrolley;
  },

  async getCustomerTrolleyById(
    { dispatch, state },
    { trolleyId }
  ): Promise<Partial<TrolleyData> | null> {
    if (isNil(trolleyId)) {
      return null;
    }

    try {
      const response = await getTrolleyById(this.$axios, trolleyId);

      if (response !== null) {
        await dispatch("refreshTrolleyData", response.data);
      }
    } catch (e) {
      await dispatch("clearTrolley");
      console.log(e);
    }
    return state.trolley;
  },

  async convertGuestTrolleyToCustomerTrolley(
    { commit, dispatch, rootGetters },
    { trolleyId, sessionToken }
  ): Promise<Partial<TrolleyData> | null> {
    if (isNil(sessionToken) || isNil(trolleyId)) {
      return null;
    }

    let result: TrolleyData | null = null;
    try {
      await dispatch("setAxiosHeaders", sessionToken);
      const customerId =
        rootGetters["auth/getUserId"] || rootGetters["guest/getGuestId"];

      const { data } = await convertGuestTrolleyToCustomerTrolley(
        this.$axios,
        trolleyId ?? "",
        customerId
      );

      dispatch("deleteAxiosSessionHeader");
      commit("guest/setGuestCheckoutSession", null, { root: true });
      dispatch("setTrolleyId", data.id);
      result = data;
      await dispatch("refreshTrolleyData", result);
    } catch (e) {
      console.error(e);
    }

    return result;
  },

  async getGuestTrolleyData(
    { state, dispatch },
    { trolleyId, sessionToken }
  ): Promise<Partial<TrolleyData> | null> {
    if (!(trolleyId as boolean) || !(sessionToken as boolean)) {
      return null;
    }

    try {
      await dispatch("setAxiosHeaders", sessionToken);
      await dispatch("getGuestTrolleyById", trolleyId);
      await dispatch("getTrolleyOrdersTotal");
    } catch (error) {
      console.error(error);
    }

    return state.trolley;
  },

  async getGuestTrolleyById(
    { state, dispatch },
    id: string
  ): Promise<Partial<TrolleyData> | null> {
    let result: Partial<TrolleyData> | null = null;
    if (isNil(id)) {
      return result;
    }
    try {
      const response = await getTrolleyById(this.$axios, id);
      if (response !== null) {
        await dispatch("refreshTrolleyData", response.data);
        result = state.trolley;
      }
    } catch (error) {
      await dispatch("clearTrolley");
      console.error(error);
    }
    return result;
  },

  async getTrolleyOrdersTotal({
    commit,
    state,
    getters,
  }): Promise<TrolleyTotals | null> {
    let result: TrolleyTotals | null = null;
    if (isNil(state.trolley?.id)) {
      return result;
    }
    try {
      result = await fetchTrolleyOrdersTotal(
        this.$axios,
        state.trolley?.id ?? "",
        getters.staffDiscountToken
      )
        .then((response) => response.data)
        .catch(() => null);
      commit("setTrolleyData", result);
    } catch (error) {
      console.error(error);
    }
    return result;
  },

  async deleteTrolleyLine({ dispatch }, { trolleyLineId }): Promise<void> {
    const trolley = await deleteTrolleyLineById(this.$axios, trolleyLineId);
    await dispatch("refreshTrolleyData", trolley);
  },

  async updateTrolleyLineQuantity(
    { dispatch },
    { trolleyLineId, quantity }
  ): Promise<void> {
    const trolley = await updateLineItemQuantityById(
      this.$axios,
      trolleyLineId,
      quantity
    );
    await dispatch("refreshTrolleyData", trolley);
  },

  async updateTrolleyLineChannel(
    { dispatch },
    { trolleyLineId, channel }
  ): Promise<void> {
    const trolley = await updateLineItemChannel(
      this.$axios,
      trolleyLineId,
      channel
    );
    await dispatch("refreshTrolleyData", trolley);
    await dispatch("resetDeliveryMethods", trolley);
  },

  async updateTrolleyLineDeliveryMethod(
    { dispatch },
    { trolleyLineId, methodCode }
  ): Promise<void> {
    const trolley = await updateLineItemDeliveryMethod(
      this.$axios,
      trolleyLineId,
      methodCode
    );
    await dispatch("refreshTrolleyData", trolley);
  },

  async resetDeliveryMethods({ dispatch }, trolleyData): Promise<void> {
    await setDeliveryToCollection(this.$axios, trolleyData.id);
    await dispatch("getTrolleyOrdersTotal");
  },

  async addPromoCodeToTrolley(
    { dispatch },
    [trolleyId, promoCode]
  ): Promise<void> {
    try {
      const response = await addPromoCodeToTrolley(
        this.$axios,
        trolleyId ?? "",
        { code: promoCode }
      );
      if (response !== null) {
        await dispatch("refreshTrolleyData", response.data);
      }
    } catch (error) {
      console.error(error);
    }
  },

  async removePromoCodeFromTrolley({ dispatch }, [promoCodeId]): Promise<void> {
    try {
      const response = await deletePromoCodeFromTrolley(
        this.$axios,
        promoCodeId
      );
      if (response !== null) {
        await dispatch("refreshTrolleyData", response.data);
      }
    } catch (error) {
      console.error(error);
    }
  },

  resync({ dispatch, state }) {
    dispatch("refreshTrolleyData", state.trolley);
  },

  async refreshTrolleyData(
    { dispatch, commit, rootGetters },
    trolleyData: RawTrolleyData
  ): Promise<void> {
    if (trolleyData === null) {
      return;
    }

    // staff discount apply
    await dispatch("trolley/hydrateStaffDiscountToken", null, { root: true });
    const rg = rootGetters["trolley/staffDiscountToken"];
    const branchId = rootGetters["branch/selectedBranchId"];

    const discount = await checkTrolleyDiscount(
      this.$axios,
      trolleyData.id,
      rg,
      branchId
    );

    commit("setTrolleyDiscounts", discount);

    await dispatch("setTrolleyId", trolleyData.id);
    await dispatch("getFullTrolleyPreviewData", trolleyData);
    await dispatch("getTrolleyOrdersTotal");
  },

  async fetchDeliveryMethods({ commit, getters }) {
    const trolleyId = getters.trolleyId;

    if (isEmpty(trolleyId) || isNil(trolleyId)) {
      return;
    }

    if (!getters.getDeliveryAddressId && !getters.getCollectionAddressId) {
      return;
    }

    if (getters.trolleyLines.length === 0) {
      return;
    }

    const methods = await trolleyDeliveryMethods(this.$axios, trolleyId, {
      deliveryAddressId: getters.getDeliveryAddressId,
      collectionAddressId: getters.getCollectionAddressId,
    });

    commit("setDeliveryMethods", methods);
  },

  clearTrolley({ commit }) {
    commit("setTrolleyData", null);
    this.$cookies.remove(AppCookieNames.Trolley);
  },

  setStaffDiscountToken({ commit }, rg: string | null) {
    commit("setStaffDiscountToken", rg);

    // @todo fx, needs to be moved
    if (window.localStorage && rg) {
      window.localStorage.setItem("rg", rg);
    }
  },

  hydrateStaffDiscountToken({ commit }) {
    const rg = window.localStorage && window.localStorage.getItem("rg");

    // @todo fx, needs to be moved
    if (rg) {
      commit("setStaffDiscountToken", window.localStorage.getItem("rg"));
    }
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
