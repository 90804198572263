export default (context) => {
  const { app, store } = context;

  app.router.beforeEach((to, from, next) => {
    // Pages where we want to disable the global route loading indicator
    const disabledPages = [
      "search",
      "topslug-slug_id-id",
      "topslug-slug_id-id/brands/:slug_id",
    ];

    store.commit(
      "setPageLoadingIndicator",
      !disabledPages.includes(to.name) || !disabledPages.includes(from.name)
    );

    next();
  });
};
