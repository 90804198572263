<template functional>
  <svg viewBox="0 0 24 24" fill="none" :class="props.classes">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M3.5 5.04545C3.5 4.19159 4.19159 3.5 5.04545 3.5H8.13636C8.99023 3.5 9.68182 4.19159 9.68182 5.04545V8.13636C9.68182 8.99023 8.99023 9.68182 8.13636 9.68182H5.04545C4.19159 9.68182 3.5 8.99023 3.5 8.13636V5.04545ZM14.3182 5.04545C14.3182 4.19159 15.0098 3.5 15.8637 3.5H18.9546C19.8084 3.5 20.5 4.19159 20.5 5.04545V8.13636C20.5 8.99023 19.8084 9.68182 18.9546 9.68182H15.8637C15.0098 9.68182 14.3182 8.99023 14.3182 8.13636V5.04545ZM5.0455 5.04545H8.13641V8.13636H5.0455V5.04545ZM11.2273 6.5909V5.04545H12.7728V6.5909H11.2273ZM15.8636 5.04545H18.9545V8.13636H15.8636V5.04545ZM5.8182 7.36364V5.81819H7.36366V7.36364H5.8182ZM16.6364 5.81819V7.36364H18.1819V5.81819H16.6364ZM11.2273 9.68182V8.13636H12.7728V9.68182H11.2273ZM5.0455 11.2273V12.7727H6.59096V11.2273H5.0455ZM8.1364 12.7727V11.2273H9.68186V12.7727H8.1364ZM11.2273 11.2273V12.7727L12.7727 12.7727V14.3182H14.3182V12.7727L12.7728 12.7727V11.2273H11.2273ZM14.3182 12.7727L15.8636 12.7727V14.3182H17.4091V12.7727L15.8637 12.7727V11.2273H14.3182V12.7727ZM17.4091 12.7727L18.9545 12.7727V14.3182H20.5V12.7727L18.9546 12.7727V11.2273H17.4091V12.7727ZM18.9546 14.3182H17.4091V15.8636L18.9545 15.8636V17.4091L17.4091 17.4091V18.9545H18.9546V17.4091L20.5 17.4091V15.8636L18.9546 15.8636V14.3182ZM15.8636 18.9545H17.4091V20.5H15.8636V18.9545ZM15.8637 18.9545V17.4091L17.4091 17.4091V15.8636L15.8637 15.8636V14.3182H14.3182V15.8636L15.8636 15.8636V17.4091L14.3182 17.4091V18.9545H15.8637ZM12.7727 18.9545H14.3182V20.5H12.7727V18.9545ZM12.7728 18.9545V17.4091L14.3182 17.4091V15.8636L12.7728 15.8636V14.3182H11.2273V15.8636L12.7727 15.8636V17.4091L11.2273 17.4091V18.9545H12.7728ZM3.5 15.8636C3.5 15.0098 4.19159 14.3182 5.04545 14.3182H8.13636C8.99023 14.3182 9.68182 15.0098 9.68182 15.8636V18.9546C9.68182 19.8084 8.99023 20.5 8.13636 20.5H5.04545C4.19159 20.5 3.5 19.8084 3.5 18.9546V15.8636ZM8.13641 15.8636V18.9545H5.0455V15.8636H8.13641ZM5.8182 18.1818V16.6364H7.36366V18.1818H5.8182Z"
      fill="currentColor"
    />
  </svg>
</template>

<script>
export default {
  name: "QrIcon",
  props: {
    classes: {
      type: String,
      required: false,
      default: "",
    },
  },
};
</script>
