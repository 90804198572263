import { ActionTree, GetterTree, MutationTree } from "vuex";
import { isNil } from "ramda";
import { RootState } from "./index";
import { getSearchData } from "~/services/search.service";
import type { SearchBarState, SearchData } from "~/types/searchBar";

export const defaultSearchData = {
  queryContext: {
    originalQuery: "",
  },
  suggestionGroups: [],
};
export const state = (): SearchBarState => ({
  searchData: defaultSearchData,
  searchApi: "",
  searchQuery: "",
});

export const getters: GetterTree<SearchBarState, RootState> = {
  searchData: (state): SearchData => state.searchData,
  searchQuery: (state): string => (state.searchQuery ?? "").trim(),
};

export const mutations: MutationTree<SearchBarState> = {
  setSearchData: (state, payload: SearchData): void => {
    state.searchData = payload;
  },
  setSearchApi: (state, payload: string): void => {
    state.searchApi = payload;
  },
  setSearchQuery: (state, payload: string): void => {
    state.searchQuery = payload;
  },
};

export const actions: ActionTree<SearchBarState, RootState> = {
  async getSearchData(
    { commit, state, rootGetters },
    query: string
  ): Promise<SearchData | null> {
    let result: SearchData | null = null;
    try {
      const { suggestPlatform } =
        rootGetters["featureFlags/getFeatureFlags"] || {};
      let crsEnabled = false;

      if (!isNil(this.$cookies.get("_ts_use_crs_suggestions"))) {
        crsEnabled = true;
      }
      if (suggestPlatform === "GOOGLE_CRS") {
        crsEnabled = true;
      }

      const data = await getSearchData(this.$axios, this.$cookies, {
        query,
        searchApi: state.searchApi,
        crsEnabled,
      });
      if (data != null) {
        result = data;
      }
      commit("setSearchData", result);
    } catch (error) {
      this.$log.error(error);
    }

    return result;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
