// TODO: Investigate how to test this.

import { namedBoxType } from "../util";

export const loadImageObjectFromUrl = async (
  src: string
): Promise<HTMLImageElement> => {
  return await new Promise((resolve, reject) => {
    const img = new Image();
    img.onload = () => resolve(img);
    img.onerror = reject;
    img.src = src;
  });
};

// @see lib/shared/index.ts:28

// Associate some data named "image"
export const imageBoxedType = namedBoxType<"image">("image");

// Associate some data named "video"
export const videoBoxedType = namedBoxType<"video">("video");
