import { TrackingEvents } from "~/store/tracking";

export default ({ app, store, $dd }) => {
  app.router.afterEach((to, _from) => {
    $dd.startView({ name: to.name });

    // Remove items from the dataLayer if 'removeOnNewPage' key is set
    window.dataLayer.forEach((val, index) => {
      if (Object.prototype.hasOwnProperty.call(val, "removeOnNewPage"))
        window.dataLayer.splice(index, 1);
    });

    function fireTracking() {
      // This acts as the 'page view' tracking event within GTM. The firing order of this is setup to work with the GTM events. Please consider before changing.
      store.commit("tracking/addTrackingEvent", {
        type: TrackingEvents.PageView,
        data: {
          to,
        },
      });
      store.commit("tracking/addTrackingEvent", {
        type: TrackingEvents.MetaData,
      });
    }

    fireTracking();
  });
};
