import { datadogRum } from "@datadog/browser-rum";
import type { Context } from "@nuxt/types";
import { Inject } from "@nuxt/types/app";
import { version } from "../package.json";

export default (context: Context, inject: Inject): void => {
  const { $config, isDev, store } = context;
  // https://nuxtjs.org/docs/configuration-glossary/configuration-dev/
  const id = store.getters["auth/getUserId"];

  datadogRum.init({
    applicationId: $config.dataDogApplicationId,
    clientToken: $config.dataDogClientToken,
    site: "datadoghq.eu",
    service: $config.dataDogService,
    env: $config.dataDogEnvironment || $config.deploymentEnvironment,
    version,
    sessionSampleRate: isDev ? 0 : $config.dataDogSessionSampleRate,
    sessionReplaySampleRate: isDev ? 0 : $config.dataDogSessionReplaySampleRate,
    // @ts-ignore
    trackResources: true,
    trackLongTasks: true,
    trackUserInteractions: true,
    trackViewsManually: true,
  });

  if (id) {
    datadogRum.setUser({
      id,
    });
  }

  console.log("init rum");

  inject("dd", datadogRum);
};
