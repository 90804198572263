import throttle from "lodash.throttle";

function handleScroll() {
  if ("requestIdleCallback" in window) {
    window.requestIdleCallback(this.handleThrottledScroll, {
      timeout: 50,
    });
  } else {
    this.handleThrottledScroll();
  }
}

export default {
  created() {
    if (process.client) {
      globalThis.window.addEventListener("scroll", this.throttledScroll);
    }
  },
  destroyed() {
    if (process.client) {
      globalThis.window.removeEventListener("scroll", this.throttledScroll);
    }
  },
  methods: {
    throttledScroll: throttle(handleScroll, 50),
  },
};

// TODO: add unit tests for this mixin
