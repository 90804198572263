<template functional>
  <svg viewBox="0 0 24 24" fill="none" :class="props.classes">
    <path
      d="M20.25 5H7.5C7.08525 5 6.75 5.336 6.75 5.75V6.5C6.75 6.914 7.08525 7.25 7.5 7.25H20.25C20.6647 7.25 21 6.914 21 6.5V5.75C21 5.336 20.6647 5 20.25 5Z"
      fill="currentColor"
    />
    <path
      d="M20.25 8.75H7.5C7.08525 8.75 6.75 9.086 6.75 9.5V10.25C6.75 10.664 7.08525 11 7.5 11H20.25C20.6647 11 21 10.664 21 10.25V9.5C21 9.086 20.6647 8.75 20.25 8.75Z"
      fill="currentColor"
    />
    <path
      d="M20.25 12.5H7.5C7.08525 12.5 6.75 12.836 6.75 13.25V14C6.75 14.414 7.08525 14.75 7.5 14.75H20.25C20.6647 14.75 21 14.414 21 14V13.25C21 12.836 20.6647 12.5 20.25 12.5Z"
      fill="currentColor"
    />
    <path
      d="M20.25 16.25H7.5C7.08525 16.25 6.75 16.586 6.75 17V17.75C6.75 18.164 7.08525 18.5 7.5 18.5H20.25C20.6647 18.5 21 18.164 21 17.75V17C21 16.586 20.6647 16.25 20.25 16.25Z"
      fill="currentColor"
    />
    <path
      d="M3.75 7.25H4.5C4.91475 7.25 5.25 6.914 5.25 6.5V5.75C5.25 5.336 4.91475 5 4.5 5H3.75C3.33525 5 3 5.336 3 5.75V6.5C3 6.914 3.33525 7.25 3.75 7.25Z"
      fill="currentColor"
    />
    <path
      d="M3.75 11H4.5C4.91475 11 5.25 10.664 5.25 10.25V9.5C5.25 9.086 4.91475 8.75 4.5 8.75H3.75C3.33525 8.75 3 9.086 3 9.5V10.25C3 10.664 3.33525 11 3.75 11Z"
      fill="currentColor"
    />
    <path
      d="M3.75 14.75H4.5C4.91475 14.75 5.25 14.414 5.25 14V13.25C5.25 12.836 4.91475 12.5 4.5 12.5H3.75C3.33525 12.5 3 12.836 3 13.25V14C3 14.414 3.33525 14.75 3.75 14.75Z"
      fill="currentColor"
    />
    <path
      d="M3.75 18.5H4.5C4.91475 18.5 5.25 18.164 5.25 17.75V17C5.25 16.586 4.91475 16.25 4.5 16.25H3.75C3.33525 16.25 3 16.586 3 17V17.75C3 18.164 3.33525 18.5 3.75 18.5Z"
      fill="currentColor"
    />
  </svg>
</template>

<script>
export default {
  name: "ListIcon",
  props: {
    classes: {
      type: String,
      required: false,
      default: "",
    },
  },
};
</script>
