import { NuxtAxiosInstance } from "@nuxtjs/axios";
import {
  ApiBranchesResponse,
  ApiBranchResponse,
} from "~/services/api/branches.api.d";

const ENDPOINTS = {
  BRANCHES: `/branches`,
  NEAREST: `/branches/nearest`,
  BRANCH: (branchId: string) => `/branches/${branchId}`,
};

export const getBranches = async (
  $axios: NuxtAxiosInstance
): Promise<ApiBranchesResponse> => await $axios.$get(ENDPOINTS.BRANCHES);

export const nearestBranches = async (
  $axios: NuxtAxiosInstance
): Promise<ApiBranchesResponse> => await $axios.$post(ENDPOINTS.NEAREST);

export const getBranch = async (
  $axios: NuxtAxiosInstance,
  branchId: string
): Promise<ApiBranchResponse> => await $axios.$get(ENDPOINTS.BRANCH(branchId));
