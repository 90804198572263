<template>
  <div class="md:content-container min-h-[48px]">
    <div
      class="w-full px-1 xs:px-4 py-3 md:py-6 md:px-0 flex items-center justify-start"
    >
      <nuxt-link
        to="/account/home"
        class="breadcrumb-link"
        :class="
          $route.name === 'account-home'
            ? 'text-[20px] lg:text-size-9 font-bold'
            : 'text-[16px] font-regular'
        "
      >
        <span v-if="$route.name !== 'account-home'" class="mr-1">&lt;</span>
        <span>{{ $t("account.accountProfile") }}</span>
      </nuxt-link>
      <nuxt-link
        v-for="item in items"
        :key="item.title"
        :to="item.to ?? ''"
        class="breadcrumb-link text-[16px] font-regular"
      >
        <span>
          {{ item.title }}
        </span>
      </nuxt-link>
    </div>
  </div>
</template>

<script>
export default {
  name: "AccountBreadcrumbs",
  props: {
    items: {
      type: Array,
      default: () => [],
    },
  },
};
</script>

<style lang="postcss">
.breadcrumb-link {
  @apply leading-[24px] text-blue flex;

  &:not(:last-of-type) {
    &:after {
      content: "/";
      @apply block mx-1;
    }
  }

  &.nuxt-link-exact-active {
    @apply font-bold;
  }
}
</style>
