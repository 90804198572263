import { timeNowInSeconds } from "~/utils/date";

/**
 * Checks if the access token requires refreshing
 *
 * @param accessToken - API access token
 * @param expiresTime - Epoch token expires
 * @param expiryBuffer - (seconds) buffer so we don't wait til the token has fully expired
 * @returns boolean
 */
export const accessTokenRequiresRefresh = (
  accessToken: string | null,
  expiresTime: number,
  expiryBuffer: number
): boolean => {
  if (!(accessToken && expiresTime)) {
    return true;
  }

  const now = timeNowInSeconds();
  return now > expiresTime - expiryBuffer;
};
