import { ObjectDirective, VNode } from "vue";
import { DirectiveBinding } from "vue/types/options";

interface ClickOutsideHTMLElement extends HTMLElement {
  clickedOutsideEvent: any;
}

export const ClickOutside: ObjectDirective = {
  bind(el: HTMLElement, binding: DirectiveBinding, vnode: VNode) {
    const currentElement = el as ClickOutsideHTMLElement;

    const handler =
      binding.value instanceof Function
        ? binding.value.bind(vnode.context)
        : () => {};

    currentElement.clickedOutsideEvent = (event: Event) => {
      if (
        !(
          currentElement === event.target ||
          currentElement.contains(event.target as Node)
        )
      ) {
        handler(event);
      }
    };

    document.body.addEventListener("click", currentElement.clickedOutsideEvent);
  },
  unbind(el: HTMLElement) {
    document.body.removeEventListener(
      "click",
      (el as ClickOutsideHTMLElement).clickedOutsideEvent
    );
  },
};

export default ClickOutside;
