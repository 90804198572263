<template functional>
  <svg
    :class="props.classes"
    xmlns="http://www.w3.org/2000/svg"
    :width="props.width"
    :height="props.height"
    viewBox="0 0 5 5"
    fill="none"
  >
    <path
      d="M4.95159 2.02V3.028L3.31359 2.974L4.24959 4.324L3.33159 4.864L2.50359 3.406L1.67559 4.864L0.757586 4.324L1.69359 2.974L0.055586 3.028V2.02L1.69359 2.074L0.757586 0.723999L1.67559 0.184L2.50359 1.642L3.33159 0.184L4.24959 0.723999L3.31359 2.074L4.95159 2.02Z"
      fill="currentColor"
    />
  </svg>
</template>

<script>
export default {
  name: "StarIcon",
  props: {
    classes: {
      type: String,
      required: false,
      default: "",
    },
    height: {
      type: String,
      required: false,
      default: "5",
    },
    width: {
      type: String,
      required: false,
      default: "5",
    },
  },
};
</script>
