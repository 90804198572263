<template>
  <div class="grid grid-cols-2 gap-7">
    <component
      :is="cardType"
      v-for="(recommendation, index) in data.data"
      :key="index"
      :image-url="recommendation.thumbImage"
      :title="recommendation.title"
      :reviews="recommendation.tsReviews || 0"
      :gross-price="getPrice(recommendation, 'gross')"
      :net-price="getPrice(recommendation, 'net')"
      :saving-value="getSavingValue(recommendation)"
      :product="recommendation"
      :cta="cta"
      :show-flash="showFlash"
      :show-add-to-trolley="showAddToTrolley"
      :badge-icons="
        showBadges
          ? {
              brand: {
                imageUrl: getBadgeUrl(recommendation.brand || '', 'brand'),
                altText: recommendation.brand || '',
              },
              badge: {
                imageUrl: getBadgeUrl(recommendation.assettr || '', 'badge'),
                altText: recommendation.brand || '',
              },
            }
          : {}
      "
      @trackClickPixel="trackClick"
    />
  </div>
</template>
<script>
import PathwaysAndRecsMixin from "../mixins/PathwaysAndRecsMixin";

export default {
  name: "ProductRecsGrid",
  components: {
    CardVersionOne: () =>
      import(
        /* webpackChunkName: "CardVersionOne" */ "~/components/product-recommendations/cards/VersionOne.vue"
      ),
    CardVersionTwo: () =>
      import(
        /* webpackChunkName: "CardVersionTwo" */ "~/components/product-recommendations/cards/VersionTwo.vue"
      ),
  },
  mixins: [PathwaysAndRecsMixin],
  props: {
    cta: {
      type: String,
      required: false,
      default: "",
    },
    showBadges: {
      type: Boolean,
      required: false,
      default: false,
    },
    showFlash: {
      type: Boolean,
      required: false,
      default: false,
    },
    showAddToTrolley: {
      type: Boolean,
      required: false,
      default: false,
    },
    cardType: {
      type: String,
      required: true,
    },
    data: {
      type: Object,
      required: true,
    },
  },
  methods: {
    trackClick(data) {
      this.$emit("trackClickPixel", data);
    },
  },
};
</script>
