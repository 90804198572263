import type { Context } from "@nuxt/types";

export default (context: Context): void => {
  const { store, redirect } = context;

  const trolleyLinesCount = store.getters["trolley/trolleyLines"]?.length ?? 0;

  // @see note in mixins/trolley/TrolleyAddresses
  if (process.client) {
    if (trolleyLinesCount === 0) {
      return redirect("/trolley");
    }
  }
};
