<template>
  <div
    class="flex flex-col items-center justify-center relative overflow-hidden"
    :class="heightClasses"
  >
    <div
      v-if="backgroundColour"
      :style="backgroundColourClass"
      class="w-full object-cover h-2/3"
    />
    <img
      v-else
      class="w-full object-contain h-2/3"
      :src="imageUrl"
      :alt="title"
      loading="lazy"
    />
  </div>
</template>

<script setup>
import { computed } from "vue";

const props = defineProps({
  heightClasses: {
    type: String,
    required: true,
  },
  imageUrl: {
    type: String,
    required: true,
  },
  backgroundColour: {
    type: String,
    required: false,
    default: null,
  },
  title: {
    type: String,
    required: true,
  },
});

const backgroundColourClass = computed(
  () => `background-color:${props.backgroundColour}`
);
</script>
