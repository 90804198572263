export interface Geolocation {
  latitude: string;
  longitude: string;
}

export interface MatchedSubstring {
  offset: number;
  length: number;
}

export interface MatchedSubstrings {
  name: MatchedSubstring[];
  description: MatchedSubstring[];
  admin_0: MatchedSubstring[];
}

export interface Branch {
  address: string[];
  address_id: string;
  address_text: string;
  click_and_collect_available: boolean;
  contact_number: string;
  details: string;
  distance: number;
  geolocation: Geolocation;
  id: string;
  name: string;
  opening_hours: string[];
  parking_available: boolean;
  postcode: string;
  status: number;
}

export interface Location {
  admin_0: string;
  admin_1: string;
  description: string;
  has_addresses: boolean;
  locality: string;
  location: { [key: string]: string };
  matched_substrings?: MatchedSubstrings;
  name: string;
  public_id: string;
  type: string;
}

export interface BranchState {
  nearestBranches: Branch[];
  postcodeApiKey: string;
  postcodes: Location[];
  selectedBranchName: string | null;
  selectedBranchId: string | null;
  showBranchModal: boolean;
  storeNotFoundByLocationPostcode: string | null;
  showStockFor: string | null;
  selectedBranchLetter: string;
  selectedLocality: Branch;
}

export interface BranchesLocation {
  latitude?: string;
  longitude?: string;
  postcode?: string;
  exactSearch?: boolean;
}

export enum BranchCookieNames {
  StoreInfo = "storeInfo",
  SelectedSiteName = "selectedSiteName",
  SelectedSiteId = "selectedSite",
}

export interface GoogleMapPosition {
  lat: Number | any;
  lng: Number | any;
}

export interface AllBranches {
  [key: string]: Branch[];
}
