const ENDPOINTS = {
  PRODUCTS_BY_CODE: (productCode: string) => `/products/${productCode}`,
  MONETATE_OMNI_JSON: `https://engine.monetate.net/api/engine/v1/decide/a-a77f9577`,
};

export const getMonetateOmniJson = async (
  monetateBody: Object
): Promise<Object> => {
  const response = await fetch(ENDPOINTS.MONETATE_OMNI_JSON, {
    method: "POST",
    headers: {
      Accept: "*/*",
      "Content-Type": "application/JSON",
    },
    body: JSON.stringify(monetateBody),
  });

  return response.json();
};
