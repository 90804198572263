import { uniq } from "ramda";
import { Route } from "vue-router/types/router";
import { ActionTree, GetterTree, MutationTree } from "vuex";
import { RootState } from ".";
import { BloomreachFolderPaths } from "~/services/api/bloomreach.api";
import { fetchBrDocument } from "~/services/bloomreach.service";

interface SiteMessagesState {
  messages: {
    siteWideMessages: any[];
    categoryMessages: any[];
    subCatMessages: any[];
    productPageMessages: any[];
    messageBySlug: any[];
  };
  messagesSet: boolean;
}

export const state = (): SiteMessagesState => ({
  messages: {
    siteWideMessages: [],
    categoryMessages: [],
    subCatMessages: [],
    productPageMessages: [],
    messageBySlug: [],
  },
  messagesSet: false,
});

export const mutations: MutationTree<SiteMessagesState> = {
  setSiteMessages: (state, payload) => {
    state.messages = payload;
    state.messagesSet = true;
  },
};

export const actions: ActionTree<SiteMessagesState, RootState> = {
  async fetchSiteMessages({ commit }, brContentURL) {
    const messages = await fetchBrDocument(
      BloomreachFolderPaths.SiteMessages,
      "site-messages",
      brContentURL
    );
    commit("setSiteMessages", messages);
  },
};

export const getters: GetterTree<SiteMessagesState, RootState> = {
  messagesSet: (state) => state.messagesSet,
  getAllMessages: (state) => state.messages,
  getMessages: (state) => (route: Route) => {
    const routePath = route.path;
    const routeName = route.name;

    const siteWideMessages = state.messages?.siteWideMessages || [];

    const templateMessages = () => {
      switch (routeName) {
        case "topslug-id":
          return state.messages?.categoryMessages ?? [];
        case "topslug-slug_id-id":
          return state.messages?.subCatMessages ?? [];
        case "topslug-productId":
          return state.messages?.productPageMessages ?? [];
        default:
          return [];
      }
    };

    const messagesBySlug =
      state?.messages?.messageBySlug?.find((page) => {
        const slug = page.pageSlug;
        const pathMatch = new RegExp(`^${slug.replace("*", ".*")}$`);
        return (
          pathMatch.test(routePath) || routePath === slug.replace("/*", "")
        );
      })?.messageDoc ?? [];

    // Remove duplicates
    const messages = uniq([
      ...siteWideMessages,
      ...templateMessages(),
      ...messagesBySlug,
    ]);

    return messages;
  },
};

export default {
  state,
  getters,
  mutations,
  actions,
};
