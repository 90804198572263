import type { Context } from "@nuxt/types";

export default (context: Context): void => {
  const { store, redirect, route } = context;

  if (!store.state?.guest?.guestEmail) {
    const redirectTo = route.path;
    redirect(`/login?redirectTo=${redirectTo}`);
  }
};
