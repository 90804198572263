<template functional>
  <svg viewBox="0 0 24 24" fill="none" :class="props.classes">
    <path
      d="M12.8261 5.67556V8.47826H17.5256C18.3879 8.47826 19.087 9.17832 19.087 10.0388V19.4394C19.087 20.3013 18.3884 21 17.5256 21H6.56137C5.69905 21 5 20.2999 5 19.4394V10.0388C5 9.17695 5.69857 8.47826 6.56137 8.47826H11.2609V5.67302L10.3769 6.55695C10.0741 6.85976 9.58303 6.8596 9.27527 6.55185C8.96964 6.24622 8.97145 5.74889 9.27017 5.45017L11.4939 3.22642C11.6445 3.07585 11.8416 3.00019 12.0396 3L12.0456 3.00234C12.2443 3.00276 12.442 3.07788 12.5905 3.22642L14.8143 5.45017C15.1171 5.75298 15.1169 6.24409 14.8091 6.55185C14.5035 6.85747 14.0062 6.85567 13.7075 6.55695L12.8261 5.67556ZM11.2609 10.0435H6.56522V19.4348H17.5217V10.0435H12.8261V13.1751C12.8261 13.6067 12.4787 13.9565 12.0435 13.9565C11.6113 13.9565 11.2609 13.6063 11.2609 13.1751V10.0435ZM8.91304 8.47826V10.0435H15.1739V8.47826H8.91304ZM11.2609 8.47826H12.8261V10.0435H11.2609V8.47826Z"
      fill="currentColor"
    />
  </svg>
</template>

<script>
export default {
  name: "ShareIcon",
  props: {
    classes: {
      type: String,
      required: false,
      default: "",
    },
  },
};
</script>
