
import Vue from "vue";
import { mapGetters } from "vuex";
import BaseLayout from "~/layouts/baseLayout.vue";
import SiteHeader from "~/components/layout/header/TheHeader.vue";
import AccountMenu from "~/components/account/AccountMenu.vue";
import AccountTopMenu from "~/components/account/AccountTopMenu.vue";
import AccountBreadcrumbs from "~/components/account/AccountBreadcrumbs.vue";

export default Vue.extend({
  name: "MyAccountLayout",
  components: {
    AccountBreadcrumbs,
    AccountTopMenu,
    BaseLayout,
    SiteHeader,
    AccountMenu,
  },
  layout: "MyAccount",

  middleware: ["isLoggedIn"],

  computed: {
    ...mapGetters("breadcrumbs", ["getBreadcrumbsItems"]),

    ...mapGetters("auth", [
      "isAdminAccount",
      "getAccountId",
      "getAccount",
      "getAccountCreditInformation",
    ]),

    hideAccountTopMenu() {
      const path = this.$route.path;

      switch (path) {
        case "/account/details/manage":
        case "/account/details/address-book":
        case "/account/details/preferences":
        case "/account/details/catrequest":
          return "block";
        case "/account/details/manage/edit":
        case "/account/details/manage/change-password":
        case "/account/details/manage/change-username":
          return "hidden md:block";
        default:
          return "hidden";
      }
    },
  },
});
