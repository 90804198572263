<template>
  <div
    v-if="data[0] && (data[0].imageUrl || data[0].backgroundColour)"
    class="flex flex-col items-center"
  >
    <h3
      v-if="mainTitle"
      class="text-blue font-bold text-size-7 self-start mb-2"
    >
      {{ mainTitle }}
    </h3>
    <div class="w-full gap-4" :class="[widthClasses, { grid: !isCarousel }]">
      <!--  When in Carousel mode
       -->
      <BaseCarousel v-if="isCarousel" :settings="carouselSetting">
        <template #slides>
          <Component
            :is="getElementType(item.link.externalLink)"
            v-bind="getHref(item.link.externalLink)"
            v-for="(item, index) in computedData"
            :key="index"
          >
            <Component
              :is="getVariant(item)"
              :height-classes="heightClasses"
              :image-url="item.imageUrl"
              :background-colour="item.backgroundColour"
              :title="item.title"
              :cta="{
                path: item.link.externalLink,
                linkText: item.link.linkText,
                colour: ctaBtnColour,
              }"
              :title-text-colour="titleTextColour"
              :no-image-padding="noImagePadding"
            ></Component>
          </Component>
        </template>
      </BaseCarousel>

      <!--  When NOT in Carousel mode -->
      <template v-else>
        <Component
          :is="getElementType(item.link.externalLink)"
          v-bind="getHref(item.link.externalLink)"
          v-for="(item, index) in computedData"
          v-show="item.show"
          :key="index"
          data-testid="item-wrapper"
        >
          <Component
            :is="getVariant(item)"
            :height-classes="heightClasses"
            :image-url="item.imageUrl"
            :background-colour="item.backgroundColour"
            :title="item.title"
            :image-classes="imageClasses"
            :cta="{
              path: item.link.externalLink,
              linkText: item.link.linkText,
              colour: ctaBtnColour,
            }"
            :title-text-colour="titleTextColour"
            :no-image-padding="noImagePadding"
          />
        </Component>
      </template>
    </div>
    <DefaultButton
      v-if="
        showViewMoreButton &&
        !isCarousel &&
        splitResults() &&
        !(
          !isExpanded &&
          data.length === computedData.filter((item) => item.show).length
        )
      "
      ref="button"
      class="md:hidden mt-6 !px-24 !py-2 !text-size-4"
      @clickAction="toggleExpand()"
    >
      {{ computedButtonText }}
    </DefaultButton>
    <DefaultButton
      v-if="mainButton && mainButton.href"
      appearance="pill"
      size="lg"
      variant="primary"
      :path="mainButton && mainButton.href"
      class="w-[345px] mt-4 !p-3"
    >
      {{ mainButton.text }}
    </DefaultButton>
  </div>
</template>

<script setup>
import { computed, ref, nextTick } from "vue";
import BaseCarousel from "~/components/UI/carousel/BaseCarousel.vue";
import DefaultButton from "~/components/UI/button/DefaultButton.vue";
import { store } from "~/composables/utils";

// Variants
import VersionOne from "~/components/multi-text-and-image/variants/VersionOne.vue";
import VersionTwo from "~/components/multi-text-and-image/variants/VersionTwo";
import VersionThree from "~/components/multi-text-and-image/variants/VersionThree";
import VersionFour from "~/components/multi-text-and-image/variants/VersionFour";
import VersionFive from "~/components/multi-text-and-image/variants/VersionFive";
import VersionSix from "~/components/multi-text-and-image/variants/VersionSix";
import VersionSeven from "~/components/multi-text-and-image/variants/VersionSeven";
import VersionEight from "~/components/multi-text-and-image/variants/VersionEight";
import VersionNine from "~/components/multi-text-and-image/variants/VersionNine";
import VersionTen from "~/components/multi-text-and-image/variants/VersionTen";

const props = defineProps({
  data: {
    type: Array,
    required: true,
  },
  styleType: {
    type: String,
    required: true,
  },
  height: {
    type: String,
    required: true,
  },
  isCarousel: {
    type: Boolean,
    required: false,
    default: false,
  },
  itemsPerRowDesktop: {
    type: Number,
    required: true,
  },
  itemsPerRowTablet: {
    type: Number,
    required: true,
  },
  itemsPerRowMobile: {
    type: Number,
    required: true,
  },
  collapsedItemCount: {
    type: Object,
    required: false,
    default: () => {},
  },
  showViewMoreButton: {
    type: Boolean,
    required: false,
    default: false,
  },
  buttonText: {
    type: Object,
    required: false,
    default: () => {},
  },
  alignLeft: {
    type: Boolean,
    required: false,
    default: false,
  },
  imageClasses: {
    type: String,
    required: false,
    default: "",
  },
  ctaBtnColour: {
    type: String,
    required: false,
    default: "",
  },
  titleTextColour: {
    type: String,
    required: false,
    default: "",
  },
  mainTitle: {
    type: String,
    required: false,
    default: "",
  },
  mainButton: {
    type: Object,
    required: false,
    default: () => {},
  },
  noImagePadding: {
    type: Boolean,
    required: false,
    default: false,
  },
});

function getElementType(val) {
  return val ? "a" : "div";
}

function getHref(val) {
  return val ? { href: val } : {};
}

const isMobile = computed(() => store().getters["viewport/isMobile"]);
const isTablet = computed(() => store().getters["viewport/isTablet"]);
const isDesktop = computed(() => store().getters["viewport/isDesktop"]);

const computedData = computed(() => {
  if (!splitResults())
    return props.data.map((data) => ({
      ...data,
      show: true,
    }));

  const dataLength = () => {
    if (isMobile.value) return props.collapsedItemCount.mobile;
    if (isTablet.value) return props.collapsedItemCount.tablet;
    if (isDesktop.value)
      return props.collapsedItemCount?.desktop ?? props.data.length;
  };

  return props.data.map((data, index) => ({
    ...data,
    show: index < dataLength() || isExpanded.value,
  }));
});

function splitResults() {
  const { showViewMoreButton, collapsedItemCount, data } = props;

  if (!showViewMoreButton || (isDesktop.value && !props.showViewMoreButton))
    return false;
  if (isDesktop.value && collapsedItemCount.desktop >= data.length)
    return false;
  if (isTablet.value && collapsedItemCount.tablet >= data.length) return false;
  if (isMobile.value && collapsedItemCount.mobile >= data.length) return false;

  return true;
}

const computedButtonText = computed(() => {
  return isExpanded.value
    ? props.buttonText.expanded
    : props.buttonText.collapsed;
});

const isExpanded = ref(false);
const button = ref(null);

function toggleExpand() {
  isExpanded.value = !isExpanded.value;
  if (!isExpanded.value) {
    // Remove smooth scrolling on page
    document.documentElement.style.scrollBehavior = "unset";

    // Jump back to button when collapsed
    nextTick(() => {
      if (isDesktop.value) return;
      const element = button.value?.$el;
      if (!element) return;
      const y = element.getBoundingClientRect().top + window.pageYOffset;
      window.scrollTo({ top: y - 150 });
    });
  }
}

const carouselSetting = {
  autoplayDelay: 6000,
  slidesPerPage: props.itemsPerRowDesktop,
  paginateBySlide: true,
  loop: props.itemsPerRowDesktop !== props.data.length,
  responsive: [
    {
      maxWidth: 1024,
      slidesPerPage: props.itemsPerRowTablet,
      loop: props.itemsPerRowDesktop !== props.data.length,
    },
    {
      maxWidth: 640,
      slidesPerPage: props.itemsPerRowMobile,
      loop: props.itemsPerRowDesktop !== props.data.length,
    },
  ],
};

const heightClasses = computed(() => {
  if (props.isCarousel) return "h-auto";

  switch (props.height) {
    case "extra small":
      return "h-[130px] md:h-[140px]";
    case "small":
      return "h-[200px] md:h-[250px]";
    case "medium":
      return "h-[250px] md:h-[300px]";
    case "large":
      return "h-[300px] md:h-[350px]";
    default:
      return "h-[250px] md:h-[300px]";
  }
});

const widthClasses = computed(() => {
  if (props.isCarousel) return "";

  const desktop = getWidthClasses(props.itemsPerRowDesktop, "desktop");
  const tablet = getWidthClasses(props.itemsPerRowTablet, "tablet");
  const mobile = getWidthClasses(props.itemsPerRowMobile, "mobile");

  function getWidthClasses(val, deviceType) {
    switch (val) {
      case 1:
        return deviceType === "desktop"
          ? `lg:grid-cols-1`
          : deviceType === "tablet"
            ? "md:grid-cols-1"
            : "grid-cols-1";

      case 2:
        return deviceType === "desktop"
          ? `lg:grid-cols-2`
          : deviceType === "tablet"
            ? "md:grid-cols-2"
            : "grid-cols-2";

      case 3:
        return deviceType === "desktop"
          ? `lg:grid-cols-3`
          : deviceType === "tablet"
            ? "md:grid-cols-3"
            : "grid-cols-3";
      case 4:
        return deviceType === "desktop"
          ? `lg:grid-cols-4`
          : deviceType === "tablet"
            ? "md:grid-cols-4"
            : "grid-cols-4";
      case 5:
        return deviceType === "desktop"
          ? `lg:grid-cols-5`
          : deviceType === "tablet"
            ? "md:grid-cols-5"
            : "grid-cols-5";
      case 6:
        return deviceType === "desktop"
          ? `lg:grid-cols-6`
          : deviceType === "tablet"
            ? "md:grid-cols-6"
            : "grid-cols-6";
      default:
        return `w-full`;
    }
  }

  return `${mobile} ${tablet} ${desktop}`;
});

function getVariant(data) {
  if (props.styleType === "version 9" && !data?.imageUrl) {
    return VersionTen;
  }
  switch (props.styleType) {
    case "version 1":
      return VersionOne;
    case "version 2":
      return VersionTwo;
    case "version 3":
      return VersionThree;
    case "version 4":
      return VersionFour;
    case "version 5":
      return VersionFive;
    case "version 6":
      return VersionSix;
    case "version 7":
      return VersionSeven;
    case "version 8":
      return VersionEight;
    case "version 9":
      return VersionNine;
    case "version 10":
      return VersionTen;
    default:
      return VersionOne;
  }
}
</script>
