import dayjs from "dayjs";
import { isEmpty, isNil } from "ramda";
import { ActionTree, Commit, GetterTree, MutationTree } from "vuex";
import { RootState } from "./index";
import { getNearestBranches } from "~/services/branch.service";
import { updateSelectedBranch } from "~/services/customers.service";
import {
  getDetails,
  getPostcodeAutocompleteData,
} from "~/services/woosmap.service";
import type {
  Branch,
  BranchesLocation,
  BranchState,
  Location,
} from "~/types/branch";
import { BranchCookieNames } from "~/types/branch";
import { loadCountrySettings, LocaleCode, splitLocale } from "~/utils/i18n";

export const state = (): BranchState => ({
  nearestBranches: [],
  postcodeApiKey: "",
  postcodes: [],
  selectedBranchName: null,
  selectedBranchId: null,
  showBranchModal: false,
  storeNotFoundByLocationPostcode: null,
  showStockFor: null,
  selectedBranchLetter: "All",
  selectedLocality: {} as Branch,
});

export const getters: GetterTree<BranchState, RootState> = {
  nearestBranches: (state: BranchState): Branch[] => state.nearestBranches,
  postcodes: (state: BranchState): Location[] => state.postcodes,
  selectedBranchName: (state: BranchState): string | null =>
    state.selectedBranchName,
  selectedBranchId: (state: BranchState): string | null =>
    state.selectedBranchId,
  isSelectedBranchId: (state: BranchState): boolean =>
    isNil(state.selectedBranchId),
  showBranchModal: (state: BranchState): boolean => state.showBranchModal,
  storeNotFoundByLocationPostcode: (state: BranchState): string | null =>
    state.storeNotFoundByLocationPostcode,
  showStockFor: (state: BranchState): string | null =>
    state.showStockFor ?? null,
  selectedBranchLetter: (state: BranchState): string | null =>
    state.selectedBranchLetter,
  selectedLocality: (state: BranchState): Object | null =>
    state.selectedLocality,
  isSelectedBranch: (state: BranchState): Boolean =>
    isEmpty(state.selectedBranchId) && isNil(state.selectedBranchId),
};

export const mutations: MutationTree<BranchState> = {
  setNearestBranches: (state: BranchState, payload: Branch[]): void => {
    state.nearestBranches = payload;
  },
  setPostcodeApiKey: (state: BranchState, payload: string): void => {
    state.postcodeApiKey = payload;
  },
  setPostcodes: (state: BranchState, payload: Location[]): void => {
    state.postcodes = payload;
  },
  setSelectedBranch(state: BranchState, { cookieBranchName, cookieBranchId }) {
    state.selectedBranchId = cookieBranchId;
    state.selectedBranchName = cookieBranchName;
  },
  setShowBranchModal: (state: BranchState, payload: boolean): void => {
    state.showBranchModal = payload;
  },
  setShowStockFor: (state: BranchState, productCode: string | null): void => {
    state.showStockFor = productCode;
  },
  setStoreNotFoundByLocationPostcode: (
    state: BranchState,
    postcode: string | null
  ): void => {
    state.storeNotFoundByLocationPostcode = postcode ?? "";
  },
  resetStoreNotFoundByLocationPostcode(state: BranchState) {
    state.storeNotFoundByLocationPostcode = null;
  },
  setSelectedBranchLetter: (state: BranchState, letter: string): void => {
    state.selectedBranchLetter = letter;
  },
  setSelectedLocality: (state: BranchState, locality: Branch): void => {
    state.selectedLocality = locality;
  },
};

export const actions: ActionTree<BranchState, RootState> = {
  setStore({ commit, dispatch }, { name, id }) {
    commit("setNearestBranches", []);
    dispatch("setSelectedBranch", {
      cookieBranchName: name,
      cookieBranchId: id,
    });
    // index store
    commit("setCollectionSite", { name, id }, { root: true });
    // stock store
    commit("stock/showSelectedBranchStock", id, { root: true });
  },
  setSelectedBranch({ commit }, { cookieBranchName, cookieBranchId }) {
    commit("setSelectedBranch", { cookieBranchId, cookieBranchName });

    this.$cookies?.set(BranchCookieNames.SelectedSiteName, cookieBranchName, {
      expires: dayjs().add(1, "year").toDate(),
      path: "/",
    });
    this.$cookies?.set(BranchCookieNames.SelectedSiteId, cookieBranchId, {
      expires: dayjs().add(1, "year").toDate(),
      path: "/",
    });
  },
  async getNearestBranches(
    { state, commit, dispatch, getters },
    params: { query: BranchesLocation; locale: LocaleCode }
  ): Promise<Branch[] | null> {
    let branchesResult: Branch[] | null = [];
    let searchTerm = "";
    try {
      if (
        params.query.postcode !== undefined &&
        !isEmpty(params.query.postcode)
      ) {
        const currentCountry = splitLocale(params.locale).countryCode;
        const countrySettings = await loadCountrySettings(currentCountry);
        const postcodeRegex = new RegExp(countrySettings.postcodeRegex);

        searchTerm = params.query.postcode ?? "";
        if (!postcodeRegex.test(searchTerm?.replace(/\s/g, ""))) {
          const postcodes = getters.postcodes;

          if (!isNil(postcodes[0])) {
            const locality = await getDetails(
              state.postcodeApiKey,
              postcodes[0].public_id
            );
            params.query = {
              latitude: locality.geometry.location.lat,
              longitude: locality.geometry.location.lng,
            };
          }
        }
      }

      const { data } = await getNearestBranches(this.$axios, params.query);
      branchesResult = data;
      commit("setNearestBranches", branchesResult);

      return branchesResult;
    } catch (error) {
      this.$log.error(error);
      return [];
    } finally {
      // If no data: Save the latest location postcode to show an error banner
      await dispatch("checkAndSetStoreNotFoundByLocationPostcode", {
        result: branchesResult,
        postcode: searchTerm,
      });
    }
  },

  checkAndSetStoreNotFoundByLocationPostcode(
    { commit },
    { result, postcode }: { result: Branch[]; postcode: string }
  ): void {
    if (result?.length > 0) {
      commit("resetStoreNotFoundByLocationPostcode");
    } else {
      commit("setStoreNotFoundByLocationPostcode", postcode);
    }
  },

  async getPostcodeAutocompleteData(
    { commit, state }: { commit: Commit; state: BranchState },
    { query, country }
  ): Promise<Location[] | null> {
    let result: Location[] | null = [];
    try {
      const { localities } = await getPostcodeAutocompleteData(
        state.postcodeApiKey,
        query,
        country
      );
      if (localities != null) {
        result = localities;
      }
      commit("setPostcodes", result);
    } catch (error) {
      this.$log.error(error);
    }

    return result;
  },

  async updateSelectedBranch(
    { commit },
    {
      customerId,
      siteId,
    }: {
      customerId: string;
      siteId: string;
    }
  ): Promise<void> {
    try {
      await updateSelectedBranch(this.$axios, customerId, siteId);
      commit("setNearestBranches", []);
      commit("setPostcodes", []);
    } catch (error) {
      this.$log.error(error);
    }
  },

  openBranchModal({ commit }, alsoShowInStockForProduct?: string): void {
    commit("setShowStockFor", alsoShowInStockForProduct ?? null);
    commit("setShowBranchModal", true);
  },
  closeBranchModal({ commit }): void {
    commit("setShowStockFor", null);
    commit("setShowBranchModal", false);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
