import { identity } from "ramda";
import { Result } from "./index.d";

export const isErr = <TResult, E>(
  res: Result<TResult, E | null>
): res is Result<TResult, E> => res.err !== null;
export const isOk = <TResult, E>(
  res: Result<TResult, E | null>
): res is Result<TResult, null> => res.err === null;

export const is422 = <TResult, E extends AxiosResponse>(
  res: Result<TResult, E | AxiosResponse>
): res is Result<TResult, E> => res.err.status === 422;

export const wrapError = <TResult, E>(p: Promise<AxiosResponse>) =>
  p.catch(identity).then(
    (response: AxiosResponse) =>
      ({
        ok: response.status >= 200 && response.status <= 299 ? response : null,
        err: response.status > 400 ? response : null,
      }) as Result<TResult | null, E | null>
  );
