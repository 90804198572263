import btoa from "btoa";
import { NuxtAxiosInstance } from "@nuxtjs/axios";
import {
  AccessTokenApiResponse,
  AccessTokenOptions,
} from "~/types/accessToken";

export const fetchAccessToken = async (
  $axios: NuxtAxiosInstance,
  accessTokenOptions: AccessTokenOptions
): Promise<AxiosResponse<AccessTokenApiResponse>> => {
  const { clientId, clientSecret, api } = accessTokenOptions;
  const authBasic = btoa(`${clientId}:${clientSecret}`);
  const url = `${api}/oauth2/v1/accesstoken?grant_type=client_credentials`;

  const requestOptions: AxiosRequestConfig = {
    method: "post",
    url,
    headers: {
      Authorization: `Basic ${authBasic}`,
    },
  };

  // The access token endpoint doesn't use the same base url as others so we're using request()
  return await $axios.request(requestOptions);
};
