/**
 * NOTE: A Slug is the unique identifying part of a web address,
 * typically at the end of the URL. In the context of MDN, it is the portion of the URL following "<locale>/docs/"
 * source https://developer.mozilla.org/en-US/docs/Glossary/Slug
 */
function stringToSlug(text: string): string {
  let slug = text
    .trim()
    .normalize("NFD") // The "NFD" method splits graphemes from their base character
    .replace(/\p{Diacritic}/gu, "") // Performs a unicode regex replace stripping any instances of diacritics, which you'd split out in the line above.
    .toLowerCase();

  // remove invalid chars, collapse whitespace & dashes
  slug = slug.replace(/[^a-z0-9 -]/g, "").replace(/[-\s]+/g, "-");

  return slug;
}

export default stringToSlug;
