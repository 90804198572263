import { NuxtAxiosInstance } from "@nuxtjs/axios";
import {
  GetProductStockPayload,
  ApiProductStockResponse,
} from "~/services/api/stock.api.d";

const ENDPOINTS = {
  STOCK: `/stock`,
  GET_STOCK: `/stock/getStock`,
};

export const getStock = async (
  $axios: NuxtAxiosInstance
): Promise<AxiosResponse<any>> => await $axios.$get(ENDPOINTS.STOCK);

export const getAllStock = async (
  $axios: NuxtAxiosInstance,
  data: any
): Promise<AxiosResponse<any>> => await $axios.$post(ENDPOINTS.GET_STOCK, data);

export const getProductStock = async (
  $axios: NuxtAxiosInstance,
  data: GetProductStockPayload
): Promise<ApiProductStockResponse> =>
  await $axios.$post(ENDPOINTS.GET_STOCK, data);
