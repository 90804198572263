export const timeNowInSeconds = (): number => Date.now() / 1000;

const formatDate =
  (format: Intl.DateTimeFormatOptions) => (date: string, locale: string) =>
    Intl.DateTimeFormat(locale, format).format(new Date(date));

export const publishDate = formatDate({
  month: "short",
  day: "numeric",
  year: "numeric",
});

export const lastCatalogueRequestDate = formatDate({
  weekday: "short",
  month: "short",
  day: "numeric",
  year: "numeric",
});

export const timestampToISO = (val: string): string => {
  return new Date(val).toISOString();
};
