<template functional>
  <svg viewBox="0 0 24 24" fill="none" :class="props.classes">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M4.38462 5C3.61962 5 3 5.60413 3 6.35V7.025H21V6.35C21 5.60413 20.3804 5 19.6154 5H4.38462ZM3 9.05V17.15C3 17.8959 3.61962 18.5 4.38462 18.5H19.6154C20.3804 18.5 21 17.8959 21 17.15V9.05H3ZM10.6153 10.4H5.07686V11.075H10.6153V10.4Z"
      fill="currentColor"
    />
  </svg>
</template>

<script>
export default {
  name: "CardSolidIcon",
  props: {
    classes: {
      type: String,
      required: false,
      default: "",
    },
  },
};
</script>
