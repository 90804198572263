var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('BaseCarousel',{key:_vm.recommendationData.data.length,class:{ 'version-2': _vm.isVersion('two') },attrs:{"settings":_vm.carouselSetting},scopedSlots:_vm._u([{key:"slides",fn:function(){return _vm._l((_vm.recommendationData.data),function(recommendation,index){return _c('div',{key:index},[(_vm.isLoading)?_c('LoadingCard'):_c(_vm.cardType,{tag:"component",attrs:{"image-url":recommendation.thumbImage,"title":recommendation.title,"reviews":recommendation.tsReviews || 0,"gross-price":_vm.getPrice(recommendation, 'gross'),"net-price":_vm.getPrice(recommendation, 'net'),"saving-value":_vm.getSavingValue(recommendation),"product":recommendation,"show-add-to-trolley":_vm.showAddToTrolley,"cta":_vm.cta,"show-flash":_vm.showFlash,"web-overlay-text":recommendation.weboverlaytext,"badge-icons":_vm.showBadges
              ? {
                  brand: {
                    imageUrl: _vm.getBadgeUrl(
                      recommendation.brand || '',
                      'brand'
                    ),
                    altText: recommendation.brand || '',
                  },
                  badge: {
                    imageUrl: _vm.getBadgeUrl(
                      recommendation.assettr || '',
                      'badge'
                    ),
                    altText: recommendation.brand || '',
                  },
                }
              : {}},on:{"trackClickPixel":_vm.trackClick}})],1)})},proxy:true},{key:"backArrow",fn:function(){return [(_vm.isVersion('one'))?_c('ChevronIcon',{attrs:{"classes":"w-10 text-black-100","direction":"left"}}):_c('span',{staticClass:"navigation-btn-wrapper"},[_c('img',{staticClass:"-rotate-90 -ml-1",attrs:{"src":require("assets/svg/up-arrow.svg"),"alt":"right-arrow"}})])]},proxy:true},{key:"nextArrow",fn:function(){return [(_vm.isVersion('one'))?_c('ChevronIcon',{attrs:{"classes":"w-10 text-black-100","direction":"right"}}):_vm._e(),_vm._v(" "),(_vm.isVersion('two') && _vm.showNextBtn)?_c('span',{staticClass:"navigation-btn-wrapper"},[_c('img',{staticClass:"rotate-90 -mr-1",attrs:{"src":require("assets/svg/up-arrow.svg"),"alt":"right-arrow"}})]):_vm._e()]},proxy:true}])})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }