<template>
  <a
    v-if="isExternal"
    :class="classes"
    :href="url"
    v-bind="$attrs"
    @click="emit('clickAction', $event)"
  >
    <slot />
  </a>
  <NuxtLink
    v-else
    v-bind="$attrs"
    :to="url"
    :class="classes"
    @click="emit('clickAction', $event)"
  >
    <slot />
  </NuxtLink>
</template>

<script>
export default {
  name: "SmartLink",
  props: {
    url: {
      type: String,
      required: true,
    },
    classes: {
      type: String,
      default: "",
    },
  },
  computed: {
    isExternal() {
      return this.url.startsWith("http") || this.url.startsWith("mailto");
    },
  },
};
</script>
