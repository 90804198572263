export default {
  departments: {
    path: "#",
    translationKey: "header.mainNav.departments",
  },
  menu: {
    path: "#",
    translationKey: "header.mainNav.menu",
  },
  topTradeBrands: {
    path: "#",
    translationKey: "header.mainNav.topTradeBrands",
  },
  topBrands: {
    path: "#",
    translationKey: "header.mainNav.topBrands",
  },
  newBrands: {
    path: "#",
    translationKey: "header.mainNav.newBrands",
  },
  tradeBrandsViewAll: {
    path: "/brands",
    translationKey: "header.mainNav.viewAll",
  },
  mobileBranchLocator: {
    path: "/branches",
    translationKey: "header.mainNav.branches",
  },
  branchLocator: {
    path: "/branches",
    translationKey: "header.topNav.setBranch",
    translationKeyLarge: "header.mainNav.branchLocator",
  },
  myAccount: {
    path: "/account/home",
    translationKey: "header.mainNav.myAccountLarge",
    translationKeyLarge: "myAccountLarge",
  },
  signIn: {
    path: "/login?redirectTo=/account/home",
    translationKey: "header.mainNav.signIn",
    translationKeyLarge: "header.mainNav.signIn",
  },
  trolley: {
    path: "/trolley",
    translationKey: "header.mainNav.trolley",
  },
};
