<template functional>
  <svg
    :class="props.classes"
    width="19"
    height="24"
    viewBox="0 0 19 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M16.2581 3.87109H16.1265V5.41948H16.2581C16.4634 5.41948 16.6603 5.50105 16.8055 5.64624C16.9507 5.79143 17.0323 5.98835 17.0323 6.19367V21.6775C17.0323 21.8829 16.9507 22.0798 16.8055 22.225C16.6603 22.3702 16.4634 22.4517 16.2581 22.4517H2.32258C2.11725 22.4517 1.92033 22.3702 1.77514 22.225C1.62995 22.0798 1.54839 21.8829 1.54839 21.6775V6.19367C1.54839 5.98835 1.62995 5.79143 1.77514 5.64624C1.92033 5.50105 2.11725 5.41948 2.32258 5.41948H2.45419V3.87109H2.32258C1.70659 3.87109 1.11584 4.11579 0.680268 4.55136C0.2447 4.98693 0 5.57769 0 6.19367V21.6775C0 22.2935 0.2447 22.8843 0.680268 23.3199C1.11584 23.7554 1.70659 24.0001 2.32258 24.0001H16.2581C16.8741 24.0001 17.4648 23.7554 17.9004 23.3199C18.3359 22.8843 18.5806 22.2935 18.5806 21.6775V6.19367C18.5806 5.57769 18.3359 4.98693 17.9004 4.55136C17.4648 4.11579 16.8741 3.87109 16.2581 3.87109Z"
      fill="#065CAB"
    />
    <path
      d="M14.7096 2.32258H12.387V0H6.19343V2.32258H3.87085V6.96774H14.7096V2.32258ZM13.1612 5.41935H5.41924V3.87097H7.74182V1.54839H10.8386V3.87097H13.1612V5.41935Z"
      fill="#065CAB"
    />
    <path
      d="M4.64526 10.0645H13.9356V11.6128H4.64526V10.0645ZM4.64526 13.9354H13.9356V15.4838H4.64526V13.9354ZM4.64526 17.8064H13.9356V19.3548H4.64526V17.8064Z"
      fill="#065CAB"
    />
  </svg>
</template>

<script>
export default {
  name: "ClipboardIcon",
  props: {
    classes: {
      type: String,
      required: false,
      default: "",
    },
  },
};
</script>
