import { DeliveryMethodCodes } from "~/constants/ecomApi";
import { TrolleyChannel } from "~/services/trolleys.service";

export const getDefaultDeliveryCode = (
  channel: TrolleyChannel
): DeliveryMethodCodes => {
  switch (channel) {
    case TrolleyChannel.COLLECTION:
      return DeliveryMethodCodes.Collection;
    case TrolleyChannel.DELIVERY:
      return DeliveryMethodCodes.Delivery;
    case TrolleyChannel.DIRECTSHIP:
      return DeliveryMethodCodes.Directship;
    case TrolleyChannel.NEXT_DAY_COLLECTION:
      return DeliveryMethodCodes.NextDayCollection;
    case TrolleyChannel.SAME_DAY_DELIVERY:
      return DeliveryMethodCodes.SameDayDelivery;
    default:
      return DeliveryMethodCodes.Delivery;
  }
};
