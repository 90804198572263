
import TsSavings from "~/components/UI/icons/TsSavings.vue";
import TsClub from "~/components/UI/icons/TsClub.vue";

export default {
  name: "PromptMessage",
  components: { TsSavings, TsClub },
  props: {
    icon: {
      type: String as () => "TsSavings" | "TsClub" | null,
      required: false,
      default: null,
    },
    message: {
      type: String,
      required: true,
    },
  },
};
