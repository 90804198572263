<template>
  <transition name="fadeHeight">
    <li v-show="show" class="border-grey-3 border-t first:border-t-0">
      <div
        class="flex items-center relative cursor-pointer text-size-4 leading-none px-4"
        :class="itemClasses"
      >
        <component
          :is="slug ? 'SmartLink' : 'div'"
          :url="slug"
          class="flex items-center gap-4 flex-1 py-4"
        >
          <slot />
        </component>

        <div
          v-if="showArrow"
          :tabindex="hasChildren ? '0' : '-1'"
          :role="hasChildren ? 'button' : 'none'"
          :aria-label="hasChildren ? $t('expandMenuItem') : ''"
          @click="$emit('toggleChildren')"
          @keypress="
            $a11yKeyEnter($event, () =>
              hasChildren ? $emit('toggleChildren') : null
            )
          "
        >
          <ChevronIcon
            classes="w-6 h-6"
            :direction="hasChildren && isActive ? 'down' : 'right'"
          />
        </div>
      </div>

      <slot name="children" />
    </li>
  </transition>
</template>

<script>
import { ChevronIcon } from "~/components/UI/icons";
import SmartLink from "~/components/UI/links/SmartLink.vue";

export default {
  name: "MenuItem",
  components: {
    ChevronIcon,
    SmartLink,
  },
  props: {
    classes: {
      type: String,
      default: "",
    },
    isActive: {
      type: Boolean,
      default: false,
      required: true,
    },
    showArrow: {
      type: Boolean,
      default: true,
    },
    slug: {
      type: String,
      required: false,
      default: "",
    },
    isChildItem: {
      type: Boolean,
      required: false,
      default: false,
    },
    show: {
      type: Boolean,
      required: false,
      default: true,
    },
  },
  computed: {
    itemClasses() {
      const classes = this.isChildItem
        ? "bg-blue-10 hover:bg-white text-blue !pl-8 ease-in-out duration-150"
        : this.isActive
          ? "font-bold bg-blue hover:bg-blue text-white"
          : "bg-white hover:bg-grey-1 text-blue ease-in-out duration-150";

      return classes.concat(" " + this.classes);
    },
    hasChildren() {
      return !!this.$slots?.children?.[0];
    },
  },
};
</script>

<style scoped>
.fadeHeight-enter-active,
.fadeHeight-leave-active {
  transition: all 0.2s;
  max-height: 250px;
}
.fadeHeight-enter,
.fadeHeight-leave-to {
  opacity: 0;
  max-height: 0px;
}
</style>
