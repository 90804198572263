import { render, staticRenderFns } from "./ClipboardIcon.vue?vue&type=template&id=3e76e386&functional=true"
import script from "./ClipboardIcon.vue?vue&type=script&lang=js"
export * from "./ClipboardIcon.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  true,
  null,
  null,
  null
  
)

export default component.exports