import { NuxtAxiosInstance } from "@nuxtjs/axios";
import constants from "~/constants/ecomApi";

export const companySearch = async (
  $axios: NuxtAxiosInstance,
  registrationNumber: string,
  companyName: string,
  countryCode: string
): Promise<any> => {
  const url = constants.PROCARD_COMPANY_SEARCH;
  const params = new URLSearchParams();

  params.append("countryISOAlpha2Code", countryCode);
  if (registrationNumber !== "")
    params.append("registrationNumber", registrationNumber);
  if (companyName !== "") params.append("name", companyName);

  return await $axios.$get(`${url}?${params.toString()}`);
};

export const banksList = async ($axios: NuxtAxiosInstance): Promise<any> =>
  await $axios.$get(constants.PROCARD_BANKS);

export default { companySearch };
