
import Vue from "vue";
import { mapGetters } from "vuex";
import dayjs from "dayjs";
import SiteMessage from "~/components/UI/alerts/site-messages/SiteMessage.vue";

interface SiteMessageType {
  id: string;
  styleType: { selectionValues: { label: string }[] };
  title: string;
  body: { value: string };
  persistent: boolean;
}

export default Vue.extend({
  name: "SiteMessages",
  components: { SiteMessage },
  data: () => ({
    siteMessages: [],
  }),
  computed: {
    ...mapGetters("siteMessages", ["getMessages", "messagesSet"]),
  },
  watch: {
    $route: {
      handler() {
        this.getSiteMessages();
      },
      immediate: true,
    },
  },
  mounted() {
    this.fetchSiteMessages();
  },
  methods: {
    async fetchSiteMessages(): Promise<void> {
      if (this.messagesSet) return;

      // Fetch and store site messages
      await this.$store.dispatch(
        "siteMessages/fetchSiteMessages",
        this.$config.brxmContentEndpoint
      );

      this.getSiteMessages();
    },
    getSiteMessages(): void {
      const isErrorLayout = (this as any).$nuxt.context._errored;
      if (isErrorLayout) return;

      this.siteMessages = this.getMessages(this.$route).filter(
        (message: SiteMessageType) =>
          !this.getSeenMessageIds().includes(message.id)
      );
    },
    closeSiteMessage(messageId: string, styleType: string) {
      // Remove message from component data
      this.siteMessages = this.siteMessages.filter(
        (item: SiteMessageType) => item.id !== messageId
      );

      // Get seen message id's from cookie
      const seenMessageIds = this.getSeenMessageIds(styleType);

      // If message ID id not stored in cookie, add it
      if (!seenMessageIds.includes(messageId)) {
        seenMessageIds.push(messageId);

        this.setSiteMessageInStorage(JSON.stringify(seenMessageIds), styleType);
      }
    },

    setSiteMessageInStorage(data: string, styleType: string) {
      const expirationTime =
        styleType === "warning" ? undefined : dayjs().add(1, "week").toDate();

      const cookieName =
        styleType === "warning" ? "seenWarningMessageIds" : "seenMessageIds";

      this.$cookies.set(cookieName, data, {
        path: "/",
        sameSite: "lax",
        expires: expirationTime,
        secure: true,
      });
    },

    getSeenMessageIds(styleType: string = "all") {
      switch (styleType) {
        case "all":
          return [
            ...(this.$cookies.get("seenMessageIds") ?? []),
            ...(this.$cookies.get("seenWarningMessageIds") ?? []),
          ];
        case "warning":
          return this.$cookies.get("seenWarningMessageIds") ?? [];

        default:
          return this.$cookies.get("seenMessageIds") ?? [];
      }
    },
  },
});
