<template functional>
  <svg viewBox="0 0 24 24" fill="none" :class="props.classes">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M18.3743 9.16955L20.0852 7.45871C20.6387 6.90521 20.6387 6.00797 20.0852 5.45446L18.5458 3.91513C17.9923 3.36162 17.0951 3.36162 16.5416 3.91513L14.8307 5.62597L18.3743 9.16955ZM13.4133 7.0434L5.093 15.3637C6.22999 15.2282 6.18142 15.8384 6.12826 16.5061C6.09441 16.9313 6.05869 17.3799 6.32616 17.6741C6.56017 17.9314 6.98711 17.9122 7.41178 17.8931C8.08549 17.8628 8.75351 17.8327 8.63658 18.9073L16.9569 10.587L13.4133 7.0434ZM4.42178 20.4644L4.43241 20.4693L6.3261 19.8003L4.19995 17.6742L3.53092 19.5678L3.53588 19.5785C3.33957 20.2766 3.99301 20.616 4.42178 20.4644Z"
      fill="currentColor"
    />
  </svg>
</template>

<script>
export default {
  name: "PencilIcon",
  props: {
    classes: {
      type: String,
      required: false,
      default: "",
    },
  },
};
</script>
