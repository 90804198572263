import { Context } from "@nuxt/types";

export default (_: Context, inject: Function): void => {
  inject("log", {
    log: console.log,
    error: console.error,
    warn: console.warn,
    info: console.info,
    debug: console.debug,
  });
};
