
import { mapMutations } from "vuex";
import { MetaInfo } from "vue-meta";
import {
  android,
  ios,
  isAllowed,
  iosConfig,
  androidConfig,
} from "~/utils/config/mobile-app-config";
import { TrackingEvents } from "~/store/tracking";

import browsingPageMetas from "~/mixins/browsingPageMetas";
import { fromToolstationCdnUrl } from "~/utils/fromToolstationCdn";

interface Meta {
  name: string;
  content: string;
  hid: string;
}

enum SmartBannerEvents {
  View = "smartbanner.view",
  Click = "smartbanner.clickout",
  Close = "smartbanner.exit",
}

export default browsingPageMetas.extend({
  head(): MetaInfo {
    return {
      meta: [...this.androidMetas, ...this.iosMetas],
    };
  },
  computed: {
    androidMetas(): Meta[] {
      return this.isAllowed(android)
        ? [
            {
              hid: "smartbanner:disable-positioning",
              name: "smartbanner:disable-positioning",
              content: "true",
            },
            {
              hid: "smartbanner:title",
              name: "smartbanner:title",
              content: this.$tc("smartBanner.android.title"),
            },
            {
              hid: "smartbanner:author",
              name: "smartbanner:author",
              content: this.$tc("smartBanner.android.author"),
            },
            {
              hid: "smartbanner:price",
              name: "smartbanner:price",
              content: this.$tc("smartBanner.android.price"),
            },
            {
              hid: "smartbanner:price-suffix-google",
              name: "smartbanner:price-suffix-google",
              content: this.$tc("smartBanner.android.priceSuffix"),
            },
            {
              hid: "smartbanner:icon-google",
              name: "smartbanner:icon-google",
              content: fromToolstationCdnUrl(
                "ts-images/banner-icon.png",
                this.$config
              ),
            },
            {
              hid: "smartbanner:button",
              name: "smartbanner:button",
              content: this.$tc("smartBanner.android.button"),
            },
            {
              hid: "smartbanner:button-url-google",
              name: "smartbanner:button-url-google",
              content: android.playStoreUrl,
            },
            {
              hid: "smartbanner:enabled-platforms",
              name: "smartbanner:enabled-platforms",
              content: "android",
            },
            {
              hid: "smartbanner:close-label",
              name: "smartbanner:close-label",
              content: this.$tc("smartBanner.android.close"),
            },
          ]
        : [];
    },
    iosMetas(): Meta[] {
      return this.isAllowed(ios)
        ? [
            {
              hid: "apple-itunes-app",
              name: "apple-itunes-app",
              content: `app-id=${ios.id}, app-argument=${this.getPageUrl()}`,
            },
          ]
        : [];
    },
  },
  methods: {
    ...mapMutations("tracking", ["addTrackingEvent"]),
    isAllowed(config: androidConfig | iosConfig): boolean {
      return isAllowed(this.$i18n.locale, config);
    },
    trackView() {
      document.addEventListener(SmartBannerEvents.Click, this.trackClick);
      document.addEventListener(SmartBannerEvents.Close, this.trackClose);

      this.addTrackingEvent(TrackingEvents.SmartBannerVisible);
    },
    trackClick() {
      this.addTrackingEvent(TrackingEvents.SmartBannerClick);
    },
    trackClose() {
      document.removeEventListener(SmartBannerEvents.View, this.trackView);
      document.removeEventListener(SmartBannerEvents.Click, this.trackClick);
      document.removeEventListener(SmartBannerEvents.Close, this.trackClose);
      this.addTrackingEvent(TrackingEvents.SmartBannerClose);
    },
  },
  mounted() {
    document.addEventListener(SmartBannerEvents.View, this.trackView);
  },
});
