
import Vue from "vue";
import BaseLayout from "~/layouts/baseLayout.vue";
import SiteHeader from "~/components/layout/header/TheHeader.vue";

export default Vue.extend({
  name: "DefaultLayout",
  components: {
    BaseLayout,
    SiteHeader,
  },
  layout: "default",
});
