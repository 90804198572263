export const handleError = (error: any): null => {
  if (error?.status === 404) {
    if (process.client) {
      // only log 404 errors on the client
      console.warn("404 Error:", error);
      return null;
    }
    return null;
  } else {
    console.error(error);
  }

  return null;
};

export const strippedContent = (html: string): string => {
  const regex = /(<([^>]+)>)/gi;
  return html.replace(regex, "");
};

export default { handleError, strippedContent };
