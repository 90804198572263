import { isNil } from "ramda";
import { GetterTree, MutationTree } from "vuex";
import { RootState } from ".";
import { ProductAttribute } from "~/constants/attributes/productAttributes";
import { getValueFromAttributeId } from "~/lib/product";

export enum ProductMutation {
  SetProduct = "setProduct",
  SetProductQty = "setProductQty",
}

interface ProductState {
  product: any;
  metaData: any;
  productQty: number;
}

export const state = (): ProductState => ({
  product: null,
  metaData: null,
  productQty: 1,
});

export const mutations: MutationTree<ProductState> = {
  [ProductMutation.SetProduct]: (state: ProductState, product) => {
    state.product = product;
    state.metaData = {
      title: product?.name ?? "Product not found",
      meta: [
        {
          hid: "description",
          name: "description",
          content: product?.description ?? "Product not found",
        },
        {
          hid: "og:description",
          property: "og:description",
          content: product?.description ?? "Product not found",
        },
        {
          hid: "og:title",
          property: "og:title",
          content: product?.name ?? "Product not found",
        },
        {
          hid: "og:image",
          property: "og:image",
          content: product?.image ?? "Product not found",
        },
        {
          hid: "og:url",
          property: "og:url",
          content: product?.url ?? "Product not found",
        },
        {
          hid: "twitter:title",
          name: "twitter:title",
          content: product?.name ?? "Product not found",
        },
        {
          hid: "twitter:description",
          name: "twitter:description",
          content: product?.description ?? "Product not found",
        },
        {
          hid: "twitter:image",
          name: "twitter:image",
          content: product?.image ?? "Product not found",
        },
        {
          hid: "twitter:url",
          name: "twitter:url",
          content: product?.url ?? "Product not found",
        },
      ],
    };
  },
  [ProductMutation.SetProductQty]: (state: ProductState, qty: number) => {
    state.productQty = qty;
  },
};

export const getters: GetterTree<ProductState, RootState> = {
  getProduct: (state: ProductState) => state.product,
  getProductMeta: (state: ProductState) => state.metaData,
  getProductAttributes: (state: ProductState) => [
    ...(state.product?.otherAttributes ?? []),
    ...(state.product?.technicalSpecAttributes ?? []),
    ...(state.product?.documentsAndManualsAttributes ?? []),
  ],
  getProductQty: (state: ProductState) => state.productQty,
  isKitchenKitProduct: (_state: ProductState, getters) =>
    !isNil(
      getValueFromAttributeId(
        ProductAttribute.KitchenKit,
        getters.getProductAttributes
      )
    ),
  isQtyLabelInMeters: (_state: ProductState, getters) => {
    const value = getValueFromAttributeId(
      ProductAttribute.QuantityLabel,
      getters.getProductAttributes
    );

    return value === "lengthm";
  },
};

export default {
  state,
  getters,
  mutations,
};
