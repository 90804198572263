<template functional>
  <svg
    viewBox="0 0 31 35"
    :class="props.classes"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M0.340039 0.0195312L30.34 17.34L0.340039 34.6605L0.340039 0.0195312Z"
      fill="currentColor"
    />
  </svg>
</template>

<script>
export default {
  name: "Triangle",
  props: {
    classes: {
      type: String,
      required: false,
      default: "",
    },
  },
};
</script>
