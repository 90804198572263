
import Vue, { PropType } from "vue";
import ProgressCircle from "./circle.vue";
import { Step, StepStatus } from ".";

export default Vue.extend({
  components: { ProgressCircle },
  props: {
    steps: {
      type: [] as PropType<Step[]>,
      default: () => [],
    },
    showNumbers: {
      type: Boolean as PropType<boolean>,
      default: false,
    },
    label: {
      type: String,
      required: false,
      default: "Checkout Progress",
    },
    isCenter: {
      type: Boolean,
      required: false,
      default: false,
    },
    lastStepAsTick: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  computed: {
    hasTitle() {
      return this.steps.some((step) => step.title);
    },
    currentStep() {
      const stepIdx = this.steps.findIndex((step) => {
        if (Array.isArray(step.path)) {
          return step.path.includes(this.$route.path);
        }
        return step.path === this.$route.path;
      });

      return stepIdx === -1 ? 9999 : stepIdx;
    },
    progressSteps() {
      return this.steps.map((step, index) => {
        // @ts-ignore
        const status = this.getStatus(index);
        return {
          ...step,
          status,
        };
      });
    },
  },
  methods: {
    getStatus(stepIdx: number): StepStatus {
      if (this.currentStep > stepIdx) return "completed";
      if (this.currentStep === stepIdx) {
        // treat last step as a tick
        if (this.lastStepAsTick) {
          if (this.currentStep === this.steps.length - 1) return "completed";
        }

        return "current";
      }

      return "incomplete";
    },
  },
});
