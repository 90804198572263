import { env } from "process";

const requiredVars = ["NUXT_ENV_DEPLOYMENT_TARGET"];

export const hasRequiredVars = (env: any): void => {
  requiredVars.forEach((key: string) => {
    if (env[key] === undefined || env[key] === "") {
      throw new Error(`Environment variable '${key}' is required.`);
    }
  });
};

export const checkDeploymentEnvironmentName = (
  nameStartsWith: string
): boolean => {
  return env.NUXT_ENV_DEPLOYMENT_TARGET.toLowerCase().startsWith(
    nameStartsWith
  );
};

export const isDevelopment = (): boolean =>
  checkDeploymentEnvironmentName("dev");
export const isPreProduction = (): boolean =>
  checkDeploymentEnvironmentName("pre");
export const isProduction = (): boolean =>
  checkDeploymentEnvironmentName("prod");
export const allowAuthCookieByQuery = (): boolean =>
  !(isProduction() || isPreProduction());

export default {
  allowAuthCookieByQuery,
  isDevelopment,
  isPreProduction,
  isProduction,
  hasRequiredVars,
  requiredVars,
};
