import type { Context } from "@nuxt/types";
import { isEmpty } from "ramda";

export enum ClubPromptLocations {
  Default = "default",
  CheckoutReview = "checkoutReview",
  AddToTrolleyModal = "addToTrolleyModal",
}

interface ClubPrompt {
  message: any;
  show: boolean;
}

/**
 * @description Returns Toolstation club prompt messaging
 */
export const clubPrompt = (
  context: Context,
  location: ClubPromptLocations = ClubPromptLocations.Default
): ClubPrompt => {
  const {
    "auth/getUserSpendStats": userSpendStats,
    "auth/isLoyaltyClubAccount": isLoyaltyClubAccount,
    "auth/loyaltyClubDiscountMessagingIsActive":
      loyaltyClubDiscountMessagingIsActive,
    "auth/loyaltyClubDiscountIsActive": loyaltyClubDiscountIsActive,
    "trolley/clubDiscountSavings": clubDiscountSavings,
    "trolley/hasLineItems": hasLineItems,
    "trolley/trolleyDiscountInPounds": trolleyDiscountInPounds,
    "trolley/isClubDiscount": isClubDiscount,
  } = context.store.getters;

  if (isEmpty(userSpendStats) || !userSpendStats)
    return { message: "", show: false };

  const {
    spendRequirements: {
      standard: { needToSpend, daysLeftToQualify, discount },
    },
  } = userSpendStats;

  const loyaltyMemberMessage = () => {
    const remainingSpendTranslationKey = loyaltyClubDiscountIsActive
      ? "account.club.prompt.remainingSpend"
      : "account.club.prompt.remainingSpendNextMonth";

    const clubSavingsTargetTranslationKey = loyaltyClubDiscountIsActive
      ? "account.club.prompt.clubSavingsTargetMetMaintain"
      : "account.club.prompt.clubSavingsTargetMetAttain";

    return Number(needToSpend) > 0
      ? context.i18n.t(remainingSpendTranslationKey, {
          remainingBalance: formatClubSpend(needToSpend),
          days: daysLeftToQualify,
          discount,
        })
      : context.i18n.t(clubSavingsTargetTranslationKey, {
          discount,
        });
  };

  const nonLoyaltyMemberMessage = () => {
    return context.i18n.t("account.club.prompt.clubSavingsSignUp", {
      savings: clubDiscountSavings,
    });
  };

  if (location === ClubPromptLocations.CheckoutReview) {
    return {
      show:
        loyaltyClubDiscountMessagingIsActive &&
        loyaltyClubDiscountIsActive &&
        isClubDiscount &&
        isLoyaltyClubAccount,
      message: context.i18n.t("account.club.prompt.clubSavings", {
        savings: trolleyDiscountInPounds,
      }),
    };
  }

  if (location === ClubPromptLocations.AddToTrolleyModal) {
    return {
      show:
        loyaltyClubDiscountMessagingIsActive &&
        hasLineItems &&
        isLoyaltyClubAccount &&
        Number(needToSpend) > 0,
      message: isLoyaltyClubAccount
        ? loyaltyMemberMessage()
        : nonLoyaltyMemberMessage(),
    };
  }

  return {
    show:
      loyaltyClubDiscountMessagingIsActive &&
      hasLineItems &&
      isLoyaltyClubAccount,
    message: isLoyaltyClubAccount
      ? loyaltyMemberMessage()
      : nonLoyaltyMemberMessage(),
  };
};

/**
 * @description Returns Toolstation club sign up banner messaging
 */
export const clubSignUp = (context: Context, orderTotal: number) => {
  const {
    "auth/isTradeAccount": isTradeAccount,
    "auth/getUserSpendStats": userSpendStats,
    "auth/isLoyaltyClubAccount": isLoyaltyClubAccount,
    "auth/loyaltyClubDiscountIsActive": loyaltyClubDiscountIsActive,
    "trolley/clubDiscountSavings": clubDiscountSavings,
    "trolley/isSubTotalAboveLimit": isSubTotalAboveLimit,
  } = context.store.getters;

  if (isEmpty(userSpendStats) || !userSpendStats)
    return { message: "", show: false };

  const {
    spendRequirements: {
      standard: { needToSpend, daysLeftToQualify, discount },
    },
  } = userSpendStats;

  const orderTotalIsAboveTarget = orderTotal >= needToSpend;

  const notLoyaltyIsAboveLimitNotTrade =
    isSubTotalAboveLimit && !isTradeAccount && !isLoyaltyClubAccount;

  const isLoyaltyNoClubDiscountNotTradeIsAboveTarget =
    isLoyaltyClubAccount &&
    !isTradeAccount &&
    !loyaltyClubDiscountIsActive &&
    !orderTotalIsAboveTarget;

  const message = notLoyaltyIsAboveLimitNotTrade
    ? context.i18n.t("account.club.signUpBanner.couldHaveSaved", {
        savings: clubDiscountSavings,
      })
    : context.i18n.t("account.club.prompt.remainingSpendNextMonth", {
        remainingBalance: formatClubSpend(needToSpend - orderTotal),
        days: daysLeftToQualify,
        discount,
      });

  return {
    show:
      notLoyaltyIsAboveLimitNotTrade ||
      isLoyaltyNoClubDiscountNotTradeIsAboveTarget,
    message,
    bottomMessage: isLoyaltyNoClubDiscountNotTradeIsAboveTarget
      ? context.i18n.t("account.club.signUpBanner.timeDisclaimer")
      : "",
    showCta: !isLoyaltyNoClubDiscountNotTradeIsAboveTarget,
  };
};

/**
 * @description Formats Club spend, if whole number then no decimal place, else 2 decimal places
 */
export const formatClubSpend = (input: string | number): string => {
  const number = Number(input);
  return number.toFixed(Number.isSafeInteger(number) ? 0 : 2);
};
