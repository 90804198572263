var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return (_vm.pageData)?_c('div',[_c('div',{staticClass:"bg-white"},[_c('div',{staticClass:"content-container flex flex-col items-center gap-4 md:gap-8 py-10 md:py-24"},[_c('h1',{staticClass:"font-bold text-size-7.5 md:text-size-9 text-blue text-left md:text-center"},[_vm._v("\n        "+_vm._s(_vm.pageData.pageTitle)+"\n      ")]),_vm._v(" "),_c('p',{staticClass:"text-size-4 md:text-size-7 text-left md:text-center"},[_vm._v("\n        "+_vm._s(_vm.pageData.pageSubTitle)+"\n      ")]),_vm._v(" "),_c('LoadingSpinner',{directives:[{name:"show",rawName:"v-show",value:(!_vm.initialUpdate),expression:"!initialUpdate"}],staticClass:"relative min-h-[200px]",attrs:{"size":"xxl"}}),_vm._v(" "),_c('client-only',[_c('MultiTextAndImage',{staticClass:"mt-4 md:mt-0",attrs:{"data":(_vm.pageData.titleAndImage &&
              _vm.pageData.titleAndImage.titleAndImageItem) ||
            [],"style-type":"version 4","height":"extra small","items-per-row-desktop":6,"items-per-row-tablet":4,"items-per-row-mobile":2,"show-view-more-button":true,"collapsed-item-count":{ tablet: 12, mobile: 6 },"button-text":{
            collapsed: _vm.pageData.collapsedButtonText,
            expanded: _vm.pageData.expandedButtonText,
          }}})],1)],1)]),_vm._v(" "),_c('div',{staticClass:"bg-grey-2"},[_c('div',{staticClass:"content-container py-10 md:py-24"},[(_vm.pathwaysDataLength)?_c('ProductRecommendations',{attrs:{"data":_vm.recommendations,"style-type":"version 2","slides-per-page":{
          desktop: _vm.pathwaysDataLength >= 6 ? 6 : _vm.pathwaysDataLength,
          tablet: _vm.pathwaysDataLength >= 4 ? 4 : _vm.pathwaysDataLength,
          mobile: _vm.pathwaysDataLength >= 2 ? 2 : _vm.pathwaysDataLength,
        },"title":_vm.pageData.pathwaysTitle}}):_vm._e()],1)])]):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }