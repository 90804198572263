import { ProductPricesParsed } from "~/types/products";

export const getSavingsBadgeLines = (
  prices: ProductPricesParsed,
  currency: string,
  priceSaveTranslation: string,
  priceOffTranslation: string
): string[] => {
  const grossPrice = prices.gross;
  const wasPrice = prices.was;

  if (wasPrice === null || grossPrice === null) return [];

  if (grossPrice >= 100) {
    const priceDifference = Math.floor(
      parseFloat((wasPrice - grossPrice).toPrecision(12).toString())
    );

    return [priceSaveTranslation, `${currency}${priceDifference}`];
  }

  // change prices to pence so we are dealing with whole numbers
  let offerCalc =
    100 -
    parseInt(grossPrice.toFixed(2).replace(".", "")) /
      (parseInt(wasPrice.toFixed(2).replace(".", "")) / 100);

  // keep it to jump by 5s
  offerCalc -= offerCalc % 5;
  offerCalc = Math.round(offerCalc);

  if (offerCalc >= 5) {
    return [`${offerCalc}%`, priceOffTranslation];
  }

  return [];
};

export const showSavingsBadge = (prices: ProductPricesParsed): boolean => {
  return (
    prices.was !== null && prices.gross !== null && prices.was > prices.gross
  );
};
export const showSavingsBadgeEcomPdp = (prices: any): boolean => {
  return (prices?.wasPriceRaw ?? 0.0) > (prices?.raw?.gross ?? 0.0);
};
export const toProductPricesParsed = (prices: any): ProductPricesParsed => {
  return {
    gross: prices.raw.gross,
    net: prices.raw.net,
    priceperunit: 0,
    was: prices.wasPriceRaw,
  };
};
