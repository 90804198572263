export enum PageTypes {
  Home = "home",
  Taxonomy = "taxonomy",
  Search = "search",
  Product = "product",
  Other = "other",
}

export const regexPagePatterns = {
  home: /toolstation.com($|\/$|\/\?)/,
  category: /.*\/c\d{1,4}/,
  search: /q=([^&]*)/,
  productPage: /(p)([a-zA-Z0-9]{5})/,
};

type RegexPatternKey = keyof typeof regexPagePatterns;

export function pageRegexExpression(type: RegexPatternKey) {
  return regexPagePatterns[type];
}

export const pageType = (url: string | string[]) => {
  const urlString = Array.isArray(url) ? url.join(" ") : url;

  switch (true) {
    case pageRegexExpression("home").test(urlString):
      return PageTypes.Home;
    case pageRegexExpression("category").test(urlString):
      return PageTypes.Taxonomy;
    case pageRegexExpression("search").test(urlString):
      return PageTypes.Search;
    case pageRegexExpression("productPage").test(urlString):
      return PageTypes.Product;
    default:
      return PageTypes.Other;
  }
};
