<template functional>
  <svg
    :class="props.classes"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M7.1 3C6.11624 3 5.3 3.81624 5.3 4.8V5.7H4.4C3.9032 5.7 3.5 6.1032 3.5 6.6C3.5 7.0968 3.9032 7.5 4.4 7.5H5.3V9.3H4.4C3.9032 9.3 3.5 9.7032 3.5 10.2C3.5 10.6968 3.9032 11.1 4.4 11.1H5.3V12.9H4.4C3.9032 12.9 3.5 13.3032 3.5 13.8C3.5 14.2968 3.9032 14.7 4.4 14.7H5.3V16.5H4.4C3.9032 16.5 3.5 16.9032 3.5 17.4C3.5 17.8968 3.9032 18.3 4.4 18.3H5.3V19.2C5.3 20.1838 6.11624 21 7.1 21H17.9C18.8838 21 19.7 20.1838 19.7 19.2V4.8C19.7 3.81624 18.8838 3 17.9 3H7.1ZM7.1 4.8H17.9V19.2H7.1V4.8ZM8.9 9.3V7.5H16.1V9.3H8.9Z"
      fill="currentColor"
    />
  </svg>
</template>

<script>
export default {
  name: "AddressBookIcon",
  props: {
    classes: {
      type: String,
      required: false,
      default: "",
    },
  },
};
</script>
