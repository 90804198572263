<template functional>
  <div
    :class="[
      'w-full bg-white rounded-[1px] border border-transparent shadow',
      props.morePadding ? 'p-6 md:p-10' : 'px-4 py-5',
      props.classes ? props.classes : '',
      {
        'shadow-transparent': props.hideShadow,
      },
    ]"
  >
    <slot></slot>
  </div>
</template>

<script>
export default {
  props: {
    morePadding: {
      type: Boolean,
      required: false,
    },
    hideShadow: {
      type: Boolean,
      required: false,
    },
    classes: {
      default: "",
      type: String,
      required: false,
    },
  },
};
</script>
