const star = "★";
export const getStars = (numberOfStars: number = 5): string =>
  Array(numberOfStars).fill(star).join("");

export const getRatingWidth = (
  rating: string | number = 0,
  maxStars: number = 5
): string => {
  const width = (100 / maxStars) * parseFloat(rating as string);
  return `${width}%`;
};

export const getStarWidth = (
  rating: string | number = 0,
  index: number,
  arrayLength: number
): string => {
  if (index < arrayLength - 1) return "100%";
  const decimalPortion = ((parseFloat(rating as string) % 1) * 100).toFixed(2);
  return `${decimalPortion}%`;
};

export const starsToIterate = (rating: string | number): number[] => {
  const count = Math.ceil(parseFloat(rating as string));
  return Array(count);
};
