var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"alert",class:[
    `alert-${_vm.variant}`,
    {
      'with-border': _vm.withBorder,
      'with-fill': _vm.withFill,
      'with-inner-block': _vm.withInnerBlock,
      'legacy-colour': _vm.legacyColour,
    },
  ],attrs:{"role":"alert"}},[_vm._t("default")],2)
}
var staticRenderFns = []

export { render, staticRenderFns }