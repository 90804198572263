<template functional>
  <svg viewBox="0 0 24 24" fill="none" :class="props.classes">
    <path
      d="M20.82 18.7979L16.3118 14.2707C18.2249 11.3915 17.8461 7.56511 15.4025 5.12155C14.0197 3.7577 12.2013 3 10.2502 3C8.29913 3 6.48066 3.7577 5.11681 5.12155C2.2944 7.9629 2.2944 12.5659 5.11681 15.3883C7.56038 17.8319 11.3867 18.2107 14.266 16.3165L18.7932 20.8437C19.0016 21.0521 19.3615 21.0521 19.5699 20.8437L20.82 19.5935C21.0474 19.3662 21.0474 19.0063 20.82 18.7979ZM13.7356 13.7403C11.8224 15.6535 8.69692 15.6535 6.7648 13.7403C4.85162 11.8272 4.85162 8.70166 6.7648 6.76953C8.67798 4.85635 11.8035 4.85635 13.7356 6.76953C15.6488 8.70166 15.6488 11.8272 13.7356 13.7403Z"
      fill="currentColor"
    />
    <rect x="9.25" y="6.75" width="2" height="7" rx="0.5" fill="currentColor" />
    <rect
      x="6.75"
      y="11.25"
      width="2"
      height="7"
      rx="0.5"
      transform="rotate(-90 6.75 11.25)"
      fill="currentColor"
    />
  </svg>
</template>

<script>
export default {
  name: "ZoomIcon",
  props: {
    classes: {
      type: String,
      required: false,
      default: "",
    },
  },
};
</script>
