import type { Context } from "@nuxt/types";
import { isNil } from "ramda";
import { AppCookieNames } from "~/constants/ecomApi";
import { redirectConfig } from "~/services/config.service";
import { CustomTaxonomyPageNames } from "~/lib/taxonomies/index";

type Redirect = {
  from: string;
  to: string;
};

export default async function ({
  route,
  redirect,
  $config,
  $cookies,
}: Context) {
  if (
    route.name === "login" &&
    !isNil($cookies.get(AppCookieNames.CustomerJWT) || null)
  ) {
    // ignore guest-customers
    if (isNil($cookies.get(AppCookieNames.GuestCustomerJWT))) {
      redirect("/account/home");
    }
  }

  // Redirect from cordless to cordedorcordless
  const cordless = route?.query?.cordless;
  const newUrl = route.fullPath.replace("cordless=", "cordedorcordless=");
  const isTaxonomyPage =
    route.name === CustomTaxonomyPageNames.brands ||
    route.name === CustomTaxonomyPageNames.campaign ||
    route.name === "topslug-slug_id-id";

  if (cordless && isTaxonomyPage) {
    redirect(301, newUrl);
  }

  // Category redirects
  if (!route.fullPath.match(/^\/[a-z-]+\/[a-z-]+\/c\d+$/gi)) return;

  const redirects = await redirectConfig($config.localeInstance);

  const toRedirect: Redirect = redirects.find(
    (redirect: Redirect) => redirect.from === route.fullPath
  );

  if (toRedirect) {
    if (process.server) redirect(301, toRedirect.to);
    else redirect(toRedirect.to);
  }
}
