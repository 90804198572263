import { getCurrentInstance } from "vue";

// Acts like 'this' in options api. Used access data and methods etc from options api from with in compostion api
export function currentInstance() {
  const { proxy } = getCurrentInstance();
  return proxy;
}
export function route() {
  const { proxy } = getCurrentInstance();
  const route = proxy.$route;
  return route;
}
export function router() {
  const { proxy } = getCurrentInstance();
  const router = proxy.$router;
  return router;
}
export function store() {
  const { proxy } = getCurrentInstance();
  const store = proxy.$store;
  return store;
}
export function http() {
  const {
    proxy: { $axios },
  } = getCurrentInstance();
  return $axios;
}
export function currentPath() {
  return route().path;
}
export function config() {
  const { proxy } = getCurrentInstance();
  return proxy.$config;
}

export function languageString(string) {
  const { proxy } = getCurrentInstance();
  return proxy._i18n.t(string);
}
