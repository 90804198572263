import {
  BraintreeOptions,
  PaymentProvider,
} from "~/services/api/payments.api.d";
import { TrolleyConfig } from "~/types/trolleyConfig";

interface DefaultConfig {
  [key: string]: any;
}

export interface CustomersConfig {
  titles: Record<string, string>;
  scan: Record<string, boolean>;
}

export interface ApplicationConfig {
  companyTypes: {
    popular: Record<string, string>;
    others: Record<string, string>;
  };
  typeOfBusinessOptions: Record<string, string>;
  monthlySpend: Record<string, string>;
}

type PaymentConfig = {
  paymentOptions: PaymentProvider[];
  braintree: BraintreeOptions;
};

export const config = async <T>(
  country: string,
  config: string,
  path: string = "json"
): Promise<T> => {
  try {
    const configFile = await import(`~/constants/${country}/${config}.${path}`);

    if (Object.prototype.hasOwnProperty.call(configFile, "default")) {
      return configFile.default;
    }
    return configFile;
  } catch (e) {
    return [] as unknown as T;
  }
};

// @todo refactor this so it's against true country codes. Previous implementation incorrect against directory structure
// SupportedCountry GB = "United Kingdom"

export const customersConfig = (country: string) =>
  config<CustomersConfig>(country, "customers");

export const tradeApplicationConfig = (country: string) =>
  config<ApplicationConfig>(country, "tradeapplication");

export const procardConfig = (country: string) =>
  config<DefaultConfig>(country, "procard", "ts");

export const tradeAccountConfig = async (
  country: string
): Promise<{ [key: string]: any }> =>
  await config(country, "tradeaccount", "ts");

export const contactConfig = (country: string) =>
  config<DefaultConfig>(country, "contact", "ts");

export const headerConfig = (country: string) =>
  config<DefaultConfig>(country, "header", "ts");

export const footerConfig = (country: string) =>
  config<DefaultConfig>(country, "footer", "ts");

export const cdnConfig = (country: string) =>
  config<DefaultConfig>(country, "cdn", "ts");

export const trolleyConfig = (country: string) =>
  config<TrolleyConfig>(country, "trolley", "ts");

export const branchesConfig = (country: string) =>
  config<DefaultConfig>(country, "branches", "ts");

export const registerConfig = (country: string) =>
  config<DefaultConfig>(country, "register", "ts");

export const paymentsConfig = (country: string) =>
  config<PaymentConfig>(country, "payments", "ts");

export const redirectConfig = (country: string) =>
  config<DefaultConfig>(country, "redirects", "ts");
