var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"flex",class:_vm.compact
      ? 'border-yellow-default border-b-8 border-solid h-[50px] sm:h-[70px] lg:h-[100px]'
      : 'h-auto'},[_c('div',{staticClass:"w-full relative lg:block",class:_vm.compact ? '' : 'hidden h-10'},[_c('div',{staticClass:"content-container flex justify-between items-center h-full"},[_c('nuxt-link',{attrs:{"to":"/"}},[_c('img',{class:_vm.compact
              ? 'mt-0 w-[125px] sm:w-[200px] lg:w-[275px]'
              : 'mt-14 h-[69px] w-[275px]',attrs:{"src":"https://cdn.toolstation.com/assets/toolstation-logo-halo.svg","width":"275","height":"60","alt":"Toolstation logo"}})]),_vm._v(" "),_c('nav',[_c('ul',{staticClass:"text-white flex text-size-4.5"},[_vm._l((_vm.links),function(link,index){return _c('li',{key:index,staticClass:"py-3 pl-8"},[(link.translationKey !== 'header.topNav.setBranch')?_c('nuxt-link',{key:`set-store-${index}`,staticClass:"hover:underline text-white",attrs:{"to":link.path}},[_vm._v("\n              "+_vm._s(_vm.$tc(link.translationKey))+"\n            ")]):[_c('button',{directives:[{name:"show",rawName:"v-show",value:(_vm.isBranchLinkVisible),expression:"isBranchLinkVisible"}],key:`store-link-${index}`,staticClass:"text-yellow-default font-semibold capitalize",class:_vm.isBranchPage
                    ? 'cursor-default'
                    : 'cursor-pointer hover:underline',attrs:{"data-testid":"branch-link"},on:{"click":_vm.branchClickHandler}},[_vm._v("\n                "+_vm._s(_vm.getSelectedBranchLinkName(link.translationKey))+"\n              ")])]],2)}),_vm._v(" "),(!_vm.compact && _vm.canSwitchLocales && _vm.$i18n.locale !== 'en-GB')?_c('li',{staticClass:"py-3 pl-8 z-header"},[_c('LocaleSelector',{attrs:{"current-locale":_vm.$i18n.locale,"locales":_vm.getSupportedLocales,"path":_vm.$route.path}})],1):_vm._e()],2)])],1)])])
}
var staticRenderFns = []

export { render, staticRenderFns }