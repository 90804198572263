import { NuxtAxiosInstance } from "@nuxtjs/axios";
import { LanguageCode } from "~/types/iso-639-1";
import {
  ApiMenuResponse,
  ApiPagesResponse,
  ApiPostResponse,
  ApiPostsResponse,
} from "~/services/api/content.api.d";

const ENDPOINTS = {
  CONTENT_MENU: "/content/menus",
  CONTENT_PAGE: "/content/pages",
  CONTENT_CATEGORIES: (slug: string) => {
    return `/content/categories/${slug}/posts`;
  },
  CONTENT_POST: "/content/posts",
  CONTENT_REDIRECTS: "/content/redirects",
};

export const getMenu = async (
  $axios: NuxtAxiosInstance,
  menuId: string,
  languageCode?: LanguageCode
): Promise<ApiMenuResponse> => {
  const menuPath =
    languageCode !== undefined ? `${menuId}-${languageCode}` : menuId;
  const url = `${ENDPOINTS.CONTENT_MENU}/${menuPath}`;

  return await $axios.$get(url);
};

export const getPageBySlug = async (
  $axios: NuxtAxiosInstance,
  slug: string
): Promise<ApiPagesResponse> =>
  await $axios.$get<any>(`${ENDPOINTS.CONTENT_PAGE}/${slug}`);

export const getPostsBySlug = async (
  $axios: NuxtAxiosInstance,
  slug: string
): Promise<ApiPostResponse> =>
  await $axios.$get<any>(`${ENDPOINTS.CONTENT_POST}/${slug}`);

export const getAllPostsInCategory = async (
  $axios: NuxtAxiosInstance,
  category: string
): Promise<ApiPostsResponse> =>
  await $axios.$get<any>(ENDPOINTS.CONTENT_CATEGORIES(category));

export const getRedirects = async (
  $axios: NuxtAxiosInstance
): Promise<AxiosResponse<any>> =>
  await $axios.$get(ENDPOINTS.CONTENT_REDIRECTS);
