<template>
  <div>
    <div
      v-if="backgroundColour"
      :style="backgroundColourClass"
      class="h-full w-full object-cover"
    />
    <div
      class="border-grey-3 border-1 bg-white"
      :class="{
        'p-4': !noImagePadding,
      }"
    >
      <img
        class="h-full w-full object-cover"
        :src="imageUrl"
        :alt="title"
        loading="lazy"
      />
    </div>
    <p
      v-if="title"
      class="font-bold text-size-2 md:text-size-3 text-left my-2"
      :style="titleTextColour !== '' ? titleTextColour : 'color: #065CAB'"
    >
      {{ title }}
    </p>
  </div>
</template>

<script setup>
import { computed } from "vue";

const props = defineProps({
  imageUrl: {
    type: String,
    required: true,
  },
  title: {
    type: String,
    required: true,
  },
  titleTextColour: {
    type: String,
    required: false,
    default: "color: #065CAB",
  },
  backgroundColour: {
    type: String,
    required: false,
    default: null,
  },
  noImagePadding: {
    type: Boolean,
    required: false,
    default: false,
  },
});

const backgroundColourClass = computed(
  () => `background-color:${props.backgroundColour}`
);
</script>
