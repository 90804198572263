import { NuxtAxiosInstance } from "@nuxtjs/axios";
import { isEmpty, isNil } from "ramda";
import {
  ApiDeliveryMethodResponse,
  DeliveryMethodAddressIds,
} from "~/services/api/trolleys.api.d";

const ENDPOINTS = {
  TROLLEYS: (trolleyId: string) => `/trolleys/${trolleyId}`,
  TROLLEYS_LINES: (trolleyId: string) => `/trolleys/${trolleyId}/lines`,
  TROLLEYS_PROMO_CODES: (trolleyId: string) =>
    `/trolleys/${trolleyId}/promo-codes`,
  TROLLEYS_SHARE_BY_EMAIL: (trolleyId: string) =>
    `/trolleys/${trolleyId}/share-by-email`,
  TROLLEYS_DELIVERY_METHODS: (trolleyId: string) =>
    `/trolleys/${trolleyId}/delivery-methods`,
  TROLLEYS_CHECK_DISCOUNTS: (trolleyId: string) =>
    `/trolleys/${trolleyId}/check-discounts`,
  TROLLEYS_ORDER_TOTALS: (trolleyId: string) =>
    `/trolleys/${trolleyId}/order-totals`,
  TROLLEYS_LINES_QUANTITY: (trolleyLineId: number) =>
    `/trolleys/lines/${trolleyLineId}/quantity`,
  TROLLEYS_LINES_CHANNEL: (trolleyLineId: number) =>
    `/trolleys/lines/${trolleyLineId}/channel`,
  TROLLEYS_LINES_DELIVERY_METHOD: (trolleyLineId: number) =>
    `/trolleys/lines/${trolleyLineId}/delivery-method`,
  TROLLEYS_RESET_DELIVERY_METHODS: (trolleyId: string) =>
    `/trolleys/${trolleyId}/recalculate-delivery-methods`,
  TROLLEYS_LINE: (trolleyLineId: number) => `/trolleys/lines/${trolleyLineId}`,
  TROLLEYS_PROMO_CODE: (appliedPromoCodeId: string) =>
    `/trolleys/promo-codes/${appliedPromoCodeId}`,
  TROLLEYS_CONVERT_TO_CUSTOMER: (trolleyId: string) =>
    `/trolleys/${trolleyId}/convert-to-customer`,
  TROLLEYS_ATTRIBUTES: (trolleyId: string) =>
    `/trolleys/${trolleyId}/attributes`,
  TROLLEYS_ATTRIBUTE: (trolleyId: string, attributeId: string) =>
    `/trolleys/${trolleyId}/attributes/${attributeId}`,
  TROLLEYS_CHECKOUT_LAPOSTE: (trolleyId: string) =>
    `/trolleys/${trolleyId}/checkout/laposte`,
  TROLLEYS_CHECKOUT_POSTNL: (trolleyId: string) =>
    `/trolleys/${trolleyId}/checkout/postnl`,
  TROLLEYS_CHECKOUT_BRAINTREE: () => `/trolleys/checkout/braintree`,
};

export const getTrolley = async (
  $axios: NuxtAxiosInstance,
  trolleyId: string
): Promise<AxiosResponse<any>> =>
  await $axios.$get(ENDPOINTS.TROLLEYS(trolleyId));

export const updateTrolley = async (
  $axios: NuxtAxiosInstance,
  trolleyId: string,
  data: any
): Promise<AxiosResponse<any>> =>
  await $axios.$put(ENDPOINTS.TROLLEYS(trolleyId), data);

export const deleteTrolley = async (
  $axios: NuxtAxiosInstance,
  trolleyId: string
): Promise<AxiosResponse<any>> =>
  await $axios.$delete(ENDPOINTS.TROLLEYS(trolleyId));

export const createTrolleyLines = async (
  $axios: NuxtAxiosInstance,
  trolleyId: string,
  data: any
): Promise<AxiosResponse<any>> =>
  await $axios.$post(ENDPOINTS.TROLLEYS_LINES(trolleyId), data);

export const createTrolleyPromoCodes = async (
  $axios: NuxtAxiosInstance,
  trolleyId: string,
  data: any
): Promise<AxiosResponse<any>> =>
  await $axios.$post(ENDPOINTS.TROLLEYS_PROMO_CODES(trolleyId), data);

export const createTrolleyShareByEmail = async (
  $axios: NuxtAxiosInstance,
  trolleyId: string,
  data: any
): Promise<AxiosResponse<any>> =>
  await $axios.$post(ENDPOINTS.TROLLEYS_SHARE_BY_EMAIL(trolleyId), data);

export const getTrolleyDeliveryMethods = async (
  $axios: NuxtAxiosInstance,
  trolleyId: string,
  addressIds: DeliveryMethodAddressIds
): Promise<ApiDeliveryMethodResponse> => {
  const url = ENDPOINTS.TROLLEYS_DELIVERY_METHODS(trolleyId);
  const querystring = new URLSearchParams();

  if (
    !isEmpty(addressIds.collectionAddressId) &&
    !isNil(addressIds.collectionAddressId)
  ) {
    querystring.set(
      "collection_address_id",
      addressIds.collectionAddressId as string
    );
  }
  if (
    !isEmpty(addressIds.deliveryAddressId) &&
    !isNil(addressIds.deliveryAddressId)
  ) {
    querystring.set(
      "delivery_address_id",
      addressIds.deliveryAddressId as string
    );
  }

  return await $axios.$get(url + "?" + querystring.toString());
};

export const checkTrolleyDiscounts = async (
  $axios: NuxtAxiosInstance,
  trolleyId: string,
  discountToken?: string,
  branchId?: string
): Promise<AxiosResponse<any>> =>
  await $axios.$post(ENDPOINTS.TROLLEYS_CHECK_DISCOUNTS(trolleyId), {
    ...(discountToken && { discount_token: discountToken }),
    ...(branchId && { branch_id: branchId }),
  });

export const trolleyOrderTotals = async (
  $axios: NuxtAxiosInstance,
  trolleyId: string,
  staffDiscountToken?: string
): Promise<AxiosResponse<any>> =>
  await $axios.$post(ENDPOINTS.TROLLEYS_ORDER_TOTALS(trolleyId), {
    ...(staffDiscountToken ? { discount_token: staffDiscountToken } : {}),
  });

export const updateTrolleyLinesQuantity = async (
  $axios: NuxtAxiosInstance,
  trolleyLineId: number,
  data: any
): Promise<AxiosResponse<any>> =>
  await $axios.$put(ENDPOINTS.TROLLEYS_LINES_QUANTITY(trolleyLineId), data);

export const updateTrolleyLinesChannel = async (
  $axios: NuxtAxiosInstance,
  trolleyLineId: number,
  data: any
): Promise<AxiosResponse<any>> =>
  await $axios.$put(ENDPOINTS.TROLLEYS_LINES_CHANNEL(trolleyLineId), data);

export const updateTrolleyLineDeliveryMethod = async (
  $axios: NuxtAxiosInstance,
  trolleyLineId: number,
  data: any
): Promise<AxiosResponse<any>> =>
  await $axios.$put(
    ENDPOINTS.TROLLEYS_LINES_DELIVERY_METHOD(trolleyLineId),
    data
  );

export const resetDeliveryMethods = async (
  $axios: NuxtAxiosInstance,
  trolleyId: string
): Promise<AxiosResponse<any>> =>
  await $axios.$post(ENDPOINTS.TROLLEYS_RESET_DELIVERY_METHODS(trolleyId));

export const updateTrolleyLine = async (
  $axios: NuxtAxiosInstance,
  trolleyLineId: number,
  data: any
): Promise<AxiosResponse<any>> =>
  await $axios.$patch(ENDPOINTS.TROLLEYS_LINE(trolleyLineId), data);

export const deleteTrolleyLine = async (
  $axios: NuxtAxiosInstance,
  trolleyLineId: number
): Promise<AxiosResponse<any>> =>
  await $axios.$delete(ENDPOINTS.TROLLEYS_LINE(trolleyLineId));

export const deleteTrolleyPromoCode = async (
  $axios: NuxtAxiosInstance,
  appliedPromoCodeId: string
): Promise<AxiosResponse<any>> =>
  await $axios.$delete(ENDPOINTS.TROLLEYS_PROMO_CODE(appliedPromoCodeId));

export const trolleyConvertToCustomer = async (
  $axios: NuxtAxiosInstance,
  trolleyId: string,
  data: any
): Promise<AxiosResponse<any>> =>
  await $axios.$patch(ENDPOINTS.TROLLEYS_CONVERT_TO_CUSTOMER(trolleyId), data);

export const createTrolleyAttributes = async (
  $axios: NuxtAxiosInstance,
  trolleyId: string,
  data: any
): Promise<AxiosResponse<any>> =>
  await $axios.$post(ENDPOINTS.TROLLEYS_ATTRIBUTES(trolleyId), data);

export const updateTrolleyAttribute = async (
  $axios: NuxtAxiosInstance,
  trolleyId: string,
  data: any
): Promise<AxiosResponse<any>> =>
  await $axios.$patch(ENDPOINTS.TROLLEYS_ATTRIBUTE(trolleyId, data.id), data);

export const deleteTrolleyAttribute = async (
  $axios: NuxtAxiosInstance,
  trolleyId: string,
  attributeId: string
): Promise<AxiosResponse<any>> =>
  await $axios.$delete(ENDPOINTS.TROLLEYS_ATTRIBUTE(trolleyId, attributeId));

export const getTrolleyCheckoutLaposte = async (
  $axios: NuxtAxiosInstance,
  trolleyId: string
): Promise<AxiosResponse<any>> =>
  await $axios.$get(ENDPOINTS.TROLLEYS_CHECKOUT_LAPOSTE(trolleyId));

export const createTrolleyCheckoutLaposte = async (
  $axios: NuxtAxiosInstance,
  trolleyId: string,
  data: any
): Promise<AxiosResponse<any>> =>
  await $axios.$post(ENDPOINTS.TROLLEYS_CHECKOUT_LAPOSTE(trolleyId), data);

export const createTrolleyCheckoutPostnl = async (
  $axios: NuxtAxiosInstance,
  trolleyId: string,
  data: any
): Promise<AxiosResponse<any>> =>
  await $axios.$post(ENDPOINTS.TROLLEYS_CHECKOUT_POSTNL(trolleyId), data);

export const createTrolleyCheckoutBraintree = async (
  $axios: NuxtAxiosInstance,
  data: any
): Promise<AxiosResponse<any>> =>
  await $axios.$post(ENDPOINTS.TROLLEYS_CHECKOUT_BRAINTREE(), data);
