export const getWidgetType = (widgetAlgo: string) => {
  switch (widgetAlgo) {
    case "Experience-Driven Recommendations":
    case "Frequently Bought Together":
    case "Frequently Viewed Together":
    case "Similar Products":
      return "item";
    case "Category":
      return "category";
    case "Search (Keyword)":
      return "keyword";
    case "Past Purchase":
      return "personalization";
    case "Trending Products":
    case "Best Seller":
      return "global";
    default:
      return "";
  }
};

export const getWidgetParams = (
  widgetType: string,
  categoryId: string,
  productIds: [],
  keyword: string,
  productImpressions: [] | null = null
) => {
  switch (widgetType) {
    case "item":
      return {
        itemIds: productImpressions || productIds,
      };

    case "category":
      return {
        categoryId,
      };
    case "keyword":
    case "personalization":
      return {
        query: keyword,
      };
    case "global":
      return {};
    default:
      return null;
  }
};
