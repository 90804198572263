<template functional>
  <svg
    aria-hidden="true"
    focusable="false"
    role="img"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 512 512"
    :class="`spinner ${props.classes} ${props.size}`"
  >
    <path
      fill="currentColor"
      d="M304 48a48 48 0 1 0 -96 0 48 48 0 1 0 96 0zm0 416a48 48 0 1 0 -96 0 48 48 0 1 0 96 0zM48 304a48 48 0 1 0 0-96 48 48 0 1 0 0 96zm464-48a48 48 0 1 0 -96 0 48 48 0 1 0 96 0zM142.9 437A48 48 0 1 0 75 369.1 48 48 0 1 0 142.9 437zm0-294.2A48 48 0 1 0 75 75a48 48 0 1 0 67.9 67.9zM369.1 437A48 48 0 1 0 437 369.1 48 48 0 1 0 369.1 437z"
    ></path>
  </svg>
</template>

<script>
export default {
  name: "LoadingSpinnerIcon",
  props: {
    classes: {
      type: String,
      required: false,
      default: "",
    },
    size: {
      type: String,
      default: "xl",
      validator(value) {
        return ["xxs", "xs", "sm", "md", "lg", "xl", "xxl", "xxxl"].includes(
          String(value)
        );
      },
    },
  },
};
</script>

<style scoped lang="postcss">
.spinner {
  animation-name: rotate;
  animation-duration: 5s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}

@keyframes rotate {
  from {
    transform: rotate(-360deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.sm {
  @apply h-5 h-5;
}
.md {
  @apply h-6 w-6;
}
.lg {
  @apply h-7 w-7;
}
.xl {
  @apply h-8 w-8;
}
.xxl {
  @apply h-9 w-9;
}
.xxxl {
  @apply h-10 w-10;
}
</style>
