export const currencySymbol = (
  locale: string,
  currencyCode: string
): string => {
  return (0)
    .toLocaleString(locale, {
      style: "currency",
      currency: currencyCode,
      maximumFractionDigits: 0,
      minimumFractionDigits: 0,
    })
    .replace(/\d/g, "");
};

export default { currencySymbol };
