<template functional>
  <svg
    aria-hidden="true"
    focusable="false"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 384 512"
    :class="`${props.classes} ${props.size}`"
  >
    <path
      fill="currentColor"
      d="M342.6 150.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L192 210.7 86.6 105.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L146.7 256 41.4 361.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L192 301.3 297.4 406.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L237.3 256 342.6 150.6z"
    ></path>
  </svg>
</template>

<script>
export default {
  name: "XmarkIcon",
  props: {
    classes: {
      type: String,
      required: false,
      default: "",
    },
    size: {
      type: String,
      default: "xl",
      validator(value) {
        return ["xxs", "xs", "sm", "md", "lg", "xl", "xxl"].includes(
          String(value)
        );
      },
    },
  },
};
</script>

<style scoped lang="postcss">
.xxxs {
  @apply h-2 w-2;
}
.xxs {
  @apply h-3 w-3;
}
.xs {
  @apply h-4 w-5;
}
.sm {
  @apply h-5 h-5;
}
.md {
  @apply h-6 w-6;
}
.lg {
  @apply h-7 w-7;
}
.xl {
  @apply h-8 w-8;
}
</style>
