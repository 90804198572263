interface Taxonomy {
  slug: string;
  name: string;
  id: number;
}

export interface Ancestor {
  parentId: number;
  taxonomy: Taxonomy;
}

export interface BreadcrumbItem {
  title: string;
  to: string;
}

export type BreadcrumbItems = (
  taxonomy: Taxonomy | undefined,
  topParentSlug: string,
  ancestors?: Ancestor[]
) => BreadcrumbItem[];

export const getTaxonomyBreadcrumbs: BreadcrumbItems = (
  taxonomy,
  topParentSlug,
  ancestors = [],
  isSlugId = false,
  routeName = ""
) => {
  if (!taxonomy) return [];

  return [
    ...ancestors.map(({ parentId, taxonomy }) => {
      return {
        title: taxonomy.name,
        to: isSlugId
          ? `/${routeName}`
          : parentId === 0
            ? `${topParentSlug}/c${taxonomy.id}`
            : `${topParentSlug}/${taxonomy.slug}/c${taxonomy.id}`,
      };
    }),
    {
      title: taxonomy.name,
      to: isSlugId
        ? `/${routeName}/${taxonomy.id}`
        : `${topParentSlug}/${taxonomy.slug}/c${taxonomy.id}`,
    },
  ];
};
