import { NuxtAxiosInstance } from "@nuxtjs/axios";
import {
  ApiAddressesResponse,
  ApiCreateAddressRequest,
  ApiCustomerResetPasswordEmailRequest,
  ApiCustomerResetPasswordRequest,
  ApiCustomerResponse,
  ApiLoginRequest,
  ApiPasswordResetRequest,
  ApiSetPrimaryAddressRequest,
  ApiUpdateAddressRequest,
  ApiUpdateCustomerPreferencesRequest,
  ApiUpdateCustomerRequest,
  ApiGuestCustomerAuthRegisterRequest,
  ApiUpdateGuestCustomerRequest,
} from "~/services/api/customers.api.d";
import { ApiOrderResponse } from "~/services/api/orders.api.d";
import {
  ApiSavedListResponse,
  ApiSavedListsResponse,
} from "~/services/api/savedLists.api.d";
import { RawTrolleyData } from "~/types/trolley";

const ENDPOINTS = {
  CUSTOMERS: (customerId: string) => `/customers/${customerId}`,
  CUSTOMER_ORDERS: (customerId: string) => `/customers/${customerId}/orders`,
  CUSTOMER_PURCHASE_HISTORY: (customerId: string) =>
    `/customers/${customerId}/purchase-history`,
  CUSTOMER_PASSWORD: (customerId: string) =>
    `/customers/${customerId}/password`,
  CUSTOMER_ACCOUNTS: (customerId: string) =>
    `/customers/${customerId}/accounts`,
  CUSTOMER_TROLLEYS: (customerId: string) =>
    `/customers/${customerId}/trolleys`,
  CUSTOMER_ACTIVE_TROLLEYS: (customerId: string) =>
    `/customers/${customerId}/trolleys/active`,
  CUSTOMER_CONTACT_PREFERENCES: (customerId: string) =>
    `/customers/${customerId}/contact-preferences`,
  CUSTOMER_CATALOGUE_REQUESTS: (customerId: string) =>
    `/customers/${customerId}/catalogue-requests`,
  CUSTOMERS_LAST_CATALOGUE_REQUEST: (customerId: string) =>
    `/customers/${customerId}/last-catalogue-request`,
  CUSTOMER_SAVED_LISTS: (customerId: string) =>
    `/customers/${customerId}/saved-lists`,
  FROM_TROLLEY_ID: (trolleyId: string) =>
    `/saved-lists/create-from-trolley/${trolleyId}`,
  CUSTOMER_CARD_REQUEST: (customerId: string) =>
    `/customers/${customerId}/card-request`,
  CUSTOMER_FAVOURITE_BRANCHES: (customerId: string) =>
    `/customers/${customerId}/favourite-branches`,
  CUSTOMER_FAVOURITE_BRANCH: (customerId: string, branchId: string) =>
    `/customers/${customerId}/favourite-branches/${branchId}`,
  CUSTOMER_DEFAULT_BRANCH: (customerId: string) =>
    `/customers/${customerId}/default-branch`,
  CUSTOMER_USERNAME_CHECK: () => `/customers/username-check`,
  CUSTOMER_REQUEST_ACCOUNT_DELETION: (customerId: string) =>
    `/customers/${customerId}/request-account-deletion`,

  // Promotions
  CUSTOMER_APPLY_PROMOTION: () => `/customers/promotion`,
  CUSTOMER_PROMOTIONS: (customerId: string) =>
    `/customers/${customerId}/promotions`,

  // Address book
  CUSTOMER_ADDRESS_BOOK: (customerId: string) =>
    `/customers/address-book/${customerId}`,
  CUSTOMER_ADDRESS_BOOK_PRIMARY_ADDRESS: (customerId: string) =>
    `/customers/address-book/${customerId}/primary-address`,
  CUSTOMER_ADDRESS_BOOK_ADDRESS: (addressId: string) =>
    `/customers/address-book/${addressId}`,

  // Auth
  CUSTOMER_AUTH_LOGIN: `/customers/auth/login`,
  CUSTOMER_AUTH_LOGOUT: `/customers/auth/logout`,
  CUSTOMER_AUTH_REGISTER: `/customers/auth/register`,
  CUSTOMER_AUTH_GUEST_REGISTER: `/customers/auth/guest/register`,
  CUSTOMER_AUTH_RESET_PASSWORD: `/customers/auth/password/reset`,
  CUSTOMER_AUTH_RESET_PASSWORD_EMAIL: `/customers/auth/password/email`,
  CUSTOMER_AUTH_CONVERT_GUEST_TO_CUSTOMER: (customerId: string) =>
    `/customers/auth/guest/${customerId}/convert-to-customer`,

  CUSTOMER_LOYALTY_CLUB: (customerId: string) =>
    `/customers/${customerId}/loyalty-club`,

  CUSTOMER_LOYALTY_CLUB_LINK: (customerId: string) =>
    `/customers/${customerId}/loyalty-club/rewards-platform-sso-url`,
};

export const getCustomerById = async (
  $axios: NuxtAxiosInstance,
  customerId: string
): Promise<AxiosResponse<any>> =>
  await $axios.$get(ENDPOINTS.CUSTOMERS(customerId));

export const updateCustomerById = async (
  $axios: NuxtAxiosInstance,
  customerId: string,
  data: ApiUpdateCustomerRequest
): Promise<ApiCustomerResponse> =>
  await $axios.$patch(ENDPOINTS.CUSTOMERS(customerId), data);

export const getCustomerOrders = async (
  $axios: NuxtAxiosInstance,
  customerId: string,
  page: number = 1,
  pageLimit: number = 50,
  paymentType: string | null = null
): Promise<ApiOrderResponse> => {
  const querystring = new URLSearchParams();
  querystring.set("page", String(page));
  querystring.set("pageLimit", String(pageLimit));

  if (paymentType !== null) {
    querystring.set("payment_type", paymentType);
  }

  return await $axios.$get(
    ENDPOINTS.CUSTOMER_ORDERS(customerId) + "?" + querystring.toString()
  );
};

export const getCustomerPurchaseHistory = async (
  $axios: NuxtAxiosInstance,
  customerId: string
): Promise<AxiosResponse<any>> =>
  await $axios.$get(ENDPOINTS.CUSTOMER_PURCHASE_HISTORY(customerId));

export const updateCustomerPassword = async (
  $axios: NuxtAxiosInstance,
  customerId: string,
  data: ApiPasswordResetRequest
): Promise<ApiCustomerResponse> =>
  await $axios.$put(ENDPOINTS.CUSTOMER_PASSWORD(customerId), data);

export const getCustomerAccounts = async (
  $axios: NuxtAxiosInstance,
  customerId: string
): Promise<AxiosResponse<any>> =>
  await $axios.$get(ENDPOINTS.CUSTOMER_ACCOUNTS(customerId));

export const getCustomerActiveTrolleys = async (
  $axios: NuxtAxiosInstance,
  customerId: string
): Promise<AxiosResponse<RawTrolleyData[]>> =>
  await $axios.$get(ENDPOINTS.CUSTOMER_ACTIVE_TROLLEYS(customerId));

export const getCustomerTrolleys = async (
  $axios: NuxtAxiosInstance,
  customerId: string
): Promise<AxiosResponse<any>> =>
  await $axios.$get(ENDPOINTS.CUSTOMER_TROLLEYS(customerId));

export const createCustomerTrolleys = async (
  $axios: NuxtAxiosInstance,
  customerId: string
): Promise<AxiosResponse<any>> =>
  await $axios.$post(ENDPOINTS.CUSTOMER_TROLLEYS(customerId));

export const updateCustomerContactPreferences = async (
  $axios: NuxtAxiosInstance,
  customerId: string,
  data: ApiUpdateCustomerPreferencesRequest
): Promise<ApiCustomerResponse> =>
  await $axios.$put(ENDPOINTS.CUSTOMER_CONTACT_PREFERENCES(customerId), data);

export const createCustomerCatalogueRequest = async (
  $axios: NuxtAxiosInstance,
  customerId: string,
  data: any
): Promise<AxiosResponse<any>> =>
  await $axios.$post(ENDPOINTS.CUSTOMER_CATALOGUE_REQUESTS(customerId), data);

export const getCustomerLastCatalogueRequest = async (
  $axios: NuxtAxiosInstance,
  customerId: string
): Promise<AxiosResponse<any>> =>
  await $axios.$get(ENDPOINTS.CUSTOMERS_LAST_CATALOGUE_REQUEST(customerId));

export const getCustomerSavedLists = async (
  $axios: NuxtAxiosInstance,
  customerId: string
): Promise<ApiSavedListsResponse> =>
  await $axios.$get(ENDPOINTS.CUSTOMER_SAVED_LISTS(customerId));

export const createSavedListFromTrolleyId = async (
  $axios: NuxtAxiosInstance,
  customerId: string
): Promise<ApiSavedListsResponse> =>
  await $axios.$post(ENDPOINTS.FROM_TROLLEY_ID(customerId));

export const createCustomerSavedList = async (
  $axios: NuxtAxiosInstance,
  customerId: string,
  data: any
): Promise<ApiSavedListResponse> =>
  await $axios.$post(ENDPOINTS.CUSTOMER_SAVED_LISTS(customerId), data);

export const updateCustomerCardRequest = async (
  $axios: NuxtAxiosInstance,
  customerId: string,
  data: any
): Promise<AxiosResponse<any>> =>
  await $axios.$put(ENDPOINTS.CUSTOMER_CARD_REQUEST(customerId), data);

export const createCustomerFavouriteBranches = async (
  $axios: NuxtAxiosInstance,
  customerId: string,
  data: any
): Promise<AxiosResponse<any>> =>
  await $axios.$post(ENDPOINTS.CUSTOMER_FAVOURITE_BRANCHES(customerId), data);

export const deleteCustomerFavouriteBranch = async (
  $axios: NuxtAxiosInstance,
  customerId: string,
  siteId: string
): Promise<AxiosResponse<any>> =>
  await $axios.$delete(ENDPOINTS.CUSTOMER_FAVOURITE_BRANCH(customerId, siteId));

export const updateDefaultBranch = async (
  $axios: NuxtAxiosInstance,
  customerId: string,
  data: any
): Promise<AxiosResponse<any>> =>
  await $axios.$put(ENDPOINTS.CUSTOMER_DEFAULT_BRANCH(customerId), data);

export const createCustomerUsernameCheck = async (
  $axios: NuxtAxiosInstance,
  data: any
): Promise<AxiosResponse<any>> =>
  await $axios.$post(ENDPOINTS.CUSTOMER_USERNAME_CHECK(), data);

export const createCustomerRequestAccountDeletion = async (
  $axios: NuxtAxiosInstance,
  customerId: string,
  data: any
): Promise<AxiosResponse<any>> =>
  await $axios.$post(
    ENDPOINTS.CUSTOMER_REQUEST_ACCOUNT_DELETION(customerId),
    data
  );

export const applyCustomerPromotion = async (
  $axios: NuxtAxiosInstance,
  data: any
): Promise<AxiosResponse<any>> =>
  await $axios.$post(ENDPOINTS.CUSTOMER_APPLY_PROMOTION(), data);

export const getCustomerPromotions = async (
  $axios: NuxtAxiosInstance,
  customerId: string
): Promise<AxiosResponse<any>> =>
  await $axios.$get(ENDPOINTS.CUSTOMER_PROMOTIONS(customerId));

export const getCustomerAddressBook = async (
  $axios: NuxtAxiosInstance,
  customerId: string
): Promise<ApiAddressesResponse> =>
  await $axios.$get(ENDPOINTS.CUSTOMER_ADDRESS_BOOK(customerId));

export const createCustomerAddress = async (
  $axios: NuxtAxiosInstance,
  customerId: string,
  data: ApiCreateAddressRequest
): Promise<ApiAddressesResponse> =>
  await $axios.$post(ENDPOINTS.CUSTOMER_ADDRESS_BOOK(customerId), data);

export const updateCustomerPrimaryAddress = async (
  $axios: NuxtAxiosInstance,
  customerId: string,
  data: ApiSetPrimaryAddressRequest
): Promise<ApiAddressesResponse> =>
  await $axios.$put(
    ENDPOINTS.CUSTOMER_ADDRESS_BOOK_PRIMARY_ADDRESS(customerId),
    data
  );

export const updateCustomerAddress = async (
  $axios: NuxtAxiosInstance,
  addressId: string,
  data: ApiUpdateAddressRequest
): Promise<ApiAddressesResponse> =>
  await $axios.$put(ENDPOINTS.CUSTOMER_ADDRESS_BOOK_ADDRESS(addressId), data);

export const deleteCustomerAddress = async (
  $axios: NuxtAxiosInstance,
  addressId: string
): Promise<ApiAddressesResponse> =>
  await $axios.$delete(ENDPOINTS.CUSTOMER_ADDRESS_BOOK_ADDRESS(addressId));

export const customerLogin = async (
  $axios: NuxtAxiosInstance,
  data: ApiLoginRequest
): Promise<ApiCustomerResponse> =>
  await $axios.$post(
    ENDPOINTS.CUSTOMER_AUTH_LOGIN,
    {
      username: data.username,
      password: data.password,
    },
    {
      headers: { "X-Recaptcha-Token": data.recaptchaToken },
    }
  );

export const customerLogout = async (
  $axios: NuxtAxiosInstance,
  data: any
): Promise<AxiosResponse<any>> =>
  await $axios.$post(ENDPOINTS.CUSTOMER_AUTH_LOGOUT, data);

export const customerRegister = async (
  $axios: NuxtAxiosInstance,
  data: any
): Promise<ApiCustomerResponse> =>
  await $axios.$post(ENDPOINTS.CUSTOMER_AUTH_REGISTER, data);

export const guestCustomerRegister = async (
  $axios: NuxtAxiosInstance,
  data: ApiGuestCustomerAuthRegisterRequest
): Promise<ApiCustomerResponse> =>
  await $axios.$post(ENDPOINTS.CUSTOMER_AUTH_GUEST_REGISTER, data);

export const convertGuestToCustomer = async (
  $axios: NuxtAxiosInstance,
  customerId: string,
  data: ApiUpdateGuestCustomerRequest
): Promise<ApiCustomerResponse> =>
  await $axios.$patch(
    ENDPOINTS.CUSTOMER_AUTH_CONVERT_GUEST_TO_CUSTOMER(customerId),
    data
  );

export const sendCustomerResetPasswordEmail = async (
  $axios: NuxtAxiosInstance,
  data: ApiCustomerResetPasswordEmailRequest
): Promise<AxiosResponse<any>> =>
  await $axios.$post(ENDPOINTS.CUSTOMER_AUTH_RESET_PASSWORD_EMAIL, data);

export const resetCustomerPassword = async (
  $axios: NuxtAxiosInstance,
  data: ApiCustomerResetPasswordRequest
): Promise<AxiosResponse<any>> =>
  await $axios.$post(ENDPOINTS.CUSTOMER_AUTH_RESET_PASSWORD, data);

export const updateLoyaltyAccountStatus = async (
  $axios: NuxtAxiosInstance,
  customerId: string,
  enabled: boolean
): Promise<AxiosResponse<any>> =>
  await $axios.$put(ENDPOINTS.CUSTOMER_LOYALTY_CLUB(customerId), { enabled });

export const getLoyaltyAccountLink = async (
  $axios: NuxtAxiosInstance,
  customerId: string
): Promise<AxiosResponse<any>> =>
  await $axios.$get(ENDPOINTS.CUSTOMER_LOYALTY_CLUB_LINK(customerId));
