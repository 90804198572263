import { NuxtAxiosInstance } from "@nuxtjs/axios";
import { SavedList } from "./saved-lists";
import {
  createCustomerSavedList,
  createSavedListFromTrolleyId,
  getCustomerSavedLists,
} from "~/services/api/customers.api";
import {
  createSavedListsItems,
  deleteSavedList,
  deleteSavedListsItemsProduct,
  updateSavedList,
} from "~/services/api/savedLists.api";
import { camelizeKeys } from "~/utils/utils";

export const fetchCustomerSavedLists = async (
  $axios: NuxtAxiosInstance,
  customerId: string
): Promise<SavedList[]> =>
  await getCustomerSavedLists($axios, customerId)
    .then((response) => response.data)
    .then(camelizeKeys);

export const createSavedList = async (
  $axios: NuxtAxiosInstance,
  customerId: string,
  name: string
): Promise<SavedList> =>
  await createCustomerSavedList($axios, customerId, {
    name,
  })
    .then((response) => response.data)
    .then(camelizeKeys);

export const quickTrolleyFromTrolleyId = async (
  $axios: NuxtAxiosInstance,
  trolleyId: string
): Promise<SavedList> =>
  await createSavedListFromTrolleyId($axios, trolleyId)
    .then((response) => response.data)
    .then(camelizeKeys);

export const removeSavedList = async (
  $axios: NuxtAxiosInstance,
  listRef: string
): Promise<boolean> => await deleteSavedList($axios, listRef).then(() => true);

export const updateASavedList = async (
  $axios: NuxtAxiosInstance,
  listRef: string,
  name: string
): Promise<SavedList> =>
  await updateSavedList($axios, listRef, {
    name,
  })
    .then((response) => response.data)
    .then(camelizeKeys);

export const addItemToSavedList = async (
  $axios: NuxtAxiosInstance,
  listRef: string,
  productCode: string
): Promise<SavedList> =>
  await createSavedListsItems($axios, listRef, {
    product_code: productCode,
    quantity: 1,
  })
    .then((response) => response.data)
    .then(camelizeKeys);

export const removeItemFromSavedList = async (
  $axios: NuxtAxiosInstance,
  listRef: string,
  productCode: string
): Promise<SavedList> =>
  await deleteSavedListsItemsProduct($axios, listRef, productCode)
    .then((response) => response.data)
    .then(camelizeKeys);
