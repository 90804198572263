import { memoizeWith } from "ramda";
import type { Location } from "~/types/branch";

const autocompleteService = memoizeWith(String, (key: string) => {
  return new (window as any).woosmap.localities.AutocompleteService(key);
});

export const getPostcodeDetails = async (
  key: string,
  placeId: string
): Promise<any> => {
  const autocompleteService = new (
    window as any
  ).woosmap.localities.AutocompleteService(key);

  return await new Promise((resolve, reject) => {
    autocompleteService.getDetails(
      placeId,
      (detailsPrediction: any) => {
        resolve(detailsPrediction);
      },
      reject
    );
  });
};

export const getPostcodeAutocompleteData = async (
  key: string,
  input: string,
  country: string[]
): Promise<{ localities: Location[] | null }> => {
  return await new Promise((resolve, reject) => {
    autocompleteService(key).autocomplete(
      { input, components: { country } },
      (response: { localities: Location[] }) => {
        resolve(response);
      },
      (error: Error) => reject(error)
    );
  });
};

export interface LocationDetail {
  geometry: {
    location: { lat: string; lng: string };
  };
}
export const getDetails = async (
  key: string,
  publicId: string
): Promise<LocationDetail> =>
  await new Promise((resolve, reject) => {
    autocompleteService(key).getDetails(publicId, resolve, reject);
  });

export default { getPostcodeAutocompleteData, getDetails };
