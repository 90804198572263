import { concat, isNil } from "ramda";
import { Context } from "@nuxt/types/app";

export const fromToolstationCdnUrl = (
  url: string,
  $config: Context["$config"] | null = null
): string =>
  concat(
    (!isNil($config)
      ? $config.toolstationCdnURL
      : process.env.NUXT_TOOLSTATION_CDN_URL) ?? ("" as string),
    `/${url}`
  );
