<template functional>
  <svg viewBox="0 0 24 24" fill="none" :class="props.classes">
    <path
      d="M21.8105 19.5533L16.7986 14.523C18.9255 11.3239 18.5043 7.07235 15.7878 4.35727C14.2506 2.84188 12.229 2 10.06 2C7.89102 2 5.86944 2.84188 4.35325 4.35727C1.21558 7.51434 1.21558 12.6288 4.35325 15.7648C7.06976 18.4799 11.3235 18.9008 14.5243 16.7961L19.5573 21.8264C19.7889 22.0579 20.189 22.0579 20.4206 21.8264L21.8105 20.4373C22.0632 20.1847 22.0632 19.7848 21.8105 19.5533ZM13.9347 13.9337C11.8078 16.0595 8.33325 16.0595 6.18531 13.9337C4.05844 11.8079 4.05844 8.33518 6.18531 6.18837C8.31219 4.06262 11.7868 4.06262 13.9347 6.18837C16.0616 8.33518 16.0616 11.8079 13.9347 13.9337Z"
      fill="currentColor"
    />
  </svg>
</template>

<script>
export default {
  name: "SearchIcon",
  props: {
    classes: {
      type: String,
      required: false,
      default: "",
    },
  },
};
</script>
