export default (_context: any, inject: Function): void => {
  const $a11yKeyEnter = (event: KeyboardEvent, callback: Function): void => {
    const { key } = event;
    if (key === "Enter") {
      callback();
    }
  };
  // Inject $a11yKeyEnter() in Vue, context and store.
  inject("a11yKeyEnter", $a11yKeyEnter);
};
