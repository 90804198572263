
import Vue from "vue";
import { mapGetters, mapMutations } from "vuex";
import { pluck } from "ramda";
import BaseModal from "~/components/UI/modals/BaseModal.vue";
import DefaultButton from "~/components/UI/button/DefaultButton.vue";
import ModalMixin from "~/components/UI/modals/messaging-modal/ModalMixin";
import LoadingSpinner from "~/components/UI/loading/LoadingSpinner.vue";
import NextBusinessDayCollection from "~/components/UI/alerts/NextBusinessDayCollection.vue";
import ProductRecommendations from "~/components/product-recommendations/ProductRecommendations.vue";
import { TrolleyChannel } from "~/services/trolleys.service";
import { fetchMonetateOmniChannelProductRecs } from "~/services/monetate.service";
import { ProductRecommendation } from "~/types/productRecommendation";
import PromptMessage from "~/components/UI/banners/PromptMessage.vue";
import {
  getGoogleRecommendations,
  getRecommendations,
} from "~/services/search.service";
import { AppCookieNames } from "~/constants/ecomApi";
import {
  clubPrompt,
  ClubPromptLocations,
} from "~/utils/toolstation-club/index";

export default Vue.extend({
  name: "AddedToTrolleyModal",
  components: {
    BaseModal,
    DefaultButton,
    LoadingSpinner,
    NextBusinessDayCollection,
    ProductRecommendations,
    PromptMessage,
  },
  mixins: [ModalMixin],
  props: {
    show: {
      default: false,
      type: Boolean,
    },
  },
  data(): {
    showProductRecs: boolean;
    productRecommendations: {
      data: ProductRecommendation[];
      tracking: any;
    };
    recsTitle: string;
    recsProvider: string;
    recsShowAddToTrolley: boolean;
    recommendationsMessage: string;
  } {
    return {
      showProductRecs: false,
      productRecommendations: {
        data: [],
        tracking: {},
      },
      recsTitle: "",
      recsProvider: "monetate",
      recsShowAddToTrolley: true,
      recommendationsMessage: "",
    };
  },
  computed: {
    ...mapGetters("auth", ["getUser", "isAuthenticated"]),
    ...mapGetters("branch", ["selectedBranchName"]),
    ...mapGetters("trolley", [
      "lastTrolleyUpdate",
      "lastTrolleyUpdateChannel",
      "loading",
      "trolleyUpdateError",
      "lastAddedProduct",
    ]),
    isCollection(): boolean {
      return this.lastTrolleyUpdateChannel === TrolleyChannel.COLLECTION;
    },
    isNextBusinessDay(): boolean {
      return (
        this.lastTrolleyUpdateChannel === TrolleyChannel.NEXT_DAY_COLLECTION
      );
    },
    addedToTrolleyModalTitle(): string {
      if (this.isCollection)
        return this.$tc("trolleyStr.itemsAdded", 0, {
          storeName: this.selectedBranchName,
        });
      if (this.isNextBusinessDay) return this.$tc("trolleyStr.nextDayAdded");
      return this.$tc("trolleyStr.deliveryAdded");
    },
    productRecommendationsKey(): string {
      return pluck("pid", this.productRecommendations.data).join();
    },
    clubPrompt() {
      return clubPrompt(
        this.$nuxt.context,
        ClubPromptLocations.AddToTrolleyModal
      );
    },
  },
  watch: {
    async "$store.state.trolley.lastTrolleyUpdateProductCodes"(
      lastTrolleyUpdateProductCodes
    ) {
      if (
        !lastTrolleyUpdateProductCodes.length ||
        !this.$config.addToCartRecommendations
      )
        return;

      const lastTrolleyUpdate =
        this.$store.getters["trolley/lastTrolleyUpdate"][0];

      if (lastTrolleyUpdate?.product) {
        const { quantity, product_code } = lastTrolleyUpdate;
        const { addToCart } =
          this.$store.getters["featureFlags/getFeatureFlags"]
            ?.recommendationWidgets || {};

        this.recsTitle = addToCart.title;
        this.recsShowAddToTrolley = addToCart.showAddToTrolley;

        if (addToCart.enabled) {
          this.showProductRecs = true;

          if (addToCart?.provider === "google") {
            const visitorId = this.$cookies.get(AppCookieNames.TsSessionId);
            const loggedInCustomer = this.$store.getters["auth/getUser"];
            const recsModel =
              addToCart?.modelId || "frequently-bought-together";

            this.recsProvider = "google";

            this.productRecommendations = {
              data: await getGoogleRecommendations(
                this.$nuxt.context,
                recsModel,
                {
                  eventType: "add-to-cart",
                  visitorId,
                  ...(loggedInCustomer
                    ? {
                        userInfo: { userId: loggedInCustomer.id },
                      }
                    : {}),
                  productDetails: [
                    {
                      product: { id: product_code },
                      quantity,
                    },
                  ],
                  attributes: { gtmSchema: { text: ["CLOUD_RETAIL"] } },
                }
              ),
              tracking: {
                provider: "google",
                modelId: recsModel,
                location: "add-to-cart-modal",
              },
            };
          } else if (addToCart?.provider === "bloomreach") {
            const itemId = addToCart.modelId || "yjqq5dj7";
            this.productRecommendations = await getRecommendations(
              this,
              "item",
              itemId,
              { itemIds: product_code }
            );

            this.recsProvider = "bloomreach";

            this.productRecommendations.tracking = {
              ...this.productRecommendations.tracking,
              provider: "bloomreach",
              location: "add-to-cart-modal",
              modelId: itemId,
            };
          } else {
            this.recsProvider = "monetate";
            this.productRecommendations = {
              data:
                (await fetchMonetateOmniChannelProductRecs(
                  this.$nuxt.context,
                  [],
                  [this.lastAddedProduct],
                  addToCart?.modelId || "add to cart"
                ).catch((err: any) => console.log(err))) || [],
              tracking: {
                provider: "monetate",
                location: "add-to-cart-modal",
              },
            };
          }
        }
      }
    },
  },

  methods: {
    ...mapMutations("trolley", ["clearAddedToTrolleyModal"]),
    goToCheckout(): void {
      (this as any).clearAddedToTrolleyModal();
      this.$router.push("/checkout/delivery");
    },
    goToTrolley(): void {
      (this as any).clearAddedToTrolleyModal();
      this.$router.push("/trolley");
    },
  },
});
