import { isEmpty, isNil } from "ramda";
import { ActionTree, GetterTree, MutationTree } from "vuex/types/index";
import { RootState } from "./index";
import { companySearch, banksList } from "~/services/procard.service";

interface ProCardBanks {
  Bic: string;
  Name: string;
}

interface ProCardCompaniesState {
  companiesSearchItems: any[];
  banks: ProCardBanks[];
}

export const state = (): object => ({
  companiesSearchItems: [],
  banks: [],
});

export const mutations: MutationTree<ProCardCompaniesState> = {
  SET_COMPANIES: (state, items): void => {
    state.companiesSearchItems = items;
  },
  SET_BANKS: (state, items): void => {
    state.banks = items;
  },
};

export const getters: GetterTree<ProCardCompaniesState, RootState> = {
  getCompanyItems: (state: ProCardCompaniesState): any[] =>
    state.companiesSearchItems,
  getBanks: (state: ProCardCompaniesState): ProCardBanks[] => state.banks,
};

const mapCompanies = (apiResponse: any): any[] => {
  if (isNil(apiResponse) || isEmpty(apiResponse)) {
    return [];
  }

  const data = apiResponse.matchCandidates.map((item: any) => {
    const { primaryAddress: addr, registrationNumbers } = item.organization;

    return {
      duns: item.organization.duns,
      companyName: item.organization.primaryName,
      companyAddressFull: `${addr.streetAddress.line1 as string}, ${addr.postalCode as string}, ${addr.addressLocality.name as string} ${addr.addressCountry.name as string}`,
      companyAddressStreet: addr.streetAddress.line1,
      companyAddressPostalcode: addr.postalCode,
      companyAddressCity: addr.addressLocality.name,
      companyAddressCountryISO: addr.addressCountry.isoAlpha2Code,
      companyInfo: {
        registrationNumber: `${registrationNumbers[0]?.registrationNumber as string}`,
      },
    };
  });

  return data;
};

const mapBanks = (apiResponse: any[]): object[] => {
  if (isNil(apiResponse) || isEmpty(apiResponse)) {
    return [];
  }

  const data = apiResponse.map((item: any) => {
    return {
      value: item.Bic,
      text: item.Name,
    };
  });

  return data;
};

export const actions: ActionTree<ProCardCompaniesState, RootState> = {
  async fetchCompaniesSearch({ commit }, searchOptions): Promise<any[]> {
    let data;

    try {
      const apiResponse = await companySearch(
        this.$axios,
        searchOptions.registrationNumber,
        searchOptions.companyName,
        searchOptions.countryCode
      );

      data = mapCompanies(apiResponse);
    } catch (error: any) {
      return await Promise.reject(error);
    }

    if (data.length === 0) {
      return await Promise.reject(
        new Error("No results found, try another search criteria!")
      );
    }

    commit("SET_COMPANIES", data);

    return await Promise.resolve(data);
  },
  async fetchBanks({ commit }): Promise<object> {
    let data;

    try {
      const apiResponse = await banksList(this.$axios);

      data = mapBanks(apiResponse);
    } catch (error: any) {
      return await Promise.reject(error);
    }

    if (data.length === 0) {
      return await Promise.reject(new Error("No bank results found!"));
    }

    commit("SET_BANKS", data);

    return await Promise.resolve(data);
  },
};

export default {
  state,
  mutations,
  getters,
  actions,
};
