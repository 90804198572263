/*
 *
 * This file contains the generic configs that power certain features
 *
 */

export const state = () => ({
  // Trolley
  allowOutOfStock: false,
  allowNextDay: true,
});

export const mutations = {};

export const getters = {
  getAllowOutOfStock: (state) => state.allowOutOfStock,
  getAllowNextDay: (state) => state.allowNextDay,
};

export const actions = {};

export default {
  state,
  mutations,
  getters,
  actions,
};
