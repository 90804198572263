import * as Noibu from "noibu-vue";

export default ({ app, store }) => {
  app.config = {
    errorHandler: (_err) => {
      console.error(_err);
    },
  };

  // The Noibu handler gets initialized and will
  // call the original handler as well.
  Noibu.initNoibuSDK({ apps: [app] });

  if (process.client) {
    const id = store.getters["auth/getUserId"];
    if (id && window.NOIBUJS)
      window.NOIBUJS.addCustomAttribute("customerID", id);
  }
};
