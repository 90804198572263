<template functional>
  <svg
    :class="props.classes"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
  >
    <path
      d="M15.9724 13.0421C14.7332 12.6821 9.01682 12.6821 7.7776 13.0421C5.51904 13.6863 4 15.6947 4 17.9305V19.901C4 20.5074 4.51967 21 5.15926 21H18.5907C19.2303 21 19.75 20.5074 19.75 19.901V17.9305C19.75 15.6947 18.231 13.6863 15.9724 13.0421Z"
      fill="currentColor"
    />
    <path
      d="M11.875 11.5263C14.3587 11.5263 16.3721 9.61764 16.3721 7.26316C16.3721 4.90868 14.3587 3 11.875 3C9.39125 3 7.37781 4.90868 7.37781 7.26316C7.37781 9.61764 9.39125 11.5263 11.875 11.5263Z"
      fill="currentColor"
    />
  </svg>
</template>

<script>
export default {
  name: "UserIcon",
  props: {
    classes: {
      type: String,
      required: false,
      default: "",
    },
  },
};
</script>
