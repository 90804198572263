import { lte, type } from "ramda";
import { ProductStock } from "~/services/products.service.d";

export const stockForProductCodeAndSite = (
  stockLevels: ProductStock[],
  code: string,
  siteId: string
): ProductStock | undefined => {
  return stockLevels.find(
    ({ productCode, siteId: stockSiteId }) =>
      productCode === code && stockSiteId === siteId
  );
};

export const maskQuantity = (value: string | number) => {
  const mask = (value: number) => (lte(value, 20) ? value : "20+");

  return type(value) === "String"
    ? mask(parseInt(value as string))
    : mask(value as number);
};

export const anyStockForProductCode = (
  stockLevels: ProductStock[],
  code: string
): boolean =>
  stockLevels.some(
    ({ productCode, stockQty }) => productCode === code && stockQty !== "0"
  );
