<template functional>
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    :class="props.classes"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M5 9.48098C5 5.90163 7.96905 3 11.6316 3C15.2941 3 18.2632 5.90163 18.2632 9.48098C18.2632 13.1529 14.0909 18.5729 12.3648 20.657C11.9859 21.1143 11.2773 21.1143 10.8983 20.657C9.17221 18.5729 5 13.1529 5 9.48098ZM8.78947 9.63158C8.78947 11.2016 10.0616 12.4737 11.6316 12.4737C13.2016 12.4737 14.4737 11.2016 14.4737 9.63158C14.4737 8.0616 13.2016 6.78947 11.6316 6.78947C10.0616 6.78947 8.78947 8.0616 8.78947 9.63158Z"
      fill="currentColor"
    />
  </svg>
</template>

<script>
export default {
  name: "MarkerIcon",
  props: {
    classes: {
      type: String,
      required: false,
      default: "",
    },
  },
};
</script>
