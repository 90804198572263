var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"grid grid-cols-2 gap-7"},_vm._l((_vm.data.data),function(recommendation,index){return _c(_vm.cardType,{key:index,tag:"component",attrs:{"image-url":recommendation.thumbImage,"title":recommendation.title,"reviews":recommendation.tsReviews || 0,"gross-price":_vm.getPrice(recommendation, 'gross'),"net-price":_vm.getPrice(recommendation, 'net'),"saving-value":_vm.getSavingValue(recommendation),"product":recommendation,"cta":_vm.cta,"show-flash":_vm.showFlash,"show-add-to-trolley":_vm.showAddToTrolley,"badge-icons":_vm.showBadges
        ? {
            brand: {
              imageUrl: _vm.getBadgeUrl(recommendation.brand || '', 'brand'),
              altText: recommendation.brand || '',
            },
            badge: {
              imageUrl: _vm.getBadgeUrl(recommendation.assettr || '', 'badge'),
              altText: recommendation.brand || '',
            },
          }
        : {}},on:{"trackClickPixel":_vm.trackClick}})}),1)
}
var staticRenderFns = []

export { render, staticRenderFns }