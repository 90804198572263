import { NuxtAxiosInstance } from "@nuxtjs/axios";
import { dissoc } from "ramda";
import { DeliveryMethodCodes } from "~/constants/ecomApi";
import {
  checkTrolleyDiscounts,
  createTrolleyLines,
  createTrolleyPromoCodes,
  createTrolleyShareByEmail,
  deleteTrolleyLine,
  deleteTrolleyPromoCode,
  getTrolley,
  getTrolleyDeliveryMethods,
  trolleyConvertToCustomer,
  trolleyOrderTotals,
  updateTrolleyLine,
  updateTrolleyLineDeliveryMethod,
  updateTrolleyLinesChannel,
  updateTrolleyLinesQuantity,
  resetDeliveryMethods,
} from "~/services/api/trolleys.api";
import {
  ApiDeliveryMethod,
  ApiTrolleyData,
  DeliveryMethodAddressIds,
} from "~/services/api/trolleys.api.d";
import { DiscountData, RawTrolleyData, TrolleyTotals } from "~/types/trolley";

export enum TrolleyChannel {
  DELIVERY = 1,
  COLLECTION = 2,
  DIRECTSHIP = 3,
  NEXT_DAY_COLLECTION = 4,
  SAME_DAY_DELIVERY = 5,
}

export enum DeliveryMethods {
  NEXT_BUSINESS_DAY = "00004",
  SATURDAY_DELIVERY = "00040",
  PRE_DELIVERY = "00007",
  NEXT_BUSINESS_DAY_WEEKEND = "00005",
  FREE_SUPPLIER_DELIVERY = "00033",
  FREE_REMOTE_DELIVERY = "00014",
  REMOTE_DELIVERY = "00015",
}

export const createGuestTrolley = async (
  axios: NuxtAxiosInstance
): Promise<any> => await axios.$post("/customers/guest/trolleys");

export const addItemToTrolley = async (
  axios: NuxtAxiosInstance,
  trolleyId: string,
  payload: {
    product_code: string;
    quantity: number;
    channel: TrolleyChannel;
    delivery_method_code: DeliveryMethodCodes;
  }
): Promise<AxiosResponse<RawTrolleyData>> =>
  await createTrolleyLines(axios, trolleyId, payload);

export const updateLineItemQuantity = async (
  axios: NuxtAxiosInstance,
  trolleyLineId: number,
  quantity: number
): Promise<AxiosResponse<RawTrolleyData>> =>
  await updateTrolleyLinesQuantity(axios, trolleyLineId, { quantity });

export const updateLineItem = async (
  axios: NuxtAxiosInstance,
  trolleyLineId: number,
  payload: { quantity?: number; channel?: number; deliveryCode?: string }
): Promise<AxiosResponse<RawTrolleyData>> => {
  if (!payload.quantity) payload = dissoc("quantity", payload);
  if (!payload.channel) payload = dissoc("channel", payload);
  if (!payload.deliveryCode) payload = dissoc("deliveryCode", payload);

  return await updateTrolleyLine(axios, trolleyLineId, payload);
};

export const getTrolleyById = async (
  axios: NuxtAxiosInstance,
  id: string
): Promise<ApiTrolleyData | null> =>
  await getTrolley(axios, id).then((trolley) => {
    if (trolley.data.status !== 1) throw new Error("Invalid trolley status");

    return trolley;
  });

export const fetchTrolleyOrdersTotal = async (
  axios: NuxtAxiosInstance,
  trolleyId: string,
  staffDiscountToken?: string
): Promise<AxiosResponse<TrolleyTotals>> =>
  await trolleyOrderTotals(axios, trolleyId, staffDiscountToken);

export const convertGuestTrolleyToCustomerTrolley = async (
  axios: NuxtAxiosInstance,
  trolleyId: string,
  customerId: string
): Promise<AxiosResponse<RawTrolleyData>> =>
  await trolleyConvertToCustomer(axios, trolleyId, { customer_id: customerId });

export const deleteTrolleyLineById = async (
  axios: NuxtAxiosInstance,
  trolleyLineId: number
): Promise<AxiosResponse> =>
  await deleteTrolleyLine(axios, trolleyLineId).then(
    (response) => response.data
  );

export const updateLineItemQuantityById = async (
  axios: NuxtAxiosInstance,
  trolleyLineId: number,
  quantity: number
): Promise<AxiosResponse> =>
  await updateTrolleyLinesQuantity(axios, trolleyLineId, {
    quantity,
  }).then((response) => response.data);

export const updateLineItemChannel = async (
  axios: NuxtAxiosInstance,
  trolleyLineId: number,
  channel: number
): Promise<AxiosResponse> =>
  await updateTrolleyLinesChannel(axios, trolleyLineId, {
    channel,
  }).then((response) => response.data);

export const updateLineItemDeliveryMethod = async (
  axios: NuxtAxiosInstance,
  trolleyLineId: number,
  deliveryMethodCode: string
): Promise<AxiosResponse> =>
  await updateTrolleyLineDeliveryMethod(axios, trolleyLineId, {
    delivery_method_code: deliveryMethodCode,
  }).then((response) => response.data);

export const setDeliveryToCollection = async (
  axios: NuxtAxiosInstance,
  trolleyId: string
): Promise<AxiosResponse> =>
  await resetDeliveryMethods(axios, trolleyId).then(
    (response) => response.data
  );

export const addPromoCodeToTrolley = async (
  axios: NuxtAxiosInstance,
  trolleyId: string,
  payload: {
    code: string;
  }
): Promise<AxiosResponse<RawTrolleyData>> =>
  await createTrolleyPromoCodes(axios, trolleyId, payload);

export const deletePromoCodeFromTrolley = async (
  axios: NuxtAxiosInstance,
  promoCodeId: string
): Promise<AxiosResponse<RawTrolleyData>> =>
  await deleteTrolleyPromoCode(axios, promoCodeId);

export const trolleyDeliveryMethods = async (
  axios: NuxtAxiosInstance,
  trolleyId: string,
  addressIds: DeliveryMethodAddressIds
): Promise<ApiDeliveryMethod[]> =>
  await getTrolleyDeliveryMethods(axios, trolleyId, addressIds)
    .then((res) => res.data)
    .catch((res) => {
      console.error(res);
      return [];
    });

/**
 * This is happening 2 times on load of trolley page
 * - Trolley preview
 * - Trolley load event
 *
 * @param axios
 * @param trolleyId
 */
export const checkTrolleyDiscount = async (
  axios: NuxtAxiosInstance,
  trolleyId: string,
  discountToken?: string,
  branchId?: string
): Promise<DiscountData | null> =>
  await checkTrolleyDiscounts(axios, trolleyId, discountToken, branchId).then(
    (response) => response?.data
  );

/**
 * Share a trolley with a customer email
 *
 * @param $axios
 * @param trolleyId
 * @param email
 */
export const shareTrolleyByEmail = async (
  $axios: NuxtAxiosInstance,
  trolleyId: string,
  email: string
): Promise<boolean> =>
  await createTrolleyShareByEmail($axios, trolleyId, {
    recipient_email: email,
  }).then((response) => response.status === 204);
