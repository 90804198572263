
import Vue from "vue";
import type { PropType } from "vue";
import type { AttributeSuggestion } from "~/types/searchBar";
import SearchBarSuggestionRow from "~/components/UI/search/SearchBarSuggestionRow.vue";
import { TrackingEvents } from "~/store/tracking";

export default Vue.extend({
  name: "DepartmentItems",

  components: {
    SearchBarSuggestionRow,
  },

  props: {
    items: {
      type: Array as PropType<AttributeSuggestion[]>,
      default: () => [],
    },
  },

  methods: {
    querySuggestionClicked(item: AttributeSuggestion) {
      this.$store.commit("tracking/addTrackingEvent", {
        type: TrackingEvents.SearchSuggestionClicked,
        data: { type: "category", item },
      });
    },
    getPath(value: string) {
      if (/c\d{1,4}/.test(value)) {
        return `/c/c/${value}`;
      }
      return `/brands/${value}`;
    },
  },
});
