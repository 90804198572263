interface AppConfig {
  prompt: boolean;
  localePrompt: string[];
}

export interface iosConfig extends AppConfig {
  id: string;
}

export interface androidConfig extends AppConfig {
  playStoreUrl: string;
}

export const ios: iosConfig = {
  prompt: true,
  id: "1535789340",
  localePrompt: ["en-GB"],
};

export const android: androidConfig = {
  prompt: true,
  playStoreUrl:
    "https://play.google.com/store/apps/details?id=com.toolstation.mobile_app",
  localePrompt: ["en-GB"],
};

export const isAllowed = (
  locale: string,
  config: iosConfig | androidConfig
): boolean => config.prompt && config.localePrompt.includes(locale);

export default {
  ios,
  android,
};
