export enum IconPositions {
  None = "none",
  Top = "top",
  Left = "left",
  Right = "right",
}

export enum NavType {
  Compact = "compact",
}

export default { IconPositions, NavType };
