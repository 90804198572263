import type { Context } from "@nuxt/types";
import { identity, includes, isEmpty, isNil, not } from "ramda";
import { timeNowInSeconds } from "../utils/date";
import EcomApi from "~/constants/ecomApi";
import { accessTokenRequiresRefresh } from "~/services/accessToken";

const validUnauthorisedRequestUrls = ["customers/auth/login"];

export default (context: Context): void => {
  const { $axios, $config, store } = context;

  // Fallback as this should not occur. Token is continually refreshed.
  $axios.onError(async (error) => {
    if (error.response?.status === 401) {
      const accessToken = store.getters["ecomApi/getAccessToken"];
      const expiresTime = store.getters["ecomApi/getExpiresTime"];

      if (
        process.client &&
        accessTokenRequiresRefresh(
          accessToken,
          expiresTime,
          $config.ecomApiPollBuffer
        )
      ) {
        location.reload();
      }
    }

    const customerJwtToken = store.getters["ecomApi/getCustomerJwtToken"];
    const sessionJwtToken = store.getters["guest/guestCheckoutSession"];

    const validUnauthorisedRequestUrl = not(
      includes(error.request.path, validUnauthorisedRequestUrls)
    );

    if (
      error.response?.status === 403 &&
      validUnauthorisedRequestUrl &&
      (customerJwtToken || sessionJwtToken)
    ) {
      if (sessionJwtToken) {
        await store.dispatch("trolley/deleteAxiosSessionHeader");
      }

      if (customerJwtToken) {
        await store.dispatch("auth/clearAuthenticatedUser", context);

        if (
          window.location.pathname !== "/login" &&
          window.location.pathname !== "/trade-credit/apply" &&
          process.client
        ) {
          location.reload();
        }
      }
    }

    return Promise.reject(error);
  });

  // Log out every type of error to the console
  $axios.interceptors.response.use(identity, function (error) {
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    if (error?.response) {
      // eslint-disable-next-line no-throw-literal
      throw {
        referer: error.response?.config?.headers?.referer,
        method: error.response?.config?.method,
        baseURL: error.response?.config?.baseURL,
        url: error.response?.config?.url,
        status: error.response?.status,
        statusText: error.response?.statusText,
        data: error.response?.data,
      };
    }
  });

  // Axios is being setup in authenticated middleware

  // Set up axios for front-end
  if (process.client) {
    if (!isEmpty($config.ecomApiDomain) && !isEmpty($config.ecomApiVersion)) {
      $axios.setBaseURL(
        `${$config.ecomApiDomain as string}${$config.ecomApiVersion as string}`
      );
    }

    const accessToken = store.getters["ecomApi/getAccessToken"];

    if (!isNil(accessToken)) {
      $axios.setToken(accessToken, "Bearer");
    }

    const customerJwtToken = store.getters["ecomApi/getCustomerJwtToken"];

    if (!isNil(customerJwtToken)) {
      $axios.setHeader("X-Toolstation-Customer-Id", customerJwtToken);
    }

    // Poll token expiry.
    setInterval(checkToken, $config.ecomApiPollInterval * 1000, context);
  }

  if (process.server) {
    if (!isEmpty($config.internalEcomApiUrl)) {
      $axios.setBaseURL(`${$config.internalEcomApiUrl as string}`);
    }
  }
};

const checkToken = (context: Context): void => {
  const { $config, $cookies, $axios, store } = context;

  const accessToken = store.getters["ecomApi/getAccessToken"];
  const expiresTime = store.getters["ecomApi/getExpiresTime"];

  // Check if token is about to expire.
  if (
    accessTokenRequiresRefresh(
      accessToken,
      expiresTime,
      $config.ecomApiPollBuffer
    )
  ) {
    $axios
      .get(`${location.origin}/refresh-token`)
      .then((accessTokenDataResponse: any) => {
        // Set data.
        const accessTokenData = {
          ...accessTokenDataResponse.data,
          generated: timeNowInSeconds(),
        };

        // Calculate the expiry time.
        accessTokenData.expiresTime =
          (accessTokenData.generated as number) +
          parseInt(accessTokenData.expiresIn, 10);

        // Persist in cookie.
        $cookies.set(EcomApi.ECOM_API_COOKIE_NAME, accessTokenData, {
          secure: true,
        });

        // Set store.
        store.commit("ecomApi/setAccessToken", accessTokenData.accessToken);
        store.commit("ecomApi/setExpiresTime", accessTokenData.expiresTime);

        // Set Axios token.
        $axios.setToken(accessTokenData.accessToken, "Bearer");
      })
      .catch(() => {
        // If error, a reload will fetch a new token on the server.
        location.reload();
      });
  }
};
