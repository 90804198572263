
import Vue from "vue";
import { mapMutations, mapGetters } from "vuex";
import LoginForm from "~/components/login/LoginForm.vue";
import BaseModal from "~/components/UI/modals/BaseModal.vue";

export default Vue.extend({
  name: "LoginModal",
  components: {
    BaseModal,
    LoginForm,
  },
  props: {
    show: {
      default: false,
      type: Boolean,
    },
  },
  data() {
    return {
      modalWidth: { maxWidth: "354px" },
    };
  },
  computed: {
    ...mapGetters("auth", ["loginModalVisible"]),
  },
  methods: {
    ...mapMutations("auth", ["setLoginModalVisibility"]),
  },
});
