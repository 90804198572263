export const descriptionConfig = {
  descProductsGreaterThan: 6,
  descriptionExclusions: [
    "departments",
    "priceRange",
    "prices",
    "reviewFilterValue",
  ],
};

export const attributesToRequest = [
  "pid",
  "slug",
  "numberofreviews",
  "title",
  "brand",
  "sale_price",
  "promotion",
  "thumb_image",
  "sku_thumb_images",
  "sku_swatch_images",
  "sku_color_group",
  "url",
  "priceRange",
  "description",
  "formattedPrices",
  "prices",
  "ts_reviews",
  "assettr",
  "name_type",
  "name_qty",
  "variations",
  "price",
  "samedaydelivery",
  "quantitymaximum",
  "quantityminimum",
  "quantitylabel",
  "channel",
  "group_title",
  "sku_count",
  "sku_group_price_range",
  "sku_group_price_range_ex_vat",
  "campaign",
];

export const sortValues = [
  "relevance",
  "price asc",
  "price desc",
  "best_seller desc",
] as const;

export type SortType = (typeof sortValues)[number];

export default {
  descriptionConfig,
  attributesToRequest,
  sortValues,
};
