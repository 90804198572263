import { ActionTree, GetterTree, MutationTree } from "vuex";
import type { Context } from "@nuxt/types";
import { timeNowInSeconds } from "../utils/date";
import EcomApi from "../constants/ecomApi";
import { RootState } from "./index";
import { accessTokenRequiresRefresh } from "~/services/accessToken";
import { AccessTokenApiResponse } from "~/types/accessToken";
import { fetchAccessToken } from "~/services/api/accessToken.api";

interface EComApiState {
  accessToken: string | null;
  customerJwtToken: string | null;
  expiresTime: string | null;
}

export const state = (): EComApiState => ({
  accessToken: null,
  customerJwtToken: null,
  expiresTime: null,
});

export const mutations: MutationTree<EComApiState> = {
  setAccessToken: (state: EComApiState, token: string): void => {
    state.accessToken = token;
  },
  setExpiresTime: (state: EComApiState, expiresTime: string): void => {
    state.expiresTime = expiresTime;
  },
  setCustomerJwtToken: (state: EComApiState, token: string): void => {
    state.customerJwtToken = token;
  },
  deleteCustomerJwtToken: (state: EComApiState): void => {
    state.customerJwtToken = null;
  },
};

export const getters: GetterTree<EComApiState, RootState> = {
  get: (state: EComApiState): EComApiState => state,
  getAccessToken: (state: EComApiState): string | null => state.accessToken,
  getExpiresTime: (state: EComApiState): string => state.expiresTime as string,
  getCustomerJwtToken: (state: EComApiState): string | null =>
    state.customerJwtToken,
};

export const actions: ActionTree<RootState, RootState> = {
  async fetchEcomApiAccessToken({ commit }, context: Context) {
    const { app, $config, $axios } = context;
    const { ecomApiClientId, ecomApiClientSecret, ecomApiDomain } = $config;
    const cookieName = EcomApi.ECOM_API_COOKIE_NAME;

    let accessTokenData = app.$cookies.get(cookieName);

    if (
      accessTokenRequiresRefresh(
        accessTokenData?.accessToken,
        accessTokenData?.expiresTime,
        $config.ecomApiPollBuffer
      )
    ) {
      const accessTokenDataResponse = (await fetchAccessToken($axios, {
        api: ecomApiDomain,
        clientId: ecomApiClientId,
        clientSecret: ecomApiClientSecret,
      })) as unknown as AccessTokenApiResponse;

      // @todo needs typing information
      accessTokenData = {
        accessToken: accessTokenDataResponse.data.access_token,
        expiresIn: accessTokenDataResponse.data.expires_in,
        applicationName: accessTokenDataResponse.data.application_name,
        tokenType: accessTokenDataResponse.data.token_type,
        issuedAt: accessTokenDataResponse.data.issued_at,
        generated: timeNowInSeconds(),
      };

      // calculate the expiry time now
      accessTokenData.expiresTime =
        (accessTokenData.generated as number) +
        parseInt(accessTokenData.expiresIn, 10);

      /*
        save it to check for when we need to refresh
        TODO:
        We're saving this to a cookie for the time being.
        But we want to look at saving this token on the server if we can
        But currently we don't have anywhere to persist this data on the server
        There is some work looking at using Redis for caching, maybe this could
        utilise that as a persistent store for the access tokens?
        */
      app.$cookies.set(cookieName, accessTokenData, { secure: true });
    }

    // chuck accessToken to the client and persist to the store
    commit("setAccessToken", accessTokenData.accessToken);
    commit("setExpiresTime", accessTokenData.expiresTime);

    // Set access token for axios for subsequent ecom api calls
    $axios.setToken(accessTokenData.accessToken, "Bearer");
  },

  deleteCustomerJWT({ commit }, _context: Context) {
    commit("deleteCustomerJwtToken");
  },
};
