<template functional>
  <svg
    aria-hidden="true"
    focusable="false"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 300 300"
    :class="props.classes"
  >
    <path
      fill="currentColor"
      d="M178.6,127.2L290.3,0h-26.5l-97,110.4L89.3,0H0l117.1,166.9L0,300.2h26.5l102.4-116.6l81.8,116.6H300 M36,19.5 h40.7l187.1,262.1h-40.7"
    />
  </svg>
</template>

<script>
export default {
  name: "XIcon",
  props: {
    classes: {
      type: String,
      required: false,
      default: "",
    },
  },
};
</script>
