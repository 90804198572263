// @todo keep an eye out for how we handle attributes. If attribute work is to fall on the
// responsibility of the client (nuxt), then we'll want to ideally transform all the data into a nice
// manner that our app can understand

import { assoc } from "ramda";

export type Boxed<T> = { data: T };
export const toObject = (data: any) => ({ data });

// Associate data with a named record (tag) for use without type information (vue templates)
export type NamedBox<T1, T2> = Boxed<T1> & { type: T2 };
export const namedBoxType = assoc("type");
