import type { NuxtI18nInstance } from "@nuxtjs/i18n";

/**
 * @description Brand pages don't exist as real taxonomies, this returns the taxonomy data as expected by the application
 */
export const getBrandTaxonomy = (
  i18n: NuxtI18nInstance,
  brandId: string,
  brandName: string
) => {
  return {
    ancestors: [
      {
        taxonomy: {
          id: "brands",
          name: "Brands",
          slug: "/brands",
        },
      },
    ],
    taxonomy: {
      id: brandId,
      name: brandName,
      slug: `brands/${brandName}`,
      description: i18n.t("brands.description", {
        brandName,
      }),
      notes: [
        {
          id: 1,
          name: "meta-title",
          value: i18n.t("brands.meta.title", {
            brandName,
          }),
        },
        {
          id: 2,
          name: "meta-description",
          value: i18n.t("brands.meta.description", {
            brandName,
          }),
        },
      ],
    },
  };
};
