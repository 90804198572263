<template functional>
  <svg viewBox="0 0 24 24" fill="none" :class="props.classes">
    <path
      d="M22.1538 7H1.84615C1.62174 7 1.40652 7.08915 1.24783 7.24783C1.08915 7.40652 1 7.62174 1 7.84615V16.3077C1 16.5321 1.08915 16.7473 1.24783 16.906C1.40652 17.0647 1.62174 17.1538 1.84615 17.1538H22.1538C22.3783 17.1538 22.5935 17.0647 22.7522 16.906C22.9109 16.7473 23 16.5321 23 16.3077V7.84615C23 7.62174 22.9109 7.40652 22.7522 7.24783C22.5935 7.08915 22.3783 7 22.1538 7ZM21.3077 15.4615H2.69231V8.69231H21.3077V15.4615ZM4.38462 12.0769C4.38462 11.7422 4.48387 11.415 4.66982 11.1367C4.85577 10.8584 5.12008 10.6415 5.42931 10.5134C5.73853 10.3853 6.0788 10.3518 6.40708 10.4171C6.73535 10.4824 7.03689 10.6436 7.27357 10.8803C7.51024 11.117 7.67142 11.4185 7.73671 11.7468C7.80201 12.075 7.7685 12.4153 7.64041 12.7245C7.51232 13.0338 7.29542 13.2981 7.01712 13.484C6.73882 13.67 6.41163 13.7692 6.07692 13.7692C5.6281 13.7692 5.19765 13.5909 4.88028 13.2736C4.56291 12.9562 4.38462 12.5258 4.38462 12.0769ZM10.3077 12.0769C10.3077 11.7422 10.4069 11.415 10.5929 11.1367C10.7789 10.8584 11.0432 10.6415 11.3524 10.5134C11.6616 10.3853 12.0019 10.3518 12.3302 10.4171C12.6584 10.4824 12.96 10.6436 13.1966 10.8803C13.4333 11.117 13.5945 11.4185 13.6598 11.7468C13.7251 12.075 13.6916 12.4153 13.5635 12.7245C13.4354 13.0338 13.2185 13.2981 12.9402 13.484C12.6619 13.67 12.3347 13.7692 12 13.7692C11.5512 13.7692 11.1207 13.5909 10.8034 13.2736C10.486 12.9562 10.3077 12.5258 10.3077 12.0769ZM16.2308 12.0769C16.2308 11.7422 16.33 11.415 16.516 11.1367C16.7019 10.8584 16.9662 10.6415 17.2755 10.5134C17.5847 10.3853 17.925 10.3518 18.2532 10.4171C18.5815 10.4824 18.883 10.6436 19.1197 10.8803C19.3564 11.117 19.5176 11.4185 19.5829 11.7468C19.6482 12.075 19.6147 12.4153 19.4866 12.7245C19.3585 13.0338 19.1416 13.2981 18.8633 13.484C18.585 13.67 18.2578 13.7692 17.9231 13.7692C17.4742 13.7692 17.0438 13.5909 16.7264 13.2736C16.4091 12.9562 16.2308 12.5258 16.2308 12.0769Z"
      fill="currentColor"
    />
  </svg>
</template>

<script>
export default {
  name: "QuickCodeIcon",
  props: {
    classes: {
      type: String,
      required: false,
      default: "",
    },
  },
};
</script>
