
import Vue from "vue";
import type { PropType } from "vue";

import QueryItems from "./QueryItems.vue";
import DepartmentItems from "./DepartmentItems.vue";
import ProductItems from "./ProductItems.vue";
import type { SearchBarItem } from "~/types/searchBar";

export default Vue.extend({
  name: "AutocompleteItemTemplateSwitch",

  components: {
    QueryItems,
    DepartmentItems,
    ProductItems,
  },

  props: {
    item: {
      type: Object as PropType<SearchBarItem>,
      default: () => ({}),
    },
  },
});
