import { ActionTree, GetterTree, MutationTree } from "vuex/types/index";
import { isEmpty, isNil } from "ramda";
import { RootState } from "./index";
import { NewsletterModalState } from "~/components/layout/footer/footer-top/newsletter-sign-up/types";
import { signup } from "~/services/newsletters";

export const SHOW_ERROR_ACTION = "showError";
export enum NewsletterMutation {
  SetModalState = "setModalState",
  SetErrorMessage = "setErrorMessage",
  SetEmail = "setEmail",
}

export interface NewsletterState {
  modalState: NewsletterModalState;
  errorMessage: string;
  email: string;
}

export const state = (): NewsletterState => ({
  modalState: NewsletterModalState.Closed,
  errorMessage: "",
  email: "",
});

export const getters: GetterTree<NewsletterState, RootState> = {
  getErrorMessage: (state: NewsletterState): string => state.errorMessage,
  getEmail: (state: NewsletterState): string => state.email,
  isErrorState: (state: NewsletterState): boolean =>
    state.modalState === NewsletterModalState.Error,
  isConfirmState: (state: NewsletterState): boolean =>
    state.modalState === NewsletterModalState.Confirm,
  isSuccessState: (state: NewsletterState): boolean =>
    state.modalState === NewsletterModalState.Success,
};

export const mutations: MutationTree<NewsletterState> = {
  [NewsletterMutation.SetModalState]: (
    state: NewsletterState,
    modalState: NewsletterModalState
  ): void => {
    state.modalState = modalState;
  },
  [NewsletterMutation.SetErrorMessage]: (
    state: NewsletterState,
    message: string
  ): void => {
    state.errorMessage = message;
  },
  [NewsletterMutation.SetEmail]: (
    state: NewsletterState,
    email: string
  ): void => {
    state.email = email;
  },
};

export const actions: ActionTree<NewsletterState, RootState> = {
  closeModal({ commit }): void {
    commit(NewsletterMutation.SetModalState, NewsletterModalState.Closed);
  },
  [SHOW_ERROR_ACTION]({ commit }, message: string): void {
    commit(NewsletterMutation.SetErrorMessage, message);
    commit(NewsletterMutation.SetModalState, NewsletterModalState.Error);
  },
  async showConfirmation({ commit, dispatch, state }): Promise<void> {
    if (!isEmpty(state.email) && !isNil(state.email)) {
      commit(NewsletterMutation.SetModalState, NewsletterModalState.Confirm);
    } else {
      await dispatch(SHOW_ERROR_ACTION, "newsletter.errorEmailMessage");
    }
  },
  updateEmail({ commit }, e: InputEvent): void {
    commit(NewsletterMutation.SetEmail, (e.target as HTMLInputElement).value);
  },
  async subscribe({ commit, dispatch, state }): Promise<void> {
    try {
      await signup(this.$axios, state.email);
      commit(NewsletterMutation.SetModalState, NewsletterModalState.Success);
    } catch (error) {
      const errorMessage = (error as Error).message;
      await dispatch(SHOW_ERROR_ACTION, errorMessage);
    }
  },
};
