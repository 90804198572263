
import Vue from "vue";
import { mapGetters } from "vuex";
import LazyHydrate from "vue-lazy-hydration";
import { HeaderLink } from "~/components/layout/header/TheHeader.d";
import { NavType } from "~/components/layout/navbar/types";
import NavbarTop from "~/components/layout/navbar/NavbarTop.vue";
import NavbarBottom from "~/components/layout/navbar/NavbarBottom.vue";
import { headerConfig } from "~/services/config.service";

export default Vue.extend({
  name: "TheHeader",
  components: {
    LazyHydrate,
    TopNavbar: NavbarTop,
    BottomNavbar: NavbarBottom,
    CustomerPreferredBranchModal: () =>
      import(
        /* webpackChunkName: "CustomerPreferredBranchModal" */ "~/components/UI/modals/branch-modal/CustomerPreferredBranchModal.vue"
      ),
  },
  props: {
    appearance: {
      type: String,
      default: "standard",
      validator: (value) => ["standard", "compact"].includes(String(value)),
    },
    bottomNavbarId: {
      type: String,
      default: "bottomNavbar",
    },
  },
  data: () => ({
    NavType,
    getTopNavbarLinks: [],
  }),

  async fetch() {
    await headerConfig(this.$config.localeInstance).then((header) => {
      this.getTopNavbarLinks = header?.topNavbarLinks;
    });
  },

  computed: {
    ...mapGetters({
      getUser: "auth/getUser",
      showBranchModal: "branch/showBranchModal",
      mobileMenuOpen: "mainMenu/mobileMenuOpen",
    }),
    topNavbarLinks(): HeaderLink[] {
      return this.getTopNavbarLinks.filter((link: HeaderLink) => {
        if (this.appearance === this.NavType.Compact) {
          return link.isInCompactView;
        }

        if ("loggedIn" in link) {
          return !!this.getUser === link.loggedIn;
        }
        return true;
      });
    },
  },
  watch: {
    mobileMenuOpen(val) {
      if (val) {
        document.documentElement.classList.add("overflow-hidden");
      } else {
        document.documentElement.classList.remove("overflow-hidden");
      }
    },
  },
});
