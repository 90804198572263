import type { ActionTree, GetterTree, MutationTree } from "vuex/types";
import { RootState } from ".";
import type { ContentMenuItem } from "~/components/UI/menus/ContentMenu.d";
import type { LocaleCode } from "~/utils/i18n";
import { MenuItem } from "~/services/api/content.api.d";
import { getMenuForFooterCompanyInformation } from "~/services/content.service";

export enum ContentMutation {
  SetNewsNavItems = "setContentMenuItems",
}

export enum ContentMenuId {
  Archive = "archive",
  All = "news",
}

interface ContentState {
  newsNav: ContentMenuItem[];
  selectedCategory: string;
}

export const fixedNewsNavItems: ContentMenuItem[] = [
  {
    label: ContentMenuId.Archive,
    href: `/${ContentMenuId.All}/${ContentMenuId.Archive}`,
    category: ContentMenuId.Archive,
  },
];

export const state = (): ContentState => ({
  newsNav: [],
  selectedCategory: ContentMenuId.All,
});

export const mutations: MutationTree<ContentState> = {
  [ContentMutation.SetNewsNavItems]: (
    state: ContentState,
    items: ContentMenuItem[]
  ): void => {
    state.newsNav = [...items];
  },
};

export const getters: GetterTree<ContentState, RootState> = {
  getNewsNav: (state: ContentState): ContentMenuItem[] => state.newsNav,
};

export const actions: ActionTree<ContentState, RootState> = {
  async fetchCompanyInformationNav(
    { commit },
    locale: LocaleCode
  ): Promise<void> {
    const menuItems = await getMenuForFooterCompanyInformation(
      this.$axios,
      locale
    );
    if (menuItems != null) {
      commit(
        ContentMutation.SetNewsNavItems,
        menuItems.map((menuItem: MenuItem) => {
          const category = (menuItem.url?.split("/")?.pop() as string) ?? "";
          return {
            label: menuItem.title,
            href: `/${ContentMenuId.All}/${category}`,
            category,
          };
        })
      );
    }
  },
};
