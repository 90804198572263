import type { Context } from "@nuxt/types";
import taxonomyConfig from "~/utils/config/taxonomy-config";
import { CustomTaxonomyPageNames } from "~/lib/taxonomies/index";

export interface HierarchyCategoryItem {
  cat_id: string;
  cat_name: string;
  count: number;
  crumb: string;
  parent: string;
  tree_path: string;
}

export enum ServerSideFilterNames {
  Category = "category",
}

/**
 * @description Return the URL of the filter name, including all the query params
 */
export const serverSideFilterUrl = (
  context: Context,
  name: string,
  filterName: string
) => {
  // grab the current route query
  const query = context.route.query;

  // If query is empty/undefined, return the URL with the name of the filter
  if (!query || Object.keys(query).length === 0) {
    const url = context.app?.router?.resolve({
      path: context.route.path,
      query: {
        [filterName]: tidyFilterName(name),
      },
    }).href;

    return { href: url };
  }

  // if the current query already has a filter then we don't want to append again
  if (
    !Object.prototype.hasOwnProperty.call(query, filterName) &&
    !Object.prototype.hasOwnProperty.call(query, "page")
  ) {
    const newQuery = {
      ...query,
      [filterName]: tidyFilterName(name),
    };

    // Filter the new query against the allowed params
    const allowedParams = ["q", ...serverSideFilters()];
    const paramsToSet = Object.keys(newQuery)
      .filter((name) => allowedParams.includes(name))
      .sort((a, b) => allowedParams.indexOf(a) - allowedParams.indexOf(b));

    const newQueryObject = paramsToSet.reduce(
      (obj, key) => {
        obj[key] = newQuery[key] as string | string[];
        return obj;
      },
      {} as Record<string, string | string[]>
    );

    // Return the URL with the new query object
    const url = context.app?.router?.resolve({
      path: context.route.path,
      query: newQueryObject,
    }).href;

    return { href: url };
  }
};

/**
 * @description Get's the serverside filter list
 */
export const serverSideFilters = () => {
  return taxonomyConfig.serverSideFilters;
};

/**
 * @description Checks custom taxonomy page urls (brands, campaigns)
 */
export const getNoIndexTaxonomyUrls = (
  name: string,
  query: { [key: string]: string }
) => {
  const isCampaignPage = name === CustomTaxonomyPageNames.campaign;
  const isBrandPageWithQuery =
    name === CustomTaxonomyPageNames.brands && query?.category;

  return isCampaignPage || isBrandPageWithQuery;
};

/**
 * @description Returns true if the filterName exists in the serverSideFilter list
 */
export const isServerSideFilter = (filterName: string) => {
  return !!serverSideFilters().includes(filterName);
};

/**
 * @description Tidy's up the filter name
 */
export const tidyFilterName = (name: string) => {
  if (name?.indexOf(".0") === name?.length - 2) {
    return name.replace(".0", "");
  }
  return name;
};

/**
 * @description Filters and sorts hierarchy categories based on the specified parent category.
 */
export const filterCategories = (
  parent: string,
  categories: HierarchyCategoryItem[],
  returnRootParent?: boolean
): HierarchyCategoryItem[] => {
  return categories
    .filter((category: HierarchyCategoryItem) => {
      if (returnRootParent) {
        return category.parent === "root";
      }
      const catParent = category.parent === "root" ? "" : category.parent; // This prevents cats with the parent of 'root' from being nested within the root (home) category
      return catParent === parent;
    })
    .sort((a: HierarchyCategoryItem, b: HierarchyCategoryItem) => {
      const countDif = b.count - a.count;
      if (countDif !== 0) return countDif;
      if (a.cat_name === b.cat_name) return 0;
      return a.cat_name < b.cat_name ? -1 : 1;
    });
};
