var render = function render(_c,_vm){return _c('div',{class:[
    'w-full bg-white rounded-[1px] border border-transparent shadow',
    _vm.props.morePadding ? 'p-6 md:p-10' : 'px-4 py-5',
    _vm.props.classes ? _vm.props.classes : '',
    {
      'shadow-transparent': _vm.props.hideShadow,
    },
  ]},[_vm._t("default")],2)
}
var staticRenderFns = []

export { render, staticRenderFns }