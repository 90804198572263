
import Vue from "vue";
import type { PropType } from "vue";
import type { QuerySuggestion } from "~/types/searchBar";
import SearchBarSuggestionRow from "~/components/UI/search/SearchBarSuggestionRow.vue";
import { TrackingEvents } from "~/store/tracking";

export default Vue.extend({
  name: "QueryItems",

  components: {
    SearchBarSuggestionRow,
  },

  props: {
    items: {
      type: Array as PropType<QuerySuggestion[]>,
      default: () => [],
    },
  },

  methods: {
    querySuggestionClicked(item: QuerySuggestion) {
      this.$store.commit("tracking/addTrackingEvent", {
        type: TrackingEvents.SearchSuggestionClicked,
        data: { type: "query", item },
      });
    },
  },
});
