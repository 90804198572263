<template functional>
  <svg viewBox="0 0 24 24" fill="none" :class="props.classes">
    <path
      d="M18.1364 3H5.77273C5.56779 3 5.37124 3.08141 5.22633 3.22633C5.08141 3.37124 5 3.56779 5 3.77273V19.2273C5 19.3652 5.03692 19.5006 5.10693 19.6195C5.17694 19.7383 5.27749 19.8363 5.39813 19.9031C5.51878 19.97 5.65512 20.0034 5.79301 19.9997C5.9309 19.9961 6.0653 19.9556 6.18227 19.8825L11.9545 16.2747L17.7268 19.8825C17.8438 19.9556 17.9782 19.9961 18.1161 19.9997C18.254 20.0034 18.3903 19.97 18.511 19.9031C18.6316 19.8363 18.7321 19.7383 18.8022 19.6195C18.8722 19.5006 18.9091 19.3652 18.9091 19.2273V3.77273C18.9091 3.56779 18.8277 3.37124 18.6828 3.22633C18.5378 3.08141 18.3413 3 18.1364 3ZM17.3636 17.8364L12.3641 14.7115C12.2413 14.6347 12.0994 14.594 11.9545 14.594C11.8097 14.594 11.6678 14.6347 11.545 14.7115L6.54545 17.8364V4.54545H17.3636V17.8364Z"
      fill="currentColor"
    />
  </svg>
</template>

<script>
export default {
  name: "SavedListIcon",
  props: {
    classes: {
      type: String,
      required: false,
      default: "",
    },
  },
};
</script>
