import { NuxtAxiosInstance } from "@nuxtjs/axios";
import constants from "~/constants/ecomApi";
import httpStatusCodes from "~/constants/httpStatusCodes";

export const signup = async (
  $axios: NuxtAxiosInstance,
  email: string
): Promise<void> => {
  try {
    // TODO: double check use case of param ip and remove or set correct value
    await $axios.$post(constants.NEWSLETTER_SIGNUP, {
      email_address: email,
      ip: "0.0.0.0",
    });
  } catch (error) {
    const statusCode = (error as AxiosError)?.response?.status;
    // If API response with UNPROCESSABLE_ENTITY then means the email is already subscribed
    switch (statusCode) {
      case httpStatusCodes.UNPROCESSABLE_ENTITY:
        throw new Error("newsletter.errorAlreadySubscribedMessage");
      default:
        throw new Error("newsletter.errorUnableMessage");
    }
  }
};

export default { signup };
