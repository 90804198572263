import { Context } from "@nuxt/types/app";

declare const gtag: Function;

export const getClientId = (config: Context["$config"] | null = null) =>
  new Promise((resolve, reject) => {
    console.info("Retrieving gaServerMeasurementId...");

    const timeoutId = setTimeout(
      () =>
        reject(Error("Timeout exceed whilst attempting to retrieve client ID")),
      1000
    );

    gtag("get", config?.gaServerMeasurementId, "client_id", (id: string) => {
      clearTimeout(timeoutId);
      resolve(id);
    });
  })
    .catch((error) => {
      console.error("Error retrieving client ID:", error);
      return null;
    })
    .then((clientId) => {
      console.info("gaServerMeasurementId: ", clientId);
      return clientId;
    });
