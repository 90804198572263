import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _03be8903 = () => interopDefault(import('../pages/branches/index.vue' /* webpackChunkName: "pages/branches/index" */))
const _03de515c = () => interopDefault(import('../pages/clearcookies.vue' /* webpackChunkName: "pages/clearcookies" */))
const _4fe15da0 = () => interopDefault(import('../pages/contact.vue' /* webpackChunkName: "pages/contact" */))
const _6b14a017 = () => interopDefault(import('../pages/landing.vue' /* webpackChunkName: "pages/landing" */))
const _c600132e = () => interopDefault(import('../pages/login.vue' /* webpackChunkName: "pages/login" */))
const _500fdf3a = () => interopDefault(import('../pages/logout.vue' /* webpackChunkName: "pages/logout" */))
const _737aba16 = () => interopDefault(import('../pages/register/index.vue' /* webpackChunkName: "pages/register/index" */))
const _4370f058 = () => interopDefault(import('../pages/search.vue' /* webpackChunkName: "pages/search" */))
const _a2856b36 = () => interopDefault(import('../pages/trolley.vue' /* webpackChunkName: "pages/trolley" */))
const _04e88008 = () => interopDefault(import('../pages/account/club.vue' /* webpackChunkName: "pages/account/club" */))
const _c4f6d89e = () => interopDefault(import('../pages/account/home.vue' /* webpackChunkName: "pages/account/home" */))
const _2a27429a = () => interopDefault(import('../pages/account/order-history.vue' /* webpackChunkName: "pages/account/order-history" */))
const _0f86a0fc = () => interopDefault(import('../pages/account/pro-card.vue' /* webpackChunkName: "pages/account/pro-card" */))
const _bae9c226 = () => interopDefault(import('../pages/account/saved-lists.vue' /* webpackChunkName: "pages/account/saved-lists" */))
const _10f7f708 = () => interopDefault(import('../pages/account/spending-reports.vue' /* webpackChunkName: "pages/account/spending-reports" */))
const _b9db4436 = () => interopDefault(import('../pages/account/trade-account/index.vue' /* webpackChunkName: "pages/account/trade-account/index" */))
const _739a20c6 = () => interopDefault(import('../pages/checkout/delivery.vue' /* webpackChunkName: "pages/checkout/delivery" */))
const _764e0941 = () => interopDefault(import('../pages/checkout/review.vue' /* webpackChunkName: "pages/checkout/review" */))
const _6d5b25f6 = () => interopDefault(import('../pages/club/apply.vue' /* webpackChunkName: "pages/club/apply" */))
const _f773c3ea = () => interopDefault(import('../pages/password/reset.vue' /* webpackChunkName: "pages/password/reset" */))
const _c544b968 = () => interopDefault(import('../pages/trade-credit/login.vue' /* webpackChunkName: "pages/trade-credit/login" */))
const _0aa486a1 = () => interopDefault(import('../pages/account/details/address-book.vue' /* webpackChunkName: "pages/account/details/address-book" */))
const _34e40db8 = () => interopDefault(import('../pages/account/details/catrequest.vue' /* webpackChunkName: "pages/account/details/catrequest" */))
const _48ce33b2 = () => interopDefault(import('../pages/account/details/manage/index.vue' /* webpackChunkName: "pages/account/details/manage/index" */))
const _d7d0be6e = () => interopDefault(import('../pages/account/details/preferences.vue' /* webpackChunkName: "pages/account/details/preferences" */))
const _2969bc47 = () => interopDefault(import('../pages/account/details/preferencesNew.vue' /* webpackChunkName: "pages/account/details/preferencesNew" */))
const _6936254e = () => interopDefault(import('../pages/account/details/preferencesOld.vue' /* webpackChunkName: "pages/account/details/preferencesOld" */))
const _4c29c54c = () => interopDefault(import('../pages/account/trade-account/faqs.vue' /* webpackChunkName: "pages/account/trade-account/faqs" */))
const _2d092062 = () => interopDefault(import('../pages/account/trade-account/manage-cardholders/index.vue' /* webpackChunkName: "pages/account/trade-account/manage-cardholders/index" */))
const _090ad1a8 = () => interopDefault(import('../pages/account/trade-account/order-history.vue' /* webpackChunkName: "pages/account/trade-account/order-history" */))
const _1a9d6c2d = () => interopDefault(import('../pages/account/trade-account/promotions.vue' /* webpackChunkName: "pages/account/trade-account/promotions" */))
const _0a5ba3f3 = () => interopDefault(import('../pages/account/trade-account/spending-reports.vue' /* webpackChunkName: "pages/account/trade-account/spending-reports" */))
const _7a0b41b2 = () => interopDefault(import('../pages/checkout/guest/details.vue' /* webpackChunkName: "pages/checkout/guest/details" */))
const _5eca322d = () => interopDefault(import('../pages/account/details/manage/change-password.vue' /* webpackChunkName: "pages/account/details/manage/change-password" */))
const _79126ae8 = () => interopDefault(import('../pages/account/details/manage/change-username.vue' /* webpackChunkName: "pages/account/details/manage/change-username" */))
const _592b4776 = () => interopDefault(import('../pages/account/details/manage/edit.vue' /* webpackChunkName: "pages/account/details/manage/edit" */))
const _9a955544 = () => interopDefault(import('../pages/account/trade-account/manage-cardholders/add.vue' /* webpackChunkName: "pages/account/trade-account/manage-cardholders/add" */))
const _518f74a9 = () => interopDefault(import('../pages/account/trade-account/payment/bank-transfer/index.vue' /* webpackChunkName: "pages/account/trade-account/payment/bank-transfer/index" */))
const _09cc9968 = () => interopDefault(import('../pages/account/trade-account/payment/bank-transfer/finish.vue' /* webpackChunkName: "pages/account/trade-account/payment/bank-transfer/finish" */))
const _62a83235 = () => interopDefault(import('../pages/account/trade-account/payment/direct-debit/form.vue' /* webpackChunkName: "pages/account/trade-account/payment/direct-debit/form" */))
const _6f6fd4ee = () => interopDefault(import('../pages/checkout/confirmation/_id.vue' /* webpackChunkName: "pages/checkout/confirmation/_id" */))
const _20a54d89 = () => interopDefault(import('../pages/trade-credit/register/_token.vue' /* webpackChunkName: "pages/trade-credit/register/_token" */))
const _0207e7bb = () => interopDefault(import('../pages/branches/_slug.vue' /* webpackChunkName: "pages/branches/_slug" */))
const _14b4a3b2 = () => interopDefault(import('../pages/brands/_id.vue' /* webpackChunkName: "pages/brands/_id" */))
const _eb0b412a = () => interopDefault(import('../pages/campaign/_id.vue' /* webpackChunkName: "pages/campaign/_id" */))
const _5c014226 = () => interopDefault(import('../pages/trade-credit/_slug.vue' /* webpackChunkName: "pages/trade-credit/_slug" */))
const _0a883d9c = () => interopDefault(import('../pages/_topslug/_id.vue' /* webpackChunkName: "pages/_topslug/_id" */))
const _83636f0e = () => interopDefault(import('../pages/_topslug/_productId.vue' /* webpackChunkName: "pages/_topslug/_productId" */))
const _4c619b29 = () => interopDefault(import('../pages/_topslug/_slug_id/_id.vue' /* webpackChunkName: "pages/_topslug/_slug_id/_id" */))
const _1aad5f42 = () => interopDefault(import('../pages/_.vue' /* webpackChunkName: "pages/_" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/branches",
    component: _03be8903,
    name: "branches"
  }, {
    path: "/clearcookies",
    component: _03de515c,
    name: "clearcookies"
  }, {
    path: "/contact",
    component: _4fe15da0,
    name: "contact"
  }, {
    path: "/landing.html",
    component: _6b14a017,
    name: "landing"
  }, {
    path: "/login",
    component: _c600132e,
    name: "login"
  }, {
    path: "/logout",
    component: _500fdf3a,
    name: "logout"
  }, {
    path: "/register",
    component: _737aba16,
    name: "register"
  }, {
    path: "/search",
    component: _4370f058,
    name: "search"
  }, {
    path: "/trolley",
    component: _a2856b36,
    name: "trolley"
  }, {
    path: "/account/club",
    component: _04e88008,
    name: "account-club"
  }, {
    path: "/account/home",
    component: _c4f6d89e,
    name: "account-home"
  }, {
    path: "/account/order-history",
    component: _2a27429a,
    name: "account-order-history"
  }, {
    path: "/account/pro-card",
    component: _0f86a0fc,
    name: "account-pro-card"
  }, {
    path: "/account/saved-lists/:ref?",
    component: _bae9c226,
    name: "account-saved-lists"
  }, {
    path: "/account/spending-reports",
    component: _10f7f708,
    name: "account-spending-reports"
  }, {
    path: "/account/trade-account",
    component: _b9db4436,
    name: "account-trade-account"
  }, {
    path: "/checkout/delivery",
    component: _739a20c6,
    name: "checkout-delivery"
  }, {
    path: "/checkout/review",
    component: _764e0941,
    name: "checkout-review"
  }, {
    path: "/club/apply",
    component: _6d5b25f6,
    name: "club-apply"
  }, {
    path: "/password/reset",
    component: _f773c3ea,
    name: "password-reset"
  }, {
    path: "/trade-credit/login",
    component: _c544b968,
    name: "trade-credit-login"
  }, {
    path: "/account/details/address-book",
    component: _0aa486a1,
    name: "account-details-address-book"
  }, {
    path: "/account/details/catrequest",
    component: _34e40db8,
    name: "account-details-catrequest"
  }, {
    path: "/account/details/manage",
    component: _48ce33b2,
    name: "account-details-manage"
  }, {
    path: "/account/details/preferences",
    component: _d7d0be6e,
    name: "account-details-preferences"
  }, {
    path: "/account/details/preferencesNew",
    component: _2969bc47,
    name: "account-details-preferencesNew"
  }, {
    path: "/account/details/preferencesOld",
    component: _6936254e,
    name: "account-details-preferencesOld"
  }, {
    path: "/account/trade-account/faqs",
    component: _4c29c54c,
    name: "account-trade-account-faqs"
  }, {
    path: "/account/trade-account/manage-cardholders",
    component: _2d092062,
    name: "account-trade-account-manage-cardholders"
  }, {
    path: "/account/trade-account/order-history",
    component: _090ad1a8,
    name: "account-trade-account-order-history"
  }, {
    path: "/account/trade-account/promotions",
    component: _1a9d6c2d,
    name: "account-trade-account-promotions"
  }, {
    path: "/account/trade-account/spending-reports",
    component: _0a5ba3f3,
    name: "account-trade-account-spending-reports"
  }, {
    path: "/checkout/guest/details",
    component: _7a0b41b2,
    name: "checkout-guest-details"
  }, {
    path: "/account/details/manage/change-password",
    component: _5eca322d,
    name: "account-details-manage-change-password"
  }, {
    path: "/account/details/manage/change-username",
    component: _79126ae8,
    name: "account-details-manage-change-username"
  }, {
    path: "/account/details/manage/edit",
    component: _592b4776,
    name: "account-details-manage-edit"
  }, {
    path: "/account/trade-account/manage-cardholders/add",
    component: _9a955544,
    name: "account-trade-account-manage-cardholders-add"
  }, {
    path: "/account/trade-account/payment/bank-transfer",
    component: _518f74a9,
    name: "account-trade-account-payment-bank-transfer"
  }, {
    path: "/account/trade-account/payment/bank-transfer/finish",
    component: _09cc9968,
    name: "account-trade-account-payment-bank-transfer-finish"
  }, {
    path: "/account/trade-account/payment/direct-debit/form",
    component: _62a83235,
    name: "account-trade-account-payment-direct-debit-form"
  }, {
    path: "/checkout/confirmation/:id?",
    component: _6f6fd4ee,
    name: "checkout-confirmation-id"
  }, {
    path: "/trade-credit/register/:token?",
    component: _20a54d89,
    name: "trade-credit-register-token"
  }, {
    path: "/branches/:slug",
    component: _0207e7bb,
    name: "branches-slug"
  }, {
    path: "/brands/:slug_id",
    component: _14b4a3b2,
    name: "brands"
  }, {
    path: "/campaign/:slug_id",
    component: _eb0b412a,
    name: "campaign"
  }, {
    path: "/trade-credit/:slug?",
    component: _5c014226,
    name: "trade-credit-slug"
  }, {
    path: "/categories/:topslug/c:id(\\d+)",
    component: _0a883d9c,
    name: "topslug-id"
  }, {
    path: "/:topslug/p:productId([a-zA-Z0-9]{5})",
    component: _83636f0e,
    name: "topslug-productId"
  }, {
    path: "/:topslug/:slug_id/c:id(\\d+)",
    component: _4c619b29,
    name: "topslug-slug_id-id"
  }, {
    path: "/password/reset/:token",
    component: _f773c3ea,
    name: "password-reset-token"
  }, {
    path: "/:topslug/c:id(\\d+)",
    component: _0a883d9c,
    name: "topslug-id/:topslug/c:id(\\d+)"
  }, {
    path: "/p:productId([a-zA-Z0-9]{5})",
    component: _83636f0e,
    name: "topslug-productId/p:productId([a-zA-Z0-9]{5})"
  }, {
    path: "/:topslug/:slug_id/:level_3/c:id",
    component: _4c619b29,
    name: "topslug-slug_id-id/:topslug/:slug_id/:level_3/c:id"
  }, {
    path: "/*",
    component: _1aad5f42,
    name: "all"
  }],

  stringifyQuery: function(object) {
      const stringObject = new URLSearchParams(object).toString();
      const queryString = stringObject.replaceAll("%20", "+");
      return queryString ? "?" + queryString : "";
    },
  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
