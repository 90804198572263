<template functional>
  <svg
    :class="props.classes"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
  >
    <path
      d="M20.9831 8.92638L19.965 4.85162C19.8401 4.351 19.3901 4 18.8737 4H5.12738C4.611 4 4.161 4.351 4.035 4.85162L3.01687 8.92638C3.00562 8.97025 3 9.01638 3 9.0625C3 10.6128 4.19813 11.875 5.67188 11.875C6.528 11.875 7.29187 11.4486 7.78125 10.7871C8.27063 11.4486 9.0345 11.875 9.89062 11.875C10.7467 11.875 11.5106 11.4486 12 10.7871C12.4894 11.4486 13.2521 11.875 14.1094 11.875C14.9666 11.875 15.7294 11.4486 16.2188 10.7871C16.7081 11.4486 17.4709 11.875 18.3281 11.875C19.8019 11.875 21 10.6128 21 9.0625C21 9.01638 20.9944 8.97025 20.9831 8.92638Z"
      fill="currentColor"
    />
    <path
      d="M16.2188 12.3385C14.988 13.1946 13.2308 13.1946 12 12.3385C10.7692 13.1946 9.012 13.1946 7.78125 12.3385C7.16587 12.766 6.438 13 5.67188 13C5.1195 13 4.59863 12.8706 4.125 12.6501V18.625C4.125 19.246 4.629 19.75 5.25 19.75H9.75V15.25H14.25V19.75H18.75C19.371 19.75 19.875 19.246 19.875 18.625V12.6501C19.4014 12.8706 18.8805 13 18.3281 13C17.562 13 16.8341 12.766 16.2188 12.3385Z"
      fill="currentColor"
    />
  </svg>
</template>

<script>
export default {
  name: "StoreIcon",
  props: {
    classes: {
      type: String,
      required: false,
      default: "",
    },
  },
};
</script>
