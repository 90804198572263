import { ActionTree, GetterTree, MutationTree } from "vuex";
import { Context } from "@nuxt/types";
import { LocaleObject } from "@nuxtjs/i18n";
import { isEmpty, isNil } from "ramda";
import environment from "../utils/environment";
import { RootState } from "./index";
import {
  Department,
  BloomreachDepartmentItem,
} from "~/components/layout/header/components/Departments.d";
import {
  LocaleCode,
  changeLocale,
  getDisplayName,
  splitLocale,
  getSupportedLocales,
} from "~/utils/i18n";
import {
  fetchTradeBrands,
  getMenuForSupportServices,
} from "~/services/content.service";
import { groupValuesByNumericSuffix } from "~/utils/wordpress";
import { TradeBrandItem } from "~/components/layout/header/components/TradeBrands";
import { LocaleMenuItem } from "~/components/layout/navbar/mobile-menu/types";
import { ApiPagesResponse, MenuItem } from "~/services/api/content.api.d";
import { fetchBrDocument } from "~/services/bloomreach.service";
import { BloomreachFolderPaths } from "~/services/api/bloomreach.api";

/*
 TODO: Do we want to refactor and move some of the country settings
 and site footer store code into here?
 */
export interface LocaleState {
  newTradeBrands: TradeBrandItem[] | null;
  topTradeBrands: TradeBrandItem[] | null;
  departmentsHierarchy: Department[] | null;
  supportServices: MenuItem[] | null;
  supportedLocales: LocaleMenuItem[] | null;
  ssrPageUrl: string | null;
  selectedMenuItems: number[];
}

export const state = (): LocaleState => ({
  newTradeBrands: null,
  topTradeBrands: null,
  departmentsHierarchy: null,
  supportedLocales: null,
  supportServices: null,
  ssrPageUrl: null,
  selectedMenuItems: [],
});

export const mutations: MutationTree<LocaleState> = {
  setNewTradeBrands: (state: LocaleState, items: TradeBrandItem[]): void => {
    state.newTradeBrands = items;
  },
  setTopTradeBrands: (state: LocaleState, items: TradeBrandItem[]): void => {
    state.topTradeBrands = items;
  },
  setDepartmentsHierarchy: (state: LocaleState, items: Department[]): void => {
    state.departmentsHierarchy = items;
  },
  setSupportedLocales: (state: LocaleState, items: LocaleMenuItem[]): void => {
    state.supportedLocales = items;
  },
  setSupportServices: (state: LocaleState, items: MenuItem[]): void => {
    state.supportServices = items;
  },
  setSsrPageUrl: (state: LocaleState, url: string): void => {
    state.ssrPageUrl = url;
  },
  addSelectedMenuItems: (state: LocaleState, id: number): void => {
    state.selectedMenuItems.push(id);
  },
  removeSelectedMenuItems: (state: LocaleState, id: number): void => {
    const index = state.selectedMenuItems.indexOf(id);
    if (index > -1) {
      // only splice array when item is found
      state.selectedMenuItems.splice(index, 1); // 2nd parameter means remove one item only
    }
  },
  clearSelectedMenuItems: (state: LocaleState): void => {
    state.selectedMenuItems = [];
  },
};

export const getters: GetterTree<LocaleState, RootState> = {
  getNewTradeBrands: (state: LocaleState): TradeBrandItem[] | null =>
    state.newTradeBrands,
  getTopTradeBrands: (state: LocaleState): TradeBrandItem[] | null =>
    state.topTradeBrands,
  getDepartmentsHierarchy: (state: LocaleState): Department[] | null =>
    state.departmentsHierarchy,
  getSupportedLocales: (state: LocaleState): LocaleMenuItem[] | null =>
    state.supportedLocales,
  getSupportServices: (state: LocaleState): MenuItem[] | null =>
    state.supportServices,
  canSwitchLocales: (state: LocaleState): boolean =>
    !(state.supportedLocales == null) && state.supportedLocales.length > 1,
  getSsrPageUrl: (state: LocaleState): string =>
    state.ssrPageUrl ?? "https://www.toolstation.com",
  getSelectedMenuItems: (state: LocaleState): number[] =>
    state.selectedMenuItems,
};

export const actions: ActionTree<LocaleState, RootState> = {
  setSsrPageUrl: ({ commit }, req): void => {
    const ssrUrl = `${
      (req?.headers?.["x-forwarded-host"] as string) ?? req?.headers?.host ?? ""
    }`;

    if (ssrUrl !== "") {
      commit("setSsrPageUrl", ssrUrl);
    }
  },
  async changeLocaleContent({ commit }, context: Context) {
    const { locale: queryLocale } = context.query;

    if (
      !isNil(queryLocale) &&
      !isEmpty(queryLocale) &&
      queryLocale !== context.i18n.locale
    ) {
      await changeLocale(context, queryLocale as LocaleCode);
    }

    const supportedLocales: LocaleMenuItem[] = getSupportedLocales(
      context.$config.localeInstance
    ).map((locale: string | LocaleObject): LocaleMenuItem => {
      const localeCode = ((locale as LocaleObject)?.code ??
        locale) as LocaleCode;
      const { languageCode, countryCode } = splitLocale(localeCode);
      let label = getDisplayName("language", languageCode);

      if (environment.isDevelopment()) {
        label = `${label} (${getDisplayName("region", countryCode, "en")})`;
      }

      return {
        name: label ?? "",
        localeCode,
      };
    });
    commit("setSupportedLocales", supportedLocales);
  },

  async fetchDepartmentsHierarchy({ commit }, context: Context): Promise<void> {
    try {
      const { brxmContentEndpoint: brContentURL } = context.$config;

      await fetchBrDocument(
        BloomreachFolderPaths.MegaMenu,
        "",
        brContentURL
      ).then((departmentsHierarchy) => {
        const mapTaxonomy = (taxonomyItem: BloomreachDepartmentItem) => ({
          name: taxonomyItem.departmentName,
          slug: taxonomyItem.departmentSlug,
          colour: taxonomyItem.departmentColour,
        });

        const isVisible = (taxonomyItem: {
          fields?: { hideFromWeb?: boolean };
          hideFromWeb?: boolean;
        }): boolean =>
          !(taxonomyItem?.fields?.hideFromWeb || taxonomyItem?.hideFromWeb);

        const mapCategory = (
          category: BloomreachDepartmentItem
        ): Department => {
          const children =
            category.levelTwoDepartment || category.levelThreeDepartment || [];

          return {
            taxonomy: mapTaxonomy(category),
            children: children.filter(isVisible).map(mapCategory),
          };
        };

        const mappedTaxonomyData: Department[] = departmentsHierarchy.categories
          .filter(isVisible)
          .map(({ fields }: { fields: BloomreachDepartmentItem }) =>
            mapCategory(fields)
          );

        commit("setDepartmentsHierarchy", mappedTaxonomyData);
      });
    } catch (e) {
      this.$log.error(e);
    }
  },
  async fetchSupportServices({ commit }, context: Context): Promise<void> {
    try {
      const supportServices = await getMenuForSupportServices(
        this.$axios,
        context.i18n.locale as LocaleCode
      ).catch(() => null);

      if (supportServices != null) {
        commit("setSupportServices", supportServices);
      }
    } catch (e) {
      this.$log.error(e);
    }
  },
  async fetchBrands({ commit }): Promise<void> {
    const extractTradeBrandItemsFromApiResponse = (
      apiResponse: ApiPagesResponse,
      brandFieldName: string,
      brandUrlFieldName: string
    ): TradeBrandItem[] => {
      const metaFields = apiResponse.data.meta;
      return groupValuesByNumericSuffix(metaFields, [
        brandFieldName,
        brandUrlFieldName,
      ])
        .filter((item) => {
          // remove brands with no url or image set
          return !isNil(item[brandUrlFieldName]) && item[brandFieldName]?.url;
        })
        .map((item) => {
          return {
            path: item[brandUrlFieldName],
            img: item[brandFieldName].url,
            title: item[brandFieldName].title,
          };
        });
    };
    await fetchTradeBrands(this.$axios)
      .then((response) => {
        const topTradeBrands = extractTradeBrandItemsFromApiResponse(
          response,
          "brand",
          "brand_url"
        );

        const newTradeBrands = extractTradeBrandItemsFromApiResponse(
          response,
          "new_brand",
          "new_brand_url"
        );
        commit("setNewTradeBrands", newTradeBrands);
        commit("setTopTradeBrands", topTradeBrands);
      })
      .catch(() => {});
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
