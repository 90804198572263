
import Vue from "vue";

export default Vue.extend({
  name: "AlertBox",
  props: {
    variant: {
      type: String,
      default: "success",
      validator(value) {
        // There aren't any styles attached to danger yet
        return ["success", "info", "warning", "ts-warning", "danger"].includes(
          String(value)
        );
      },
    },
    withBorder: {
      type: Boolean,
      default: true,
    },
    withInnerBlock: {
      type: Boolean,
      default: true,
    },
    withFill: {
      type: Boolean,
      default: true,
    },
    legacyColour: {
      type: Boolean,
      default: false,
    },
  },
});
