
import Vue from "vue";

export default Vue.extend({
  name: "HamburgerIcon",
  props: {
    open: {
      type: Boolean,
      required: true,
    },
  },
});
