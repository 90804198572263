
import Vue from "vue";
import { prop } from "ramda";
import SmartLink from "~/components/UI/links/SmartLink.vue";
import MenuItem from "~/components/account/MenuItem.vue";
import {
  CardOutlineIcon,
  CardSolidIcon,
  InformationIcon,
  ListIcon,
  ClipboardIcon,
  TagIcon,
  ChevronIcon,
} from "~/components/UI/icons";

interface TradeMenuItem {
  icon: string;
  text: string;
  url: string;
  external?: boolean;
  sso?: {
    method: string;
    params: Record<string, any>;
  } | null;
  show?: boolean;
}

export default Vue.extend({
  name: "TradeAccountMenu",
  components: {
    CardSolidIcon,
    CardOutlineIcon,
    ChevronIcon,
    ListIcon,
    ClipboardIcon,
    TagIcon,
    InformationIcon,
    SmartLink,
    MenuItem,
  },
  props: {
    isAdminAccount: {
      type: Boolean,
      required: false,
      default: true,
    },
  },
  computed: {
    menuItems(): TradeMenuItem[] {
      return [
        {
          url: "/account/trade-account/payment/direct-debit/form",
          text: "account.tradeAccount.menu.directDebit",
          icon: "card-solid-icon",
          show: this.isAdminAccount,
        },
        {
          url: "/account/trade-account/order-history",
          text: "account.tradeAccount.menu.orderHistory",
          icon: "list-icon",
          show: true,
        },

        {
          url: "/account/spending-reports",
          text: "account.spendingReports.pageTitle",
          icon: "clipboard-icon",
          show: true,
        },
        {
          url: "/account/trade-account/manage-cardholders",
          text: "account.tradeAccount.menu.manageCardholders",
          icon: "card-outline-icon",
          show: this.isAdminAccount,
        },
        {
          url: "/account/trade-account/promotions",
          text: "account.tradeAccount.menu.promotions",
          icon: "tag-icon",
          show: this.isAdminAccount,
        },
        {
          url: "/account/trade-account/faqs",
          text: "account.tradeAccount.menu.faqs",
          icon: "information-icon",
          show: true,
        },
      ].filter(prop("show"));
    },
  },
});
