import { ActionTree, GetterTree, MutationTree } from "vuex";
import { is } from "ramda";
import { RootState } from "./index";
import { prefixCategory } from "~/lib/shared";
import {
  Taxonomy,
  TaxonomyData,
  TaxonomyNote,
  TaxonomyChild,
} from "~/services/taxonomies.d";
import {
  generateTopParentSlug,
  generateTaxonomySlug,
  generateCategorySlug,
  getTaxonomyMap,
  CustomTaxonomyPageNames,
} from "~/lib/taxonomies/index";
import { fetchTaxonomyById } from "~/services/taxonomies";

interface TaxonomyState {
  taxonomyData: TaxonomyData | null;
  fetchingTaxonomyData: boolean;
}

export const state = (): TaxonomyState => ({
  taxonomyData: null,
  fetchingTaxonomyData: false,
});

export const mutations: MutationTree<TaxonomyState> = {
  setTaxonomy: (state: TaxonomyState, payload: TaxonomyData) => {
    state.taxonomyData = payload;
  },
  setTaxonomyBasics: (
    state: TaxonomyState,
    payload: { name: string; description: string }
  ) => {
    state.taxonomyData = {
      taxonomy: { ...payload, slug: "" },
      menu: 0,
      order: 0,
      parentId: 0,
    };
  },
  setFetchingTaxonomyData: (state: TaxonomyState, payload: boolean) => {
    state.fetchingTaxonomyData = payload;
  },
};

export const getters: GetterTree<TaxonomyState, RootState> = {
  getTaxonomy: (state: TaxonomyState): Taxonomy | null =>
    state.taxonomyData?.taxonomy ?? null,
  getTaxonomyChildren: (state: TaxonomyState): TaxonomyData[] =>
    state.taxonomyData?.children ?? [],
  getTaxonomyLinkById: (_state, getters) => (id: number) => {
    const taxonomy = getters.getTaxonomyChildren.find(
      (taxonomy: TaxonomyChild) => taxonomy.taxonomy.id === id
    );

    const parentSlug = getters.getTaxonomyTopParent
      ? `/${getters.getTaxonomyTopParent?.taxonomy?.slug}`
      : "";

    const taxonomySlug = taxonomy ? `/${taxonomy?.taxonomy?.slug}` : "";

    return `${parentSlug}${taxonomySlug}/${prefixCategory(id)}`;
  },
  getTaxonomyAncestors: (state: TaxonomyState): TaxonomyData[] =>
    state.taxonomyData?.ancestors ?? [],
  getTaxonomyId: (state: TaxonomyState): number | null =>
    state.taxonomyData?.taxonomy?.id ?? null,
  getTaxonomyName: (state: TaxonomyState): string | null =>
    state.taxonomyData?.taxonomy?.name ?? null,
  getTaxonomyDescription: (state: TaxonomyState): string | null =>
    state.taxonomyData?.taxonomy?.description ?? null,
  getTaxonomyImage: (state: TaxonomyState): string | null =>
    state.taxonomyData?.taxonomy?.image ?? null,
  getTaxonomyColor: (state: TaxonomyState): string | null =>
    state.taxonomyData?.taxonomy?.colour ?? null,
  getTaxonomyTopParent: (_state, getters): TaxonomyData | null =>
    getters.getTaxonomyAncestors[0] ?? null,
  getTaxonomyNote: (_state, getters) => (noteKey: string) => {
    return getters.getTaxonomy !== null && is(Array, getters.getTaxonomy.notes)
      ? (getters.getTaxonomy?.notes.find(
          (n: TaxonomyNote) => n.name === noteKey
        )?.value ?? "")
      : "";
  },
  getTaxonomySlug(_, getters) {
    return generateTaxonomySlug(getters.getTaxonomy);
  },
  getTopParentSlug(_, getters) {
    return generateTopParentSlug(getters.getTaxonomyAncestors);
  },
  getTaxonomySlugId(_, getters) {
    return generateCategorySlug(getters.getTaxonomyId);
  },
  getTaxonomyUrl(_, getters): string {
    return `${getters.getTopParentSlug}${getters.getTaxonomySlug}${getters.getTaxonomySlugId}`;
  },
  getFetchingTaxonomyData: (state: TaxonomyState): boolean =>
    state.fetchingTaxonomyData,
};

export const actions: ActionTree<TaxonomyState, RootState> = {
  async updateTaxonomyAction(
    { commit },
    data: {
      categoryId: string;
      routeName?: CustomTaxonomyPageNames;
      name?: string;
    }
  ): Promise<TaxonomyData | null> {
    commit("setFetchingTaxonomyData", true);
    const { categoryId, routeName, name } = data;

    const getTaxonomy = async () => {
      if (routeName && getTaxonomyMap[routeName]) {
        return getTaxonomyMap[routeName](this.app.i18n, categoryId, name || "");
      }
      return await fetchTaxonomyById(this.$axios, Number(categoryId));
    };

    const taxonomy: TaxonomyData | null = await getTaxonomy();

    commit("setTaxonomy", taxonomy);
    commit("setFetchingTaxonomyData", false);
    return taxonomy;
  },
};

export default {
  state,
  mutations,
  getters,
  actions,
};
