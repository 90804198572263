import { replace } from "ramda";

export const isUrl = (value: string): boolean => {
  let url;

  try {
    url = new URL(value);
  } catch (_) {
    return false;
  }

  return url.protocol === "http:" || url.protocol === "https:";
};

export const toRelative = replace(/(https?:\/\/|www\.).*?(?=\/)/, "");

// toRelative could be potentially unsafe... another option is below:
// export const toRelative = (urlPath: string): string => {
//   const url = new URL(urlPath);
//   return url.pathname + url.search + url.hash
// };
