import { ActionTree, GetterTree, MutationTree } from "vuex";
import { state as rootState } from "./index";
import { loadCountrySettings, splitLocale, LocaleCode } from "~/utils/i18n";

export interface CountrySettingState {
  footer: {
    showLegalInfo: boolean;
    showPaypalCreditInfo: boolean;
  };
}

type RootState = ReturnType<typeof rootState>;

export const state = (): CountrySettingState => ({
  footer: {
    showLegalInfo: false,
    showPaypalCreditInfo: false,
  },
});

export const mutations: MutationTree<CountrySettingState> = {
  setCountrySetting: (
    state: CountrySettingState,
    settings: CountrySettingState
  ): void => {
    state.footer = settings.footer;
  },
};

export const getters: GetterTree<CountrySettingState, RootState> = {
  showFooterLegalInfo: (state: CountrySettingState): boolean =>
    state.footer?.showLegalInfo,
  showFooterPaypalCreditInfo: (state: CountrySettingState): boolean =>
    state.footer?.showPaypalCreditInfo,
};

export const actions: ActionTree<CountrySettingState, RootState> = {
  async fetchCountrySettings({ commit }, locale: LocaleCode) {
    const currentCountry = splitLocale(locale).countryCode;
    const countrySettings = await loadCountrySettings(currentCountry);
    commit("setCountrySetting", countrySettings);
  },
};
