import axios from "axios";
import { handleError } from "~/services/helpers";

const ENDPOINTS = {
  HOME: (brContentUrl: string, document: string) =>
    `${brContentUrl}/${document}`,
  TRADE_ACCOUNT_PROMOS: (brContentURL: string, document: string) =>
    `${brContentURL}/promotions/trade-account/${document}`,
  PRODUCT_PAGE: (brContentURL: string, document: string) =>
    `${brContentURL}/product-page/${document}`,
  CAMPAIGNS: (brContentURL: string, document: string) =>
    `${brContentURL}/campaigns/${document}`,
  ERROR_PAGE: (brContentURL: string, document: string) =>
    `${brContentURL}/error-pages/${document}`,
  SITE_MESSAGES: (brContentURL: string, document: string) =>
    `${brContentURL}/site-messaging/${document}`,
  FOOTER: (brContentURL: string, document: string) =>
    `${brContentURL}/footer/${document}`,
  USP_BANNER: (brContentURL: string, document: string) =>
    `${brContentURL}/usp-banner/${document}`,
  MEGA_MENU: (brContentURL: string) => `${brContentURL}/departments-menu/menu`,
  CATEGORIES: (brContentURL: string, document: string) =>
    `${brContentURL}/taxonomies/categories/${document}`,
  BRANDS: (brContentURL: string, document: string) =>
    `${brContentURL}/taxonomies/brands/${document}`,
};

export enum BloomreachFolderPaths {
  Home = "HOME",
  TradeAccountPromos = "TRADE_ACCOUNT_PROMOS",
  ProductPage = "PRODUCT_PAGE",
  Campaigns = "CAMPAIGNS",
  ErrorPage = "ERROR_PAGE",
  SiteMessages = "SITE_MESSAGES",
  Footer = "FOOTER",
  USPBanner = "USP_BANNER",
  MegaMenu = "MEGA_MENU",
  Categories = "CATEGORIES",
  Brands = "BRANDS",
}

export type DocumentPathKey = keyof typeof ENDPOINTS;

interface BrLinks {
  self: {
    href: string;
  };
}

interface BrFields {
  [key: string]: any;
}
interface BrDocument {
  id: string;
  path: string;
  name: string;
  locale: string;
  namespace: string;
  contentType: string;
  lastModified: number;
  links: BrLinks;
  translations: any[];
  fields: BrFields;
}
export interface BrDocumentResponse {
  result: {
    total: number;
    offset: number;
    limit: number;
  };
  document: BrDocument;
}

export const getBrDocument = async (
  documentPath: DocumentPathKey,
  document: string,
  brContentURL: string,
  previewToken?: string
) => {
  const url = `${ENDPOINTS[documentPath](brContentURL, document)}${
    previewToken ? "?preview-token=" + previewToken : ""
  }`;

  try {
    const response = await fetch(url);
    if (
      response.status !== 203 &&
      response.status >= 200 &&
      response.status <= 299
    ) {
      return await response.json();
    }
    throw response;
  } catch (error) {
    handleError(error);
  }
};

export const getBrExperiencePage = async (config: any) => {
  try {
    // Need to maintain axios here as the Bloomreach experience manager requires it
    return await axios(config);
  } catch (error) {
    handleError(error);
  }
};
