import Vue from "vue";
import { ModalHeaderIcon } from "~/components/UI/modals/messaging-modal/types";

export default Vue.extend({
  props: {
    show: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    ModalHeaderIcon,
  }),
  methods: {
    close() {
      this.$emit("close");
    },
    ok() {
      this.$emit("ok");
    },
  },
});
