import { ActionTree, GetterTree, MutationTree } from "vuex/types/index";
import { RootState } from ".";
import { LastServerMessage, SavedList } from "~/services/saved-lists";
import {
  addItemToSavedList,
  createSavedList,
  fetchCustomerSavedLists,
  quickTrolleyFromTrolleyId,
} from "~/services/saved-lists.service";
import { Product } from "~/services/search";
import { LineItem } from "~/types/trolley";

interface SaveForLaterState {
  savedLists: SavedList[] | null;
  productCodeInQuestion: string | null;
  lastSavedList: SavedList | null;

  // The endpoint returns information about the saved list (PATCH), and we wish to allow the
  // user to redirect themselves to the list they just created
  lastServerMessage: LastServerMessage;
}

const accountAreaSavedList = "/account/saved-lists/{listId}";

export const state = (initialState = {}): SaveForLaterState => ({
  savedLists: null,
  productCodeInQuestion: null,
  lastSavedList: null,
  lastServerMessage: {
    message: null,
    linkToViewList: null,
    isError: false,
  },

  ...initialState,
});

export const mutations: MutationTree<SaveForLaterState> = {
  setSavedList: (state, list: SavedList[]) => {
    state.savedLists = list;
  },
  setLastSavedList: (state, list: SavedList) => {
    state.lastSavedList = list;
  },
  setLastServerMessage: (state, payload) => {
    state.lastServerMessage = payload;
  },
  setProductInQuestion: (state, code) => {
    state.productCodeInQuestion = code;
  },
};

export const getters: GetterTree<SaveForLaterState, RootState> = {
  isSaveForLaterModalVisible: (state: SaveForLaterState) =>
    state.productCodeInQuestion !== null,
  savedLists: (state: SaveForLaterState) => state.savedLists,
  lastSavedList: (state: SaveForLaterState) => state.lastSavedList,
  lastServerMessage: (state: SaveForLaterState) =>
    state.lastServerMessage?.message,
  lastServerMessageLinkToView: (state: SaveForLaterState) =>
    state.lastServerMessage?.linkToViewList,
  lastServerMessageWasError: (state: SaveForLaterState) =>
    state.lastServerMessage?.isError,
  productCodeInQuestion: (state: SaveForLaterState) =>
    state.productCodeInQuestion,
};

export const actions: ActionTree<SaveForLaterState, RootState> = {
  closeSaveForLaterModal({ commit }) {
    commit("setProductInQuestion", null);
    commit("setSavedList", null);
    commit("setLastServerMessage", null);
  },

  async fetchSavedLists({ commit }, customerId: string) {
    return await fetchCustomerSavedLists(this.$axios, customerId)
      .then((response) => {
        commit("setSavedList", response);
      })
      .catch(() => []);
  },

  async addItemToList(
    { commit },
    {
      savedListId,
      savedListName,
      productCode,
      product,
    }: {
      savedListId: string;
      savedListName: string;
      productCode: string;
      product: Product;
    }
  ) {
    return await addItemToSavedList(this.$axios, savedListId, productCode)
      .then(() => {
        commit("setLastServerMessage", {
          message:
            this.app?.i18n.t("saveForLater.addedToSavedList", {
              listName: savedListName,
              productName: product?.title ?? "-",
              pid: productCode,
            }) ?? null,
          linkToViewList: accountAreaSavedList.replace("{listId}", savedListId),
          isError: false,
        });
      })
      .catch(() => {
        commit("setLastServerMessage", {
          message: this.app?.i18n?.t("somethingWentWrong"),
          linkToViewList: null,
          isError: true,
        });
      });
  },

  async createProductsSavedList(
    { commit, dispatch, rootGetters },
    { savedListName, products }: { savedListName: string; products: LineItem[] }
  ) {
    // Saved lists can only be created for logged in customers
    if (rootGetters["auth/isAuthenticated"] === false) {
      return;
    }

    return await createSavedList(
      this.$axios,
      rootGetters["auth/getUser"]?.id,
      savedListName
    ).then(async (response) => {
      commit("setLastSavedList", response);
      for (const line of products) {
        const productCode = line.product.code ?? null;
        const product = line.product ?? null;

        await dispatch("addItemToList", {
          savedListId: response.id,
          savedListName: response.name,
          productCode,
          product,
        });
      }
      await dispatch("fetchSavedLists", rootGetters["auth/getUser"]?.id);
    });
  },

  async createSavedList(
    { commit, dispatch, rootGetters },
    {
      savedListName,
      productCode,
      product,
    }: { savedListName: string; productCode: string; product: Product }
  ) {
    return await createSavedList(
      this.$axios,
      rootGetters["auth/getUser"]?.id,
      savedListName
    ).then(async (response) => {
      commit("setLastServerMessage", {
        message:
          this.app?.i18n.t("saveForLater.addedToSavedList", {
            listName: savedListName,
            productName: product?.title ?? "-",
            pid: productCode,
          }) ?? null,
        linkToViewList: accountAreaSavedList.replace("{listId}", response.id),
        isError: false,
      });

      await dispatch("addItemToList", {
        savedListId: response.id,
        savedListName: response.name,
        productCode,
        product,
      });
      await dispatch("fetchSavedLists", rootGetters["auth/getUser"]?.id);
    });
  },

  async createSavedListFromTrolleyId({ commit, rootGetters }) {
    return await quickTrolleyFromTrolleyId(
      this.$axios,
      rootGetters["trolley/trolleyId"]
    ).then((response: any) => {
      commit("setLastSavedList", response);
    });
  },
};
