// This is a temporary file whilst we migrate away from laravel
window.tsc = {
  bloomreach: {
    search_api: "http://staging-core.dxpapi.com/api/v1/core/", // TODO - move ENV
  },
  branch: {},
  allowNextDay: true,
  allowSameDay: false,
};
window.dataLayer = window.dataLayer || [];
window.tscStockCheck = [];
