import Vue from "vue";
import ToastNotification from "~/components/UI/alerts/Toast.vue";

export default ({ _app }, inject) => {
  let currentToastInstance = null;

  const showToast = (message, type) => {
    const ToastComponent = Vue.extend(ToastNotification);
    const toastInstance = new ToastComponent({
      propsData: { message, type },
    });

    if (currentToastInstance) {
      document.body.removeChild(currentToastInstance.$el);
    }

    currentToastInstance = toastInstance;

    toastInstance.$mount();
    document.body.appendChild(toastInstance.$el);
  };

  inject("toast", {
    primary(message) {
      showToast(message, "primary");
    },
    error(message) {
      showToast(message, "error");
    },
    success(message) {
      showToast(message, "success");
    },
    info(message) {
      showToast(message, "info");
    },
  });
};
