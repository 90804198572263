
import type { PropType } from "vue";
import Vue from "vue";
import { mapGetters, mapMutations } from "vuex";
import type { HeaderLink } from "~/components/layout/header/TheHeader.d";

export default Vue.extend({
  name: "TopNavbar",
  components: {
    LocaleSelector: () =>
      import(
        /* webpackChunkName: "LocaleSelector" */ "~/components/layout/header/components/LocaleSelector.vue"
      ),
  },
  props: {
    links: {
      type: Array as PropType<HeaderLink[]>,
      default: () => [],
    },
    compact: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapGetters("auth", ["getUser"]),
    ...mapGetters("branch", ["selectedBranchName", "isSelectedBranch"]),
    ...mapGetters("locale", ["canSwitchLocales", "getSupportedLocales"]),
    isBranchPage() {
      return this.$route.path === "/branches";
    },
    isBranchLinkVisible() {
      if (this.isBranchPage && this.isSelectedBranch) return false;
      if (!this.isBranchPage && !this.compact) return true;
      if (this.selectedBranchName && !this.compact) return true;

      return false;
    },
  },

  methods: {
    ...mapMutations("branch", ["setShowBranchModal"]),

    getSelectedBranchLinkName(link: string) {
      return this.selectedBranchName ? this.selectedBranchName : this.$tc(link);
    },
    branchClickHandler(): void | null {
      return this.isBranchPage ? null : (this as any).setShowBranchModal(true);
    },
  },
});
