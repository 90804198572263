
import type { PropType } from "vue";
import Vue from "vue";
import { mapGetters } from "vuex";
import type { SearchSuggestion } from "~/types/searchBar";
import SearchBarSuggestionRow from "~/components/UI/search/SearchBarSuggestionRow.vue";
import { toRelative } from "~/lib/shared";
import { TrackingEvents } from "~/store/tracking";

export default Vue.extend({
  name: "ProductItems",
  components: {
    SearchBarSuggestionRow,
  },

  props: {
    items: {
      type: Array as PropType<SearchSuggestion[]>,
      default: () => [],
    },
  },
  computed: {
    ...mapGetters("searchBar", ["searchQuery"]),
  },
  methods: {
    toRelative,
    querySuggestionClicked(item: SearchSuggestion) {
      this.$store.commit("tracking/addTrackingEvent", {
        type: TrackingEvents.SearchSuggestionClicked,
        data: { type: "product", item },
      });
    },
  },
});
