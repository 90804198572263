<template>
  <div
    class="fixed bottom-0 md:bottom-12 w-full flex items-center justify-center z-50"
  >
    <transition name="slide-fade">
      <div
        v-if="visible"
        data-testid="toast"
        :class="styleClass"
        class="px-5 py-4 text-white text-size-5 font-semibold"
      >
        <div data-testid="toast-message">{{ message }}</div>
      </div>
    </transition>
  </div>
</template>

<script>
export default {
  props: {
    message: {
      type: String,
      required: true,
    },
    type: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      visible: false,
      duration: 6000,
    };
  },
  computed: {
    styleClass() {
      switch (this.type) {
        case "primary":
          return "bg-grey-800";
        case "success":
          return "bg-green";
        case "error":
          return "bg-red";
        case "info":
          return "bg-blue";
        default:
          return "bg-gray-800";
      }
    },
  },
  mounted() {
    this.visible = true;
    setTimeout(() => {
      this.visible = false;
    }, this.duration);
  },
};
</script>

<style scoped>
.slide-fade-enter-active {
  transition: all 0.2s ease-out;
}

.slide-fade-leave-active {
  transition: all 0.4s cubic-bezier(1, 0.5, 0.8, 1);
}

.slide-fade-enter,
.slide-fade-leave-to {
  transform: translateY(20px);
  opacity: 0;
}
</style>
