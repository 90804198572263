import { propEq } from "ramda";
import { Context } from "@nuxt/types";
import { AppCookieNames } from "~/constants/ecomApi";
import { getQtyMinimum } from "~/lib/product";
import { getMonetateOmniJson } from "~/services/api/monetate.api";
import { fetchProducts } from "~/services/products.service";
import { ProductRecommendation } from "~/types/productRecommendation";
import { pageType } from "~/utils/pageTypes";
import { LineItem } from "~/types/trolley";
import { camelizeKeys, randomRequestId } from "~/utils/utils";

/**
 * Fetch Monetate feature flags JSON. We're not caching this call as we need to hit their end-point for accurate tracking.
 *  @param context
 *  @param currentPath
 */

export const fetchMonetateOmniJson = async (
  context: Context,
  currentPath: string
): Promise<Object> => {
  const customerId = context.store.getters["auth/getUserId"];
  const storeId = context.store.getters["branch/selectedBranchId"];
  const monetatePreviewId = context.$cookies.get(
    AppCookieNames.MonetatePreviewId
  );

  // see for events -> (https://marketer.monetate.net/control/a-a77f9577/p/toolstation.be/settings/integration#api)
  const monetateBody = {
    channel: "a-a77f9577/p/toolstation.com",
    monetateId: context.$cookies.get("mt.v"),
    ...(customerId ? { customerId } : {}),
    ...(monetatePreviewId ? { preview: monetatePreviewId } : {}),
    events: [
      {
        eventType: "monetate:context:CustomVariables",
        customVariables: [
          { variable: "isLoggedIn", value: (!!customerId).toString() },
          { variable: "customerId", value: customerId || "" },
          { variable: "hasStoreSet", value: (!!storeId).toString() },
          { variable: "selectoreStoreId", value: storeId || null },
        ],
      },
      {
        eventType: "monetate:decision:DecisionRequest",
        actionTypes: ["monetate:action:OmnichannelJson"],
        requestId: randomRequestId().toString(),
        includeReporting: true,
      },
      {
        eventType: "monetate:context:PageView",
        url: currentPath,
        pageType: pageType(currentPath),
      },
    ],
  };

  if (!monetateIdCookieIsSet(context)) {
    delete monetateBody.monetateId;
  }

  return await getMonetateOmniJson(monetateBody)
    .then((res: any) => {
      if (!monetateIdCookieIsSet(context) && res?.meta?.monetateId) {
        setMonetateIdCookie(res.meta.monetateId, context);
      }
      const actions = res?.data?.responses[0]?.actions || [];

      // Used for GTM datalayer push
      const trackingData = actions.filter(
        (action: any) =>
          action.impressionReporting &&
          Array.isArray(action.impressionReporting)
      );

      return {
        actions: actions
          .filter(
            (action: any) =>
              action.actionType === "monetate:action:OmnichannelJson" &&
              action.json
          )
          .map((action: any) => action.json),
        trackingData,
      };
    })
    .then(camelizeKeys)
    .catch((error) => console.error(error));
};

/**
 * Fetch Monetate social proofing quantity -> (https://kibocommerce.helpjuice.com/115352-omnichannel-experiences/1100084-configure-an-omnichannel-social-proof-action)
 *  @param context
 *  @param currentPath
 *  @param productCode
 */

export const fetchMonetateOmniChannelSocialProof = async (
  context: Context,
  currentPath: string[],
  productCode: string[]
): Promise<Object> => {
  const monetateBody = {
    channel: "a-a77f9577/p/toolstation.com",
    monetateId: context.$cookies.get("mt.v"),
    events: [
      {
        eventType: "monetate:decision:DecisionRequest",
        actionTypes: ["monetate:action:SocialProofData"],
        requestId: randomRequestId().toString(),
        includeReporting: true,
      },
      {
        eventType: "monetate:context:ProductDetailView",
        products: [
          {
            productId: productCode,
          },
        ],
      },
      {
        eventType: "monetate:context:PageView",
        url: currentPath,
        pageType: pageType(currentPath),
      },
    ],
  };

  if (!monetateIdCookieIsSet(context)) {
    delete monetateBody.monetateId;
  }

  return await getMonetateOmniJson(monetateBody)
    .then((res: any) => {
      if (!monetateIdCookieIsSet(context)) {
        setMonetateIdCookie(res.meta.monetateId, context);
      }
      return res.data.responses[0].actions[0];
    })
    .then(camelizeKeys)
    .catch((error) => console.error(error));
};

/**
 * Fetch Monetate Product Recommendations
 *  @param context
 *  @param trolley
 *  @param id
 */

export const fetchMonetateOmniChannelProductRecs = async (
  context: Context,
  products: string[],
  trolley: LineItem[],
  id?: string
): Promise<ProductRecommendation[]> => {
  const pageUrl = process.server
    ? context.$config.appUrl + context.route.fullPath
    : window.location.href;

  const monetateBody = {
    channel: "a-a77f9577/p/toolstation.com",
    monetateId: context.$cookies.get("mt.v"),
    events: [
      {
        eventType: "monetate:decision:DecisionRequest",
        actionTypes: ["monetate:action:OmnichannelRecommendation"],
        requestId: randomRequestId().toString(),
        includeReporting: true,
      },
      {
        eventType: "monetate:context:ProductDetailView",
        products: (products || []).map((id: string) => ({
          productId: id,
          sku: id,
        })),
      },
      {
        eventType: "monetate:context:Cart",
        cartLines: trolley.map((line: LineItem) => ({
          sku: line.product_code,
          pid: line.product_code,
          quantity: line.quantity,
          currency: context.$config.currency,
          value: line?.product?.prices?.raw.gross.toString(),
        })),
      },
      {
        eventType: "monetate:context:PageView",
        url: pageUrl,
        pageType: pageType(pageUrl),
      },
    ],
  };

  if (!monetateIdCookieIsSet(context)) {
    delete monetateBody.monetateId;
  }

  return await fetchRecsForMonetateEvent(monetateBody, context, id);
};

const fetchRecsForMonetateEvent = (
  monetateBody: any,
  context: Context,
  id: string = ""
) =>
  getMonetateOmniJson(monetateBody)
    .then(async (res: any) => {
      const monetateProductRecs =
        res.data.responses?.[0]?.actions.find(
          (obj: any) =>
            obj.actionType === "monetate:action:OmnichannelRecommendation" &&
            (id ? obj.component === id : true)
        )?.items ?? [];

      if (!monetateProductRecs.length) return [];

      if (!monetateIdCookieIsSet(context)) {
        setMonetateIdCookie(res.meta.monetateId, context);
      }

      return await fetchProducts(context.$axios, {
        products: monetateProductRecs.map((product: any) => product.id),
      })
        .then((res) => {
          return res.map((product) => {
            return {
              // Merge in monetate recommendation information
              ...monetateProductRecs.find(propEq("id", product.code)),

              // Merge in ECOM API product information
              quantityminimum: getQtyMinimum([
                ...(product.otherAttributes ?? []),
                ...(product.technicalSpecAttributes ?? []),
              ]),
              channel: product.channel,
              thumbImage: product.images?.med ?? "",
              title: `${product.name} ${product.packSize}`,
              prices: JSON.stringify(product.prices?.raw),
              slug: product.slug,
              pid: product.code,
              tsReviews: Number(
                product.otherAttributes?.find(
                  (obj: any) => obj.key === "Product Review Rating"
                )?.value
              ),
            } as ProductRecommendation;
          });
        })
        .then((res) => {
          return res.sort((a, b) => {
            return (
              monetateProductRecs.findIndex((item: any) => item.id === a.pid) -
              monetateProductRecs.findIndex((item: any) => item.id === b.pid)
            );
          });
        });
    })
    .then(camelizeKeys)
    .catch((error) => console.error(error));

/**
 * Track product impressions -> (https://docs.monetate.com/docs/omnichannel-experiences-available-action-types#omni-intelligent-recommendations)
 *  @param context
 *  @param recToken
 */

export const trackProductImpressions = async (
  context: Context,
  recTokens: Array<string>
): Promise<Object> => {
  const monetateBody = {
    channel: "a-a77f9577/p/toolstation.com",
    monetateId: context.$cookies.get("mt.v"),
    events: [
      {
        eventType: "monetate:record:RecImpressions",
        recImpressions: recTokens,
      },
    ],
  };

  if (!monetateIdCookieIsSet(context)) {
    delete monetateBody.monetateId;
  }

  return await getMonetateOmniJson(monetateBody)
    .then((res: any) => {
      if (!monetateIdCookieIsSet(context)) {
        setMonetateIdCookie(res.meta.monetateId, context);
      }
      return res;
    })
    .catch((error) => console.error(error));
};

/**
 * Track product Clicks -> (https://docs.monetate.com/docs/omnichannel-experiences-available-action-types#omni-intelligent-recommendations)
 *  @param context
 *  @param recToken
 */

export const trackProductClicks = async (
  context: Context,
  recToken: Array<string>
): Promise<Object> => {
  const monetateBody = {
    channel: "a-a77f9577/p/toolstation.com",
    monetateId: context.$cookies.get("mt.v"),
    events: [
      {
        eventType: "monetate:record:RecClicks",
        recClicks: recToken,
      },
    ],
  };

  if (!monetateIdCookieIsSet(context)) {
    delete monetateBody.monetateId;
  }

  return await getMonetateOmniJson(monetateBody)
    .then((res: any) => {
      if (!monetateIdCookieIsSet(context)) {
        setMonetateIdCookie(res.meta.monetateId, context);
      }
      return res;
    })
    .catch((error) => console.error(error));
};

function monetateIdCookieIsSet(context: Context) {
  return !!context.$cookies.get("mt.v");
}

function setMonetateIdCookie(monetateId: string, context: Context) {
  const oneYear = 365 * 24 * 60 * 60 * 1000;
  const expirationDate = new Date(Date.now() + oneYear);

  context.$cookies.set("mt.v", monetateId, {
    expires: expirationDate,
    path: "/",
    sameSite: "lax",
    secure: true,
  });
}
