
import type { PropType } from "vue";
import Vue from "vue";
import type { TradeBrandItem } from "~/components/layout/header/components/TradeBrands";

export default Vue.extend({
  name: "TradeBrandsRow",
  props: {
    title: {
      type: String,
      required: true,
    },
    brands: {
      type: Array as PropType<TradeBrandItem[]>,
      required: true,
    },
  },
  methods: {
    brandSelected() {
      this.$emit("brandSelected");
    },
  },
});
