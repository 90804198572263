import { render, staticRenderFns } from "./XMarkIcon.vue?vue&type=template&id=904325a8&scoped=true&functional=true"
import script from "./XMarkIcon.vue?vue&type=script&lang=js"
export * from "./XMarkIcon.vue?vue&type=script&lang=js"
import style0 from "./XMarkIcon.vue?vue&type=style&index=0&id=904325a8&prod&scoped=true&lang=postcss"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  true,
  null,
  "904325a8",
  null
  
)

export default component.exports