<template functional>
  <svg viewBox="0 0 24 24" fill="none" :class="props.classes">
    >
    <path
      d="M19.6189 5.16622L18.1837 4.15527C17.7866 3.87661 17.2425 3.98354 16.9767 4.39181L9.94114 15.1429L6.70791 11.7925C6.3702 11.4425 5.81986 11.4425 5.48216 11.7925L4.25328 13.0659C3.91557 13.4158 3.91557 13.9861 4.25328 14.3393L9.22507 19.4913C9.50337 19.7797 9.94114 20 10.3351 20C10.7291 20 11.1262 19.744 11.3826 19.3584L19.8503 6.41371C20.1192 6.00544 20.0161 5.44488 19.6189 5.16622Z"
      fill="currentColor"
    />
  </svg>
</template>

<script>
export default {
  name: "CheckIcon",
  props: {
    classes: {
      type: String,
      required: false,
      default: "",
    },
  },
};
</script>
