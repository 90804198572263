<template>
  <div
    class="flex flex-col gap-4 items-center justify-center relative overflow-hidden"
  >
    <div :class="heightClasses" class="w-full">
      <div
        v-if="backgroundColour"
        :style="backgroundColourClass"
        class="w-full h-full object-cover"
      />
      <img
        v-else
        class="w-full h-full object-cover"
        :src="imageUrl"
        :alt="title"
        loading="lazy"
      />
    </div>
    <DefaultButton
      v-if="cta.linkText !== ''"
      appearance="pill"
      :path="cta.path"
      class="text-center w-full"
      :style="cta.colour"
    >
      {{ cta.linkText }}
    </DefaultButton>
  </div>
</template>

<script setup>
import { computed } from "vue";
import DefaultButton from "~/components/UI/button/DefaultButton.vue";

const props = defineProps({
  heightClasses: {
    type: String,
    required: true,
  },
  imageUrl: {
    type: String,
    required: true,
  },
  backgroundColour: {
    type: String,
    required: false,
    default: null,
  },
  title: {
    type: String,
    required: true,
  },
  cta: {
    type: Object,
    required: false,
    default: () => {},
  },
});

const backgroundColourClass = computed(
  () => `background-color:${props.backgroundColour}`
);
</script>
