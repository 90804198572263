import { Device } from "~/store/viewport";

export default ({ req, store }) => {
  if (process.server) {
    const userAgent = req.headers["user-agent"] || null;

    if (!userAgent) return;

    const isMobile = /mobile/i.test(userAgent);
    const isTablet = /tablet|ipad|playbook|silk/i.test(userAgent);
    const isDesktop = !isMobile && !isTablet;

    if (isMobile) store.commit("viewport/setDevice", Device.Mobile);
    if (isTablet) store.commit("viewport/setDevice", Device.Tablet);
    if (isDesktop) store.commit("viewport/setDevice", Device.Desktop);
  }
  if (process.client) {
    const updateDevice = () => {
      const DESKTOP_WIDTH = 1024;
      const MOBILE_WIDTH = 481;
      const DEVICE_WIDTH = window.innerWidth;

      store.commit("viewport/setDeviceWidth", DEVICE_WIDTH);

      if (DEVICE_WIDTH < MOBILE_WIDTH) {
        store.commit("viewport/setDevice", Device.Mobile);
      }
      if (DEVICE_WIDTH >= MOBILE_WIDTH && DEVICE_WIDTH <= DESKTOP_WIDTH) {
        store.commit("viewport/setDevice", Device.Tablet);
      }
      if (DEVICE_WIDTH > DESKTOP_WIDTH) {
        store.commit("viewport/setDevice", Device.Desktop);
      }
    };

    window.addEventListener("resize", updateDevice);

    // @todo it seems the resize event is fired after this update, so scheduling this to run on the
    // next tick sorts it. Figure out why or if there's a better way
    setTimeout(updateDevice);
  }
};
