<template>
  <div :class="extraClasses">
    <div class="flex flex-col items-center justify-center gap-3 h-full">
      <slot name="label" />
      <span class="z-2" :class="iconClasses">
        <LoadingSpinnerIcon :size="size" />
      </span>
    </div>
  </div>
</template>

<script>
import { LoadingSpinnerIcon } from "~/components/UI/icons";
export default {
  components: {
    LoadingSpinnerIcon,
  },
  props: {
    extraClasses: {
      type: String,
      default: "",
    },
    iconClasses: {
      type: String,
      default: "",
    },
    spinnerBackgroundClasses: {
      type: String,
      default: null,
    },
    size: {
      type: String,
      default: "xl",
      validator(value) {
        return ["xxs", "xs", "sm", "md", "lg", "xl", "xxl", "xxxl"].includes(
          String(value)
        );
      },
    },
  },
};
</script>
