import { assoc, indexBy, pipe, prop } from "ramda";
import { Product } from "~/types/product";
import { ProductWithStock } from "~/types/products";
import { ProductStock } from "~/services/products.service.d";

export const indexStockBySite = indexBy<ProductStock>(prop("siteId"));

// Why? The products' endpoint returns without stock, so we're essentially injecting into this data structure to satisfy type safety
export const productToProductWithStock = (a: Product): ProductWithStock =>
  assoc("stock", {}, a);

export const indexProductsByProductCode = indexBy<Product>(
  pipe(prop("code"), (code: number | string) => code.toString())
);
