
import Vue from "vue";
import { mapActions, mapGetters } from "vuex";
import { always } from "ramda";
import BaseModal from "~/components/UI/modals/BaseModal.vue";
import DefaultButton from "~/components/UI/button/DefaultButton.vue";
import FormLabel from "~/components/UI/forms/FormLabel.vue";
import FormInput from "~/components/UI/forms/FormInput.vue";
import AlertBox from "~/components/UI/alerts/AlertBox.vue";
import LoadingSpinner from "~/components/UI/loading/LoadingSpinner.vue";

export default Vue.extend({
  name: "SaveForLaterModal",
  components: {
    BaseModal,
    DefaultButton,
    FormLabel,
    FormInput,
    AlertBox,
    LoadingSpinner,
  },
  data(): { newSavedListName: string; unsubscribe: Function } {
    return {
      newSavedListName: "",
      unsubscribe: always(null),
    };
  },
  computed: {
    ...mapGetters("auth", ["getUser"]),
    ...mapGetters("search", ["getProductByCode"]),
    ...mapGetters("saveForLater", [
      "isSaveForLaterModalVisible",
      "savedLists",
      "lastServerMessage",
      "lastServerMessageLinkToView",
      "lastServerMessageWasError",
      "productCodeInQuestion",
    ]),
  },
  async mounted() {
    const saveForLaterParam =
      this.$router.currentRoute.query?.["save-for-later"];

    // Deep linking - react to product code we wish to add to a saved list in the url query param
    if (saveForLaterParam) {
      this.$store.commit(
        "saveForLater/setProductInQuestion",
        saveForLaterParam
      );
    }

    if (this.productCodeInQuestion && this.getUser) {
      await this.$store.dispatch(
        "saveForLater/fetchSavedLists",
        this.$store.getters["auth/getUser"]?.id
      );
    }
  },
  created(): void {
    this.unsubscribe = this.$store.subscribe(async (mutation) => {
      if (
        mutation.type === "saveForLater/setProductInQuestion" &&
        mutation.payload
      ) {
        await this.$store.dispatch(
          "saveForLater/fetchSavedLists",
          this.$store.getters["auth/getUser"]?.id
        );
      }
    });
  },
  destroyed() {
    this.unsubscribe();
  },
  methods: {
    ...mapActions("saveForLater", [
      "createSavedList",
      "addItemToList",
      "closeSaveForLaterModal",
    ]),
    submitSavedList() {
      this.createSavedList({
        savedListName: this.newSavedListName,
        productCode: this.productCodeInQuestion,
        product: this.getProductByCode(this.productCodeInQuestion),
      });
      this.newSavedListName = "";
    },
  },
});
