import { ObjectDirective, VNode } from "vue";
import { DirectiveBinding } from "vue/types/options";

interface FocusOutsideHTMLElement extends HTMLElement {
  focusedOutsideEvent: any;
}

export const FocusOutside: ObjectDirective = {
  bind(el: HTMLElement, binding: DirectiveBinding, vnode: VNode) {
    const currentElement = el as FocusOutsideHTMLElement;

    const handler =
      binding.value instanceof Function
        ? binding.value.bind(vnode.context)
        : () => {};

    currentElement.focusedOutsideEvent = (event: Event) => {
      if (
        !(
          currentElement === event.target ||
          currentElement.contains(event.target as Node)
        )
      ) {
        handler(event);
      }
    };

    document.body.addEventListener(
      "focusin",
      currentElement.focusedOutsideEvent
    );
  },
  unbind(el: HTMLElement) {
    document.body.removeEventListener(
      "focusin",
      (el as FocusOutsideHTMLElement).focusedOutsideEvent
    );
  },
};

export default FocusOutside;
