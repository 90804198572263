/**
 * @category Vue Helpers
 * @module Helpers/Images
 * @description A collection of Helpers for dealing with images
 */

/**
 * @function tidyImgUrl
 * @description A Helper for tidying up the image url
 * @param {*} img The value being passed for the image eg Everbuild
 */
function tidyImgUrl(img: string): string {
  return img.toLowerCase().replace(/\s/g, "").replace(/-/g, "");
}

export default tidyImgUrl;
