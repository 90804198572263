
import Vue from "vue";
import BaseLayout from "~/layouts/baseLayout.vue";
import SiteHeader from "~/components/layout/header/TheHeader.vue";
import ProgressBar from "~/components/progress-bar/progress-bar.vue";
import { Step } from "~/components/progress-bar/";

export default Vue.extend({
  name: "CheckoutLayout",

  components: {
    BaseLayout,
    SiteHeader,
    ProgressBar,
  },

  layout: "checkout",
  middleware: ["mustHaveTrolleyItems"],

  data(): { steps: Step[] } {
    return {
      steps: [
        {
          title: this.$tc("checkout.progressBar.trolley"),
          url: "/trolley",
          path: "/trolley",
        },
        {
          title: this.$tc("checkout.progressBar.details"),
          url: "/checkout/delivery",
          path: ["/checkout/delivery", "/checkout/guest/details"],
        },
        {
          title: this.$tc("checkout.progressBar.payment"),
          url: "/checkout/review",
          path: "/checkout/review",
        },
      ],
    };
  },

  created() {
    this.$store.dispatch("trolley/resync");
  },
});
