import Vue from "vue";
import { isEmpty } from "ramda";
import { mapGetters } from "vuex";

import { queryFilterEntriesFromRoute } from "~/utils/filters/queryFilters";

export default Vue.extend({
  computed: {
    ...mapGetters("search", ["getCurrentPage"]),
    ...mapGetters("locale", ["getSsrPageUrl"]),
  },
  methods: {
    stripTags(text: string): string {
      return text.replace(/(<([^>]+)>)/gi, "");
    },
    dedupeText(text: string): string {
      const matchFirstWord = /\b(\w+)\s+\1\b/i; // Match duplicated words seperated by one or more spaces
      return text.replace(matchFirstWord, "$1");
    },
    safelyTranslate(keyword: string): string {
      return this.$te(keyword) ? this.$tc(keyword) : "";
    },
    getPrefix(): string {
      return queryFilterEntriesFromRoute(this.$nuxt.context.query)
        .map(
          ([name, value]: [string, string]) =>
            `${this.stripTags(value)}${this.safelyTranslate(
              `search.facetSuffix.${name}`
            )}`
        )
        .join(" ");
    },
    getUrl(append: string = "", server = true): string {
      const domain = server
        ? (this.getSsrPageUrl as string)
        : window.location.host;
      return `https://${domain}${append}`;
    },
    getCanonicalUrl(): string {
      const queries = queryFilterEntriesFromRoute(this.$nuxt.context.query)
        .map(([key, value]) => {
          return `${key}=${value}`;
        })
        .join("&");

      const pageQuery =
        this.getCurrentPage > 1
          ? `${!isEmpty(queries) ? "&" : "?"}page=${
              this.getCurrentPage as string
            }`
          : "";

      return this.getUrl(
        `${this.$route.path}${
          !isEmpty(queries) ? `?${queries}` : ""
        }${pageQuery}`,
        process.server
      );
    },
    getPageUrl(): string {
      return this.getUrl(`${this.$route.fullPath}`, process.server);
    },
    getPageNumberSuffix() {
      return this.getCurrentPage > 1
        ? ` | ${this.safelyTranslate("global.page")} ${
            this.getCurrentPage as string
          }`
        : "";
    },
  },
});
