<template functional>
  <svg
    :class="props.classes"
    width="18"
    height="16"
    viewBox="0 0 18 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M15.4624 11.7122C16.0918 11.2706 16.5638 10.6403 16.8099 9.91287C17.056 9.18543 17.0633 8.39868 16.8309 7.66679C16.3542 6.16485 14.8933 5.34173 13.3145 5.3433H12.4023C12.1846 4.4965 11.7773 3.71 11.2108 3.04303C10.6444 2.37607 9.93373 1.84601 9.13228 1.49278C8.33082 1.13954 7.4595 0.972327 6.58391 1.00373C5.70831 1.03513 4.85127 1.26433 4.0773 1.67407C3.30334 2.08381 2.63263 2.66341 2.11566 3.36923C1.5987 4.07506 1.24896 4.8887 1.09277 5.74892C0.936584 6.60913 0.978025 7.49349 1.21397 8.33541C1.44992 9.17733 1.87422 9.95487 2.45494 10.6095M8.9736 14.3333L8.97045 7.27464M11.4716 11.8377L8.97045 14.3333L6.46926 11.8377"
      stroke="#065CAB"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>

<script>
export default {
  name: "DownloadIcon",
  props: {
    classes: {
      type: String,
      required: false,
      default: "",
    },
  },
};
</script>
