import type { Context } from "@nuxt/types";
import { isEmpty, isNil } from "ramda";
import { BranchCookieNames } from "~/types/branch";

const populateSelectedBranch = (context: Context): void => {
  const cookieBranchName =
    context.$cookies.get(BranchCookieNames.SelectedSiteName) ?? "";
  const cookieBranchId =
    context.$cookies.get(BranchCookieNames.SelectedSiteId) ?? "";

  // @todo turn this back on once feature is needed (originally was suppoesd to reflect the mobile app)
  /*
  const userData = context.store.getters['auth/getUser']
  if (!isNil(userData?.defaultBranch)) {
    // await context.store.dispatch('branch/getBranchById', userData.defaultBranch)
    // if (!isNil(cookieBranchName)) {
    // await context.store.dispatch('branch/updateSelectedBranch', {
    //   customerId: userData.id,
    //   siteId: cookieBranchId
    // })
    // }
  } */

  if (!isEmpty(cookieBranchName) && !isEmpty(cookieBranchId)) {
    context.store.dispatch("branch/setSelectedBranch", {
      cookieBranchName,
      cookieBranchId,
    });
  }
};

const populateTrolley = async (context: Context): Promise<void> => {
  const cookieTrolley = {
    trolleyId: context.store.getters["trolley/trolleyId"] ?? null,
    sessionToken: context.store.getters["guest/guestCheckoutSession"] ?? null,
  };

  const isAuthenticated = context.store?.getters[
    "auth/isAuthenticated"
  ] as boolean;

  const guestJWT = context.store.getters["guest/getGuestCustomerJwtToken"];
  const getGuest = context.store.getters["guest/getGuest"];
  const trolleyIdNotEmpty =
    !isEmpty(cookieTrolley.trolleyId) && !isNil(cookieTrolley.trolleyId);
  const sessionTokenIsNotEmpty =
    !isEmpty(cookieTrolley.sessionToken) && !isNil(cookieTrolley.sessionToken);

  // @todo refactor so the only types these can be is a string or nil
  if (trolleyIdNotEmpty && sessionTokenIsNotEmpty && !isAuthenticated) {
    await context.store.dispatch("trolley/getGuestTrolleyData", cookieTrolley);
  } else if (trolleyIdNotEmpty && isAuthenticated) {
    await context.store.dispatch(
      "trolley/getCustomerTrolleyById",
      cookieTrolley
    );
  } else if (trolleyIdNotEmpty && getGuest) {
    context.$axios.setHeader("X-Toolstation-Customer-Id", guestJWT);
    await context.store.dispatch(
      "trolley/getCustomerTrolleyById",
      cookieTrolley
    );
  }
};

const populateUserData = async (context: Context): Promise<void> => {
  await Promise.allSettled([
    populateSelectedBranch(context),
    populateTrolley(context),
  ]);

  if (process.client) {
    window.dispatchEvent(new Event("userDataLoaded"));
  }
};

export default async (context: Context): Promise<void> => {
  await populateUserData(context);
};
