import type { Context } from "@nuxt/types";

export default (context: Context): void => {
  const { store, redirect, route } = context;
  const isUser = !!store.state?.auth?.user?.id;
  const isGuest = !!store.state?.guest?.guest?.id;
  const isGuestCustTransient = !!store.state?.guest?.guest?.token;

  if (!isUser && !isGuest && !isGuestCustTransient) {
    const redirectTo = route.path;
    redirect(`/login?redirectTo=${redirectTo}`);
  }
};
