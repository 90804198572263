// @todo possibly refactor at later date so we use friendly names
export enum ProductAttribute {
  Assettr = 96,
  AdditionalPhotos = 128,
  RiskCode = 7,
  AgeRestricted = 13,
  Brand = 22,
  Youtube = 64,
  ReviewRating = 83,
  NumberOfReviews = 84,
  ReviewTotal = 147,
  PromotionMessage = 302,
  PromotionExpiryDate = 300,
  Repairable = 288,
  QuantityLabel = 434,
  QuantityMinimum = 435,
  IsBasePaint = 1519, // Denotes this product is a base paint (not virtual)
  BasePaint = 1518, // Denotes this product is a virtual paint (refers to a base paint, value is it's SKU)
  Paint = 1504, // Denotes this product is a paint product
  KitchenKit = 10002,
  DropshipDeliveryTime = 109,
}
