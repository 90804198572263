import { NuxtAxiosInstance } from "@nuxtjs/axios";
import { assocPath, prop, __ } from "ramda";
import { rejectUndefined } from "~/lib";
import { AccountCustomer } from "~/services/account.service.d";
import {
  createAccountCustomer,
  deleteAccountCustomer,
  getAccountCustomers,
  getAccountCustomerSpendStats,
  getAccountOrders,
  getAccountOrdersReporting,
  getBrAccountFaqDocuments,
  getBrAccountPromoDocuments,
  getCustomerOrdersReporting,
  updateSpendingLimits,
} from "~/services/api/account.api";
import {
  SpendingLimitPayload,
  SpendingReportsRequest,
  UpdateSpendingLimitsPayload,
} from "~/services/api/tradeAccount.api.d";
import type { Order } from "~/services/orders.service.d";
import { Account } from "~/store/auth";
import { camelizeKeys } from "~/utils/utils";

export interface AddAccountCustomerPayload {
  email: string;
  title: string;
  first_name: string;
  last_name: string;
  telephone: string;
  address: {
    line_1: string;
    line_2: string;
    town: string;
    country_id: string | number;
    postcode: string;
  };
  limits?: SpendingLimitPayload;
}

export const fetchAccountCustomers = async (
  $axios: NuxtAxiosInstance,
  accountId: string
): Promise<AccountCustomer[] | null> =>
  await getAccountCustomers($axios, accountId)
    .then(prop("data"))
    .then(camelizeKeys);

export const fetchAccountCustomerSpendStats = <
  T extends Pick<
    AccountCustomer,
    | "id"
    | "employeeOrderSpendLimit"
    | "employeeMonthlySpendLimit"
    | "employeePurchaseOrderRequired"
  >,
>(
  $axios: NuxtAxiosInstance,
  accountId: Account["id"],
  cardholder: T
): Promise<T> =>
  getAccountCustomerSpendStats($axios, accountId, cardholder)
    .then((r) => r.data)
    .then(camelizeKeys)
    .then(assocPath(["spendStats", "spendingLimits"], __, cardholder));

export const removeAccountCustomer = async (
  $axios: NuxtAxiosInstance,
  accountId: string,
  customerId: string
): Promise<{}> => await deleteAccountCustomer($axios, accountId, customerId);

export const addAccountCustomer = async (
  $axios: NuxtAxiosInstance,
  accountId: string,
  payload: AddAccountCustomerPayload
): Promise<void> =>
  await createAccountCustomer($axios, accountId, payload).then(prop("data"));

export const fetchAccountOrders = async (
  $axios: NuxtAxiosInstance,
  accountId: number,
  page: number = 1,
  pageLimit: number = 50
): Promise<Order[]> =>
  await getAccountOrders($axios, accountId, page, pageLimit).then((res) =>
    camelizeKeys(res.data)
  );

export const fetchAccountOrdersReporting = async (
  $axios: NuxtAxiosInstance,
  accountId: number,
  params: SpendingReportsRequest
): Promise<any> => await getAccountOrdersReporting($axios, accountId, params);

export const fetchCustomerOrdersReporting = async (
  $axios: NuxtAxiosInstance,
  customerId: number,
  params: SpendingReportsRequest
): Promise<any> => await getCustomerOrdersReporting($axios, customerId, params);

/**
 * Updates n customers on account spending limits
 */
export const setSpendingLimits = (
  $axios: NuxtAxiosInstance,
  accountId: Account["id"],
  payload: UpdateSpendingLimitsPayload
) => updateSpendingLimits($axios, accountId, rejectUndefined(payload));

/**
 * Fetch Bloomreach document data for trade account promotions
 * @param brContentURL
 */

export const fetchBrTradeAccountPromotions = async (
  brContentURL: string
): Promise<any> =>
  await getBrAccountPromoDocuments(brContentURL)
    .then((response) => response.document.fields)
    .then(camelizeKeys);

export const fetchBrTradeAccountFaqs = async (
  brContentURL: string
): Promise<any> =>
  await getBrAccountFaqDocuments(brContentURL)
    .then((response) => response.document.fields)
    .then(camelizeKeys);

export default { fetchAccountCustomers };
